<template>
    <div class="tableCornerRadius bg-white">
        <div class="table-container" v-if="entityId === 20010 || entityId === 20011" :class="entityId === 20011 ? 'displayDashboardTable' : 'displayHeatMapTable'">
            
            <table>
                <thead>
                    <th scope="col" v-for="columnName in columnNames" :key="columnName" :class="getWidth(columnName)" class="bg-white capitalize" @click="sort(columnName)">
                        <div class="flex"  :class="getFilterWidth(columnName)">
                            <div>{{ getColumnLabel(columnName) }}</div>
                            <div class="sort cursor-pointer" :class="className == columnName ? classSort : 'opacity-50'"></div>
                        </div>
                    </th>
                </thead>

                <tbody v-if="loadData1 == true || loadData2 == true|| loadData3 == true">
                    <tr class="cursor-pointer empty rowHeight">
                        <td class="relative h-5" :colspan="columnNames.size">
                            <div class="flex items-center justify-center w-full h-16 skeleton">
                                <dots-loader/>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tbody v-else-if="sortedData.length == 0">
                    <tr class="cursor-pointer empty rowHeight">
                        <td class="relative h-10" :colspan="columnNames.size">
                            <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                        </td>
                    </tr>
                </tbody>

                <tbody class="nonEmptyTable" v-else>
                    <tr v-for="(row, index) in sortedData" :class="((index + 1) % pageSize === 0) ? 'noBorderForLastTr' : 'BorderForTr'" :key="row.Country" class="cursor-pointer rowHeight">
                        <td v-for="columnName in columnNames" :key="columnName" @mouseout="doFalse()" class="cursor-pointer relative">
                            <div v-if="columnName == 'severity'">
                                <div class="relative flex regular pl-4">
                                    <div class="flex items-center justify-center rounded-full ml-2 w-5 h-5" @mousemove="toolTip(columnName, $event, index, row, 'circleButton')" :class="colorPropBg('border', row.severity)">
                                        <div class="severityCircle rounded-full" :class="colorPropBg('circle', row.severity)"></div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="columnName == 'selectedTier' || columnName == 'tier'">
                                <div>
                                    {{getTierName(row[columnName])}}
                                </div>
                            </div>
                            <div v-else>
                                <div class="table-ellipsis">
                                    <div @mousemove="toolTip(columnName, $event, index, row, 'text')">
                                        {{ row[columnName] }}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <!-- {{dynamicPagination}}
        {{pageSize}} -->
        <!-- <div v-if="!(loadData == true)"> -->
            <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :entityId="entityId" :sortedData="sortedData"></pagination>
        <!-- </div> -->

    </div>
    
</template>
<style scoped>
.noBorderForLastTr{
    border: 0px !important;
}
.BorderForTr{
    border-bottom: 1px solid #e9e9e9 !important;
}
.optionFont {
    font-size: 12px !important;
}
.w-40 {
    width: 155px;
}
.ellipsis-container {
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.5em;
    max-height: 3em;
}
.table-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.displayHeatMapTable {
    height: 300px !important;
}
.displayDashboardTable {
    height: 360px !important;
}
.empty td {
    border-bottom: 1px solid #e9e9e9 !important;
}
.Blank {
    padding-left: 11% !important;
}
.actionButtonStyling {
    left: -76px;
}
.action-popup {
    bottom: -40px;
    left: -60px;
}
.bgredStyle {
    background-color: #ff0000;
}
.fontSizingForPriority {
    font-size: 10px !important;
}
</style>

<script lang="ts">
import { emitter } from '@/main';
import _ from 'lodash';
import { defineComponent } from 'vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/dashBoardPagination.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import dotsLoader from './dotsLoader.vue';

export default defineComponent({
    props: ['tableRowData', 'headersData', 'openPopup', 'navigate','loadData1','loadData2','loadData3'],
    data(): any {
        return {
            tableHead: [],
            tableRow: [],
            searchTableRow: [],
            entityId: '',
            visibility: false,
            className: '',
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',
            showDropDown: false,
            showBlock: false,

            currentPage: '',
            pageSize: '',
            dynamicPagination: '',

            pop: false,
            styling: { left: '', bottom: '' },
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            filters: {},
            selectedTierMapping: [
                {
                    id: 1,
                    value: 'Organization'
                },
                {
                    id: 2,
                    value: 'Process'
                },
                {
                    id: 3,
                    value: 'System'
                }
            ],
            optionsValue: [
                {
                    id: 1,
                    value: 'Accept',
                    route: '/riskAcceptance'
                },
                {
                    id: 2,
                    value: 'Mitigate',
                    route: '/mitigateRisk'
                },
                {
                    id: 3,
                    value: 'Reset',
                    route: '/reset'
                },
                {
                    id: 4,
                    value: 'Delete',
                    route: '/deactive'
                },
                {
                    id: 5,
                    value: 'Clone',
                    route: '/clone'
                }
            ],
            tierObject: [
                {
                    id: 1,
                    value: 'Organization'
                },
                {
                    id: 2,
                    value: 'Process'
                },
                {
                    id: 3,
                    value: 'System'
                }
            ],
            informationValue: [
                {
                    id: 0,
                    value: 'None'
                },
                {
                    id: 1,
                    value: 'Low'
                },
                {
                    id: 2,
                    value: 'Moderate'
                },
                {
                    id: 3,
                    value: 'High'
                }
            ],
            risksValue: [
                {
                    id: 1,
                    value: 'Very Low'
                },
                {
                    id: 2,
                    value: 'Low'
                },
                {
                    id: 3,
                    value: 'Moderate'
                },
                {
                    id: 4,
                    value: 'High'
                },
                {
                    id: 5,
                    value: 'Very High'
                }
            ],
            orgType: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Subsidiary'
                },
                {
                    id: 3,
                    value: 'Branch'
                }
            ],
            type: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Secondary'
                }
            ],
            columns: [
                { key: 'statusCode', value: 'Status' },
                { key: 'systemName', value: 'System Name' },
                { key: 'controlName', value: 'Name' },
                { key: 'informationCategory', value: 'Category' },
                { key: 'processName', value: 'Process Name' },
                { key: 'organizationName', value: 'Organization Name' },
                { key: 'target', value: 'Target Name' },
                { key: 'History', value: 'History' },
                { key: 'tier', value: 'Tier' },
                { key: 'createdAt', value: 'Created' },
                { key: 'updatedAt', value: 'last Reviewed' },
                { key: 'riskCategory', value: 'Category' },
                { key: 'systemCount', value: 'Occurances' },
                { key: 'taskStatus', value: 'Status' },
                { key: 'taskId', value: 'ID' }
            ],
            myTasksStatus: [
                {
                    id: 10401,
                    value: 'Open'
                },
                {
                    id: 10402,
                    value: 'In Progress'
                },
                {
                    id: 10408,
                    value: 'Approval'
                },
                {
                    id: 10409,
                    value: 'In Review'
                },
                {
                    id: 10403,
                    value: 'Closed'
                }
            ],
            requestStatus: [
                {
                    id: 10401,
                    value: 'Open'
                },
                {
                    id: 10402,
                    value: 'In Progress'
                },
                {
                    id: 10403,
                    value: 'Approved'
                },
                {
                    id: 10404,
                    value: 'Deleted'
                },
                {
                    id: 10408,
                    value: 'Pending'
                },
                {
                    id: 10409,
                    value: 'On Hold'
                },
                {
                    id: 10410,
                    value: 'Rejected'
                }
            ],
            risksStatusValue: [
                {
                    id: 10401,
                    value: 'Open'
                },
                {
                    id: 10402,
                    value: 'In Progress'
                },
                {
                    id: 10403,
                    value: 'Accepted'
                },
                {
                    id: 10404,
                    value: 'Deleted'
                },
                {
                    id: 10405,
                    value: 'Mitigated'
                },
                {
                    id: 10406,
                    value: 'Partial'
                }
            ],
            requestedFor: [
                {
                    id: 10403,
                    value: 'Acceptance'
                },
                {
                    id: 10405,
                    value: 'Mitigate'
                },
                {
                    id: 10407,
                    value: 'Reset'
                },
                {
                    id: 10404,
                    value: 'Delete'
                }
            ],
            yesNo: [
                {
                    id: 1,
                    value: 'Yes'
                },
                {
                    id: 2,
                    value: 'No'
                }
            ],
            classificationData: [
                {
                    id: 10403,
                    value: 'Public'
                },
                {
                    id: 10407,
                    value: 'Internal'
                },
                {
                    id: 10406,
                    value: 'Confidential'
                },
                {
                    id: 10405,
                    value: 'Private'
                },
                {
                    id: 10410,
                    value: 'Top Secret'
                }
            ],
            priorityData: [
                {
                    id: 5,
                    value: 'P5'
                },
                {
                    id: 4,
                    value: 'P4'
                },
                {
                    id: 3,
                    value: 'P3'
                },
                {
                    id: 2,
                    value: 'P2'
                },
                {
                    id: 1,
                    value: 'P1'
                }
            ],
            users: [],
            optionsUserArray: [],
            selectedRowIndex: '',
            showTableActionsDropdown: false,
            tableDataActionOptions: [
                {
                    id: 1,
                    value: 'Delete'
                },
                {
                    id: 2,
                    value: 'Edit'
                }
            ],
            risksPriorityValue: [
                {
                    id: 1,
                    value: '1',
                    label: 'P1'
                },
                {
                    id: 2,
                    value: '2',
                    label: 'P2'
                },
                {
                    id: 3,
                    value: '3',
                    label: 'P3'
                },
                {
                    id: 4,
                    value: '4',
                    label: 'P4'
                },
                {
                    id: 5,
                    value: '5',
                    label: 'P5'
                }
            ],
            priorityObject: {}
        };
    },
    components: {
        toolTip,
        pagination,
        dotsLoader
    },
    async mounted() {
        this.initialize();
        await this.getAllUsers();
        emitter.on('visible', (isBoolean: any) => {
            this.visibility = isBoolean;
            this.visibility == false ? (this.filters = {}) : '';
        });
        emitter.on('*', (type, booleanValue) => {
            if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
                this.showDropDown == true ? (this.showDropDown = false) : '';
            } else {
                if (this.showBlock === true) {
                    this.showDropDown == true ? (this.showDropDown = booleanValue) : '';
                } else if (this.showBlock === false) {
                    this.showDropDown == true ? '' : (this.showDropDown = booleanValue);
                    this.showBlock = true;
                }
            }
        });
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo',
            dbSeverity: 'dbSeverity',
            dbLikelihood: 'dbLikelihood',
            dbBussiness: 'dbBussiness',
            dbOrganization: 'dbOrganization',
            dbOperations: 'dbOperations',
            dbLegal: 'dbLegal',
            dbFinancial: 'dbFinancial',
            dbTechnical: 'dbTechnical',
            dbProcessCriticality: 'dbProcessCriticality',
            dbAvailability: 'dbAvailability',
            dbConfidentiality: 'dbConfidentiality',
            dbIntegrity: 'dbIntegrity',
            dbInformationClassification: 'dbInformationClassification',
            dbSecurityCategory: 'dbSecurityCategory',
            dbPriority: 'dbPriority',
            dbOverAllImpact:'dbOverAllImpact'
        }),

        sortedData: function (): any {
            return [...this.tableRow]
                .filter((row: any) => {
                    for (const key in this.filters) {
                        const inputValue = this.filters[key];
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
        columnNames: function (): any {
            const names = new Set();
            for (const key of this.headersData) {
                if (key != 'entityId' && key != '_id') names.add(key);
            }
            return names;
        }
    },
    watch: {
        visibility(newValue) {
            if (newValue == false) {
                this.initialize();
            }
        },
        tableRowData: {
            handler(newVal, oldVal) {
                this.tableRow = this.tableRowData;
                this.initialize();
                console.log('this.tableRowData[0]', this.tableRowData[0]);
            },
            deep: true
        }
    },
    methods: {
        getColumnLabel(columnName: any) {
            const column = this.columns.find((col: { key: any }) => col.key === columnName);
            return column ? column.value : columnName;
        },
        getDropdownOptions1(columnName: any, type: any) {
            console.log('sazxsadsad', columnName);
            const option = this.dropdownOptions.find((option: { key: any }) => option.key === type);
            const option1 = option.value.find((soption: { id: any }) => soption.id === columnName);
            console.log('wasbdhjbdzjsad', option);
            console.log('wasbdhjbdzjsad', option1);
            return option ? option1.value : [];
        },
        shouldShowDropdown(columnName: any) {
            // Returns true if the column name has corresponding dropdown options
            console.log(
                'this.dropdownOptions.some((option: { key: any; }) => option.key === columnName)',
                this.dropdownOptions.some((option: { key: any }) => option.key === columnName)
            );
            return this.dropdownOptions.some((option: { key: any }) => option.key === columnName);
        },
        initialize() {
            this.tableRow = this.tableRowData;
            this.searchTableRow = this.tableRowData;

            this.tableRowData[0] == undefined ? '' : (this.entityId = this.tableRowData[0].entityId);
            console.log('this.entityId', this.entityId);
        },
        getWidth(column: any) {
            // column=='severity' || column == 'reportDelete' || column == 'reportAction'?'w-28':''
            if (column == 'infoAction' || column == 'risk'|| column == 'riskCategory' ) {
                return 'w-36';
            } else if (column == 'statusCode' || column == 'priority' || column == 'riskOwner' || column == 'createdAt') {
                return 'w-32';
            }
            else if (column == 'severity') {
                return 'w-1/6';
            }
            else if (column == 'reportDelete' || column == 'reportAction' || column == 'updatedAt') {
                return 'w-28';
            } else if (column == 'History' || column == 'tier') {
                return 'w-24';
            } else if (column == 'reportName') {
                return 'w-2/5';
            } else if (column == 'vulnerability') {
                return 'w-2/5';
            } else {
                return '';
            }
        },
        getFilterWidth(column:any){
            
            // column=='severity' || column == 'reportDelete' || column == 'reportAction'?'w-28':''
            if (column == 'risk') {
                return 'w-36';
            }else if (column=='riskCategory') {
                return 'w-16';
            }else if (column=='tier') {
                return 'w-20';
            } else {
                return '';
            }
        },
        paginationUpdations(currentPage: any, pageSize: any) {
            console.log('Pagination processing... ', currentPage, pageSize);
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        sort(tableHead: any) {
            this.className = tableHead;
            if (tableHead === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = tableHead;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        navigateWorkLog(rowData: any) {
            if (this.$route.path.includes('/systemInformation') && this.$route.path.includes('/org/system')) {
                this.$router.push({ name: 'worklogFromSystem', params: { riskId: rowData._id, systemId: rowData.systemId }, query: { systems: true } });
            } else if (this.$route.path.includes('/processInformation') && this.$route.path.includes('/org/process')) {
                this.$router.push({ name: 'worklogFromProcess', params: { riskId: rowData._id, processId: rowData.processId }, query: { process: true } });
            } else if (this.$route.path.includes('/organizationInformation')) {
                this.$router.push({ name: 'worklogFromOrganization', params: { riskId: rowData._id, orgId: rowData.orgId }, query: { organization: true } });
            } else if (this.$route.path.includes('/systemRisks')) {
                this.$router.push({ name: 'worklogFromSystem', params: { riskId: rowData._id, systemId: rowData.systemId }, query: { Risks: true } });
            } else if (this.$route.path.includes('/processRisks')) {
                this.$router.push({ name: 'worklogFromProcess', params: { riskId: rowData._id, processId: rowData.processId }, query: { Risks: true } });
            } else if (this.$route.path.includes('/orgRisks')) {
                this.$router.push({ name: 'worklogFromOrganization', params: { riskId: rowData._id, orgId: rowData.orgId }, query: { Risks: true } });
            }
        },
        changeInfoRisksPage(row: any, route: any) {
            emitter.emit('gotoInfoRisksPage', row);
        },
        changeRoute(row: any, route: any) {
            let selectedActionObj = {
                data: row,
                route: route
            };

            if (this.$route.path.includes('/org/system')) {
                emitter.emit('changeRouteInSystemInfo', selectedActionObj);
            } else if (this.$route.path.includes('/org/risks')) {
                emitter.emit('changeRouteInRisks', selectedActionObj);
            } else if (this.$route.path.includes('/systemRisks') || this.$route.path.includes('/processRisks') || this.$route.path.includes('/orgRisks')) {
                emitter.emit('changeRouteInTierRisks', selectedActionObj);
            } else if (this.$route.path.includes('/processInformation')) {
                emitter.emit('changeRouteInProcessInfo', selectedActionObj);
            } else if (this.$route.path.includes('/organizationInformation')) {
                emitter.emit('changeRouteInOrgInfo', selectedActionObj);
            }
        },
        actionOnClickingTableRow(rowData: any, index: any, column: any) {
            emitter.emit('rowData', rowData);
            console.log('rowDatarowDatarowData', rowData);
            console.log('entityIdentityId', this.entityId);
            if (column == 'actionButton' || column == 'infoAction') {
                if (this.selectedRowIndex === index) {
                    this.showDropDown = !this.showDropDown;
                } else {
                    this.showDropDown = true;
                    this.selectedRowIndex = index;
                }
            } else if (column == 'deleteinfo') {
                emitter.emit('deleteRow', rowData);
            } else if (column == 'History') {
                this.navigateWorkLog(rowData);
            } else if (column == 'reportAction') {
                console.log('a');
            } else if (column == 'deleteIcon') {
                let obj = {
                    entity: rowData,
                    index: index
                };
                emitter.emit('forDelete', obj);
            } else if (this.openPopup == true) {
                let obj = {
                    entityId: rowData.entityId,
                    index: index
                };
                let popupdata = {
                    entityData: rowData,
                    indexx: index
                };
                emitter.emit('showData', obj);
                emitter.emit('showData123', popupdata);
                emitter.emit('approvaldata', rowData);
            } else if (this.entityId == 20008 && this.navigate == true && this.openPopup == false) {
                this.goToSystemRisk(rowData);
            } else if (this.entityId == 20012 && this.navigate == true && this.openPopup == false) {
                this.openTaskEditing(rowData._id);
            } else if (this.entityId == 20009 && this.navigate == true && this.openPopup == false) {
                this.gotoSystemInformation(rowData._id);
            } else if (this.entityId == 20006 && this.navigate == true && this.openPopup == false) {
                this.gotoProcessInformation(rowData._id);
            } else if (this.entityId == 20007 && this.navigate == true && this.openPopup == false) {
                emitter.emit('gotoInfoRisksPage', rowData);
            } else if (this.entityId == 20001 && this.navigate == true && this.openPopup == false) {
                this.gotoOrganizationInformation(rowData._id);
            } else if (this.entityId == 20013 && this.navigate == true && this.openPopup == false) {
                console.log('HIIIRAAAA', this.entityId);
                this.gotoInformationsClassification(rowData.label);
            } else {
                console.log('No action for TR', this.entityId);
            }
            this.showBlock = false;
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
            this.tipText = row[column];
            this.tipColumn = column;
            this.tipIndex = index;

            switch (status) {
                case 'text':
                    this.handleTextTooltip(e);
                    break;
                case 'circleButton':
                    let findVal:any = this.dbSeverity.find((obj:any)=>{return obj.value == row[column]});

                    if(findVal != undefined){
                        this.tipText = findVal.key
                    }else{
                        this.tipText = row[column]
                    }

                    let obj:any = {
                        tipText : this.tipText
                    }
                    emitter.emit('activiteToolTip',obj)
            }
        },
        handleTextTooltip(e: any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                let obj:any = {
                    tipText : this.tipText
                }

              emitter.emit('activiteToolTip',obj)
            }
        },
        isTextOverflowed(element: any) {
            return element.scrollHeight > element.clientHeight;
        },
        getDateFormat(date: any) {
            if (date == '') {
                return 'None';
            } else {
                let now = moment(date);
                return now.format('DD-MM-YYYY');
            }
        },
        openTaskEditing(taskId: any) {
            this.$router.push({ name: 'taskEditPage', params: { taskId: taskId } });
        },
        gotoSystemInformation(systemId: any) {
            this.$router.push({ name: 'systemInformation', params: { id: systemId } });
        },
        gotoProcessInformation(processId: any) {
            this.$router.push({ name: 'processInformation', params: { processId: processId } });
        },
        gotoInformationsClassification(classificationTypee: any) {
            this.$router.push({ name: 'infoClassificationProperties', params: { classificationType: classificationTypee } });
        },
        goToSystemRisk(risk: any) {
            if (Object.keys(risk).includes('systemId')) {
                this.$router.push({ name: 'tierRisks', params: { riskId: risk.mappedRisk, type: 'systemRisks' } });
            } else if (Object.keys(risk).includes('processId')) {
                this.$router.push({ name: 'tierRisks', params: { riskId: risk.mappedRisk, type: 'processRisks' } });
            } else if (Object.keys(risk).includes('orgId')) {
                this.$router.push({ name: 'tierRisks', params: { riskId: risk.mappedRisk, type: 'orgRisks' } });
            }
        },
        gotoOrganizationInformation(orgId: any) {
            this.$router.push({ name: 'organizationInformation', params: { orgId: orgId } });
        },
        getTierName(id:any){
            let findObj = this.selectedTierMapping.find((obj:any)=>{return obj.id == id;});
            if(findObj == null || findObj == undefined){
                return '';
            }else{
                return findObj.value
            }
        },
        getOwnerName(ownerId: any) {
            let user = this.optionsUserArray.find((obj: any) => {
                return obj.value == ownerId;
            });
            if (user == undefined) {
                let empty = '';
                return empty;
            } else {
                return user.label;
            }
        },
        colorPropBg(format: any, scaleScore: any) {
            if (format == 'circle') {
                if (scaleScore == 5) {
                    return ['bg-darkred'];
                } else if (scaleScore == 4) {
                    return ['bg-error'];
                } else if (scaleScore == 3) {
                    return ['bg-yellow'];
                } else if (scaleScore == 2) {
                    return ['bg-green'];
                } else if (scaleScore == 1) {
                    return ['bg-lightgreen'];
                }
            } else if (format == 'border') {
                if (scaleScore == 5) {
                    return ['border-darkred border-4'];
                } else if (scaleScore == 4) {
                    return ['border-error border-4'];
                } else if (scaleScore == 3) {
                    return ['border-yellow border-4'];
                } else if (scaleScore == 2) {
                    return ['border-green border-4'];
                } else if (scaleScore == 1) {
                    return ['border-lightgreen border-4'];
                }
            }
        },
        colorRiskText(value: any) {
            if (value == 1) {
                return ['text-lightgreen font-semibold'];
            } else if (value == 2) {
                return ['text-green font-semibold'];
            } else if (value == 3) {
                return ['text-yellow font-semibold'];
            } else if (value == 4) {
                return ['text-error font-semibold'];
            } else if (value == 5) {
                return ['text-darkred font-semibold'];
            }
        },
        colorRiskBg(value: any) {
            if (value == 1) {
                return ['bg-veryLowColor border-solid border border-lightgreen'];
            } else if (value == 2) {
                return ['bg-lowColor border-solid border border-green'];
            } else if (value == 3) {
                return ['bg-moderateColor border-solid border border-yellow'];
            } else if (value == 4) {
                return ['bg-highColor border-solid border border-error'];
            } else if (value == 5) {
                return ['bg-veryHighColor border-solid border border-darkred'];
            }
        },
        colorInformationText(value: any) {
            if (value == 3) {
                return ['text-darkred font-semibold'];
            } else if (value == 2) {
                return ['text-yellow font-semibold'];
            } else if (value == 1) {
                return ['text-lightgreen font-semibold'];
            }
        },
        colorInformationBg(value: any) {
            if (value == 1) {
                return ['bg-lowColor border-solid border border-lightgreen'];
            } else if (value == 2) {
                return ['bg-moderateColor border-solid border border-yellow'];
            } else if (value == 3) {
                return ['bg-highColor border-solid border border-darkred'];
            }
        },
        colorTextRequestStatus(stat: any) {
            if (stat == 10410) {
                return ['text-darkred', 'border-solid border border-darkred font-semibold'];
            } else if (stat == 10408) {
                return ['text-primary', 'border-solid border border-primary font-semibold'];
            } else if (stat == 10403) {
                return ['text-lightgreen', 'border-solid border border-lightgreen font-semibold'];
            } else if (stat == 10409) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            } else if (stat == 10402) {
                return ['text-electricGreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-electricGreen'];
            } else if (stat == 10404) {
                return ['text-primary', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-primary'];
            } else if (stat == 10401) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            } else if (stat == 10406) {
                return ['text-yellow', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-yellow'];
            } else if (stat == 10407) {
                return ['text-green', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-green'];
            } else if (stat == 10405) {
                return ['text-red-600', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-red-600'];
            }
        },
        colorBgRequestStatus(code: any) {
            if (code == 10410) {
                return ['bg-darkred'];
            } else if (code == 10403) {
                return ['bg-lightgreen'];
            } else if (code == 10401) {
                return ['bg-darkred', 'opacity-10'];
            } else if (code == 10408) {
                return ['bg-primary', 'opacity-10'];
            } else if (code == 10402) {
                return ['bg-electricGreen', 'opacity-10'];
            } else if (code == 10404) {
                return ['bg-primary', 'opacity-10'];
            } else if (code == 10409) {
                return ['bg-darkred', 'opacity-10'];
            } else if (code == 10406) {
                return ['bg-yellow', 'opacity-10'];
            } else if (code == 10405) {
                return ['bg-error', 'opacity-10'];
            } else if (code == 10407) {
                return ['bg-green', 'opacity-10'];
            }
        },
        colorRiskStatusBg(Status: any) {
            if (Status == 10401) {
                return ['bg-darkred', 'opacity-10'];
            } else if (Status == 10402) {
                return ['bg-electricGreen', 'opacity-10'];
            } else if (Status == 10403) {
                return ['bg-primary', 'opacity-10'];
            } else if (Status == 10404) {
                return ['bg-primary', 'opacity-10'];
            } else if (Status == 10405) {
                return ['bg-lightgreen', 'opacity-10'];
            } else if (Status == 10406) {
                return ['bg-yellow', 'opacity-10'];
            }
        },
        colorRiskStatusText(Status: any) {
            if (Status == 10401) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            } else if (Status == 10402) {
                return ['text-electricGreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-electricGreen'];
            } else if (Status == 10403) {
                return ['text-primary', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-primary'];
            } else if (Status == 10404) {
                return ['text-primary', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-primary'];
            } else if (Status == 10405) {
                return ['text-lightgreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-lightgreen'];
            } else if (Status == 10406) {
                return ['text-yellow', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-yellow'];
            }
        },
        doFalse() {
            emitter.emit('deActiviteToolTip')
        },
        async getAllUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
            }
        },
        getOwnerType(input: any) {
            let type = this.orgType.find((l: any) => {
                return l.id == input;
            });
            if (type == undefined) {
                return input;
            } else {
                return type.value;
            }
        },
        colorTextCirclePriority(priority: any) {
            if (priority == 1) {
                return ['text-darkred', 'border-solid border border-darkred font-semibold'];
            } else if (priority == 2) {
                return ['text-error', 'border-solid border border-error font-semibold'];
            } else if (priority == 4) {
                return ['text-green', 'border-solid border border-green font-semibold'];
            } else if (priority == 3) {
                return ['text-yellow', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-yellow'];
            } else if (priority == 5) {
                return ['text-lightgreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-lightgreen'];
            }
        },
        color(value: any) {
            this.classificationData.find((g: any) => {
                return g.id == value;
            });
        },
        clickOnEditOrDeleteAction(data: any, index: any) {
            console.log('Table data', data);
            if (this.selectedRowIndex === index) {
                this.showTableActionsDropdown = !this.showTableActionsDropdown;
            } else {
                this.showTableActionsDropdown = true;
                this.selectedRowIndex = index;
                let selectedRow = {
                    entityData: data,
                    indexx: index
                };
                console.log('asdfghjkl;poiuytreqwertyuiop', selectedRow);
                this.popupDataEmittingObject = selectedRow;
            }
            console.log(data, index);
        },
        tableDataDeleteAction(data: any, index: any) {
            this.showTableActionsDropdown = !this.showTableActionsDropdown;
            let obj = {
                entityData: data,
                index: index
            };
            emitter.emit('deleteData', obj);
        },
        tableDataEditAction(data: any, index: any) {
            this.showTableActionsDropdown = !this.showTableActionsDropdown;
            let obj = {
                entityData: data,
                index: index
            };
            emitter.emit('showPopupData', obj);
        },
        changePriority(data: any) {
            emitter.emit('showPriorityPopup', data);
        },
        getPriorityObject(priorityValue: any) {
            this.priorityObject = this.risksPriorityValue.find((item: any) => {
                return item.value == priorityValue;
            });
        },
        colorBgCirclePriority(code: any) {
            if (code == 1) {
                return ['rounded-full bg-darkred'];
            } else if (code == 4) {
                return ['rounded-full bg-green'];
            } else if (code == 3) {
                return ['rounded-full bg-yellow', 'opacity-10'];
            } else if (code == 2) {
                return ['rounded-full bg-error', 'opacity-10'];
            } else if (code == 5) {
                return ['rounded-full bg-lightgreen', 'opacity-10'];
            }
        },
        colorPriorityText(value: any) {
            if (value == 5) {
                return ['text-lightgreen fontSizingForPriority font-semibold'];
            } else if (value == 4) {
                return ['text-green fontSizingForPriority font-semibold'];
            } else if (value == 3) {
                return ['text-yellow fontSizingForPriority font-semibold'];
            } else if (value == 2) {
                return ['text-error fontSizingForPriority font-semibold'];
            } else if (value == 1) {
                return ['text-darkred fontSizingForPriority font-semibold'];
            }
        },
        colorPriorityBg(value: any) {
            if (value == 5) {
                return ['border-solid border-4 border-lightgreen'];
            } else if (value == 4) {
                return ['border-solid border-4 border-green'];
            } else if (value == 3) {
                return ['border-solid border-4 border-yellow'];
            } else if (value == 2) {
                return ['border-solid border-4 border-error'];
            } else if (value == 1) {
                return ['border-solid border-4 border-darkred'];
            }
        }
    }
});
</script>
