<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="flex justify-between items-center h-8">
            <div>
                <h1>{{ businesss.entityName }}</h1>
            </div>
            <div class="flex items-center">
                <button class="mr-3" v-if="selectedIndex == 3 && systemAssesmentList.length != 0" @click="createAssesmentTask()" :class="checkedItems.length > 0 ? 'btnprimary mr-3' : 'btndisabled pointer-events-none mr-3'">Create</button>
                <button class="btnprimary mr-3 flex items-center" @click="addRiskForOrganiztaion()" v-if="selectedIndex == 0">Add</button>
                <button class="btnprimary mr-3 flex items-center" @click="addInformationForProcess()" v-if="selectedIndex == 1">Add</button>
                <button
                    v-if="selectedIndex != 2"
                    :class="(selectedIndex == 0 && risksDataJson.length != 0) || (selectedIndex == 1 && mappedInfoForProcess.length != 0) || (selectedIndex == 3 && systemAssesmentList.length != 0) ? 'filterButton' : 'btndisabled pointer-events-none'"
                    class="flex"
                    @click="filterOpen"
                >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <!-- <div class="mx-2.5">
                <div class="w-6/12 pt-5 flex flex-row borderBottom justify-between overflow-hidden cursor-pointer">
                    <div class="pb-1 w-12/12 adjust mr-1" v-for="(tab, tabIndex) in tabHead" :key="tabIndex" @click="toggleTabs(tab.id)">
                        <div :class="{ 'anime-left': !sideButton, 'anime-right': sideButton }">
                            <div ref="actualIndex" class="tab-name">{{ tab.displayName }}</div>
                            <div :class="{ progress: selectedIndex == tab.id }" class="h-1 p-0.75"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="progressbarSI h-1 mx-2.5"></div> -->

        <div class="mt-5 px-5 pb-5 bg-white w-full border border-lightgrey pt-5 relative rounded">
            <div class="group-buttons" v-if="isSmallScreen">
                <!-- Group navigation buttons for smaller screens -->
                <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': (selectedIndex === 0 || currentGroupIndex === 0) && isSmallScreen }">
                    <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                </div>
                <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': (selectedIndex === carouselHead.length - 1 || currentGroupIndex >= totalGroups - 1) && isSmallScreen }">
                    <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                </div>
            </div>
            <!-- <div v-else>
                Scroll buttons for larger screens
                <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': !canScrollLeft && !isSmallScreen }">
                    <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                </div>
                <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': !canScrollRight && !isSmallScreen }">
                    <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                </div>
            </div> -->
            <div>
                <div>
                    <div class="flex flex-row borderBottom cursor-pointer tab-container" ref="tabContainer" @scroll="handleScroll">
                        <div class="pb-1" :class="selectedIndex == tab.id ? 'adjust header font-semibold primary':'text-sm text-disabledgrey'" v-for="(tab, index) in visibleTabs" :key="index" @click="toggleTabs(tab)">
                            <div>
                                <div ref="actualIndex" class="cursor-pointer">{{ tab.displayName }}</div>
                                <div :class="{ progress: selectedIndex === tab.id }" class="h-1 p-0.75 relative rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="progressbarB rounded h-1"></div>
            </div>
            <div class = "w-full">
                <div v-if="selectedIndex == 0">
                    <newTable :tableRowData="risksDataJson" :headersData="riskHeaders" :openPopup="true" :navigate="false" :loadData="dataLoading"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
                <div class="businesssPopup" v-if="selectedIndex == 1">
                    <newTable :tableRowData="mappedInfoForProcess" :headersData="informationHeaders" :openPopup="true" :loadData="dataLoading"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
                <div v-if="selectedIndex == 2">
                    <fieldset :disabled="true">
                        <div>
                            <div class="statuscontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">


                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <label class="controllabel">Type</label>
                                            <div class="text-sm">{{ orgType.find(option => option.id === businesss.entityType)?.value || "-" }}</div>
                                            <!-- <select class="inputboxstyling" :class="{ 'is-invalid': v$.businesss.entityType.$error }" v-model="businesss.entityType">
                                                <option value="" selected disabled hidden>Please select one</option>
                                                <option v-for="(value, index) in orgType" :key="index" :value="value.id">{{ value.value }}</option>
                                            </select> -->
                                        </div>
                                    
                                    
                                        <div class="w-6/12 leftspacing">
                                            <label class="controllabel">Name</label>
                                            <div class="text-sm">{{businesss.entityName ? businesss.entityName : 'N/A'}}
                                            </div>
                                        </div>
                                  </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <label class="controllabel">Display Name</label>
                                            <div class="text-sm">{{businesss.entityDisplayName ? businesss.entityDisplayName : 'N/A'}}</div>
                                        </div>
                                        <div class="w-6/12 leftspacing">
                                            <label class="controllabel">Industry Sector</label>
                                            <div class="text-sm">{{businesss.industrySectorName ? businesss.industrySectorName : 'N/A'}}</div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <label class="controllabel">Country</label>
                                            <div class="text-sm">{{businesss.country ? businesss.country : 'N/A'}}</div>
                                           
                                        </div>
                                        <div class="w-6/12 leftspacing">
                                            <label class="controllabel">State</label>
                                            <div class="text-sm">{{businesss.state ? businesss.state : 'N/A'}}</div>
                                          
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <label class="controllabel">City</label>
                                            <div class="text-sm">{{businesss.city ? businesss.city : 'N/A'}}</div>
                                            
                                        </div>
                                        <div class="w-6/12 leftspacing">
                                            <label class="controllabel">PIN Code</label>
                                            <div class="text-sm">{{businesss.zipCode ? businesss.zipCode : 'N/A'}}</div>
                                          
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label class="controllabel">Address Line 1</label>
                                            <div class="text-sm">{{businesss.addressLineOne || 'N/A'}}</div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label class="controllabel">Address Line 2 <span class="text-xs">(Optional)</span></label>
                                            <div class="text-sm" v-if="businesss.addressLineTwo">{{businesss.addressLineTwo}}</div>
                                            <div class="text-sm" v-else>{{'N/A'}}</div>
                                            <!-- <input type="text" class="inputboxstyling" v-model="businesss.addressLineTwo" placeholder="Address Line 2" /> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div v-if="selectedIndex == 3">
                    <div v-if="discovery == true">
                        <div class="flex-col justify-center items-center">
                            <div class="loader mr-2"></div>
                        </div>
                        <p class="text-center font-semibold">Discovering Risks</p>
                    </div>
                    <div class="tableCornerRadius" v-if="discovery == false">
                        <div class="table-container">
                            <table>
                                <thead>
                                    <th class="header cursor-pointer" style="width: 5%;">
                                        <div class="flex items-center w-4">
                                            <input type="checkbox" class="checkbox cursor-pointer" v-model="selectAll" :disabled="sortedData.length == 0" @change="selectAllOptions($event)" />
                                        </div>
                                    </th>
                                    <th class="header cursor-pointer" style="width: 5%;" @click="sort('severity')">
                                        <div class="flex items-center w-28">
                                            <div>Severity</div>

                                            <div class="sort cursor-pointer" :class="className == 'severity' ? classSort : 'opacity-50'"></div>
                                        </div>
                                    </th>
                                    <th class="header cursor-pointer" style="width: 25%;" @click="sort('risk')">
                                        <div class="flex items-center w-44">
                                            <div>Risk</div>

                                            <div class="sort cursor-pointer" :class="className == 'risk' ? classSort : 'opacity-50'"></div>
                                        </div>
                                    </th>
                                    <th class="header cursor-pointer" style="width: 25%;" @click="sort('riskCategory')">
                                        <div class="flex items-center w-32">
                                            <div>Category</div>

                                            <div class="sort cursor-pointer" :class="className == 'riskCategory' ? classSort : 'opacity-50'"></div>
                                        </div>
                                    </th>
                                    <!-- <th class="header cursor-pointer" @click="sort('overallImpact')">
                                        <div class="flex items-center ml-4">
                                            <div>Impact</div>

                                            <div class="sort cursor-pointer" :class="className == 'overallImpact' ? classSort : 'opacity-50'"></div>
                                        </div>
                                    </th> -->
                                    <th class="header cursor-pointer" style="width: 15%;" @click="sort('likelihood')">
                                        <div class="flex items-center w-28">
                                            <div class="pl-2">Likelihood</div>

                                            <div class="sort cursor-pointer" :class="className == 'likelihood' ? classSort : 'opacity-50'"></div>
                                        </div>
                                    </th>

                                    <tr v-if="showFilters">
                                        <td></td>
                                        <td>
                                            <select class="widthForSelect" :class="filterDummy.severity == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="filterDummy.severity">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in dbSeverity" :key="pair" :value="pair.value">{{ pair.key }}</option>
                                            </select>
                                        </td>
                                        <td class="searchRow">
                                            <input type="text" class="inputboxStylingForFilter filter-font-size" placeholder="Search" v-model="filterDummy.risk" />
                                        </td>
                                        <td class="searchRow">
                                            <input type="text" class="inputboxStylingForFilter filter-font-size" placeholder="Search" v-model="filterDummy.riskCategory" />
                                        </td>
                                        <!-- <td>
                                            <select class="inputboxstyling resize" v-model="filterDummy.overallImpact">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in risksSeverityValue" :key="pair" :value="pair.id">{{ pair.value }}</option>
                                            </select>
                                        </td> -->
                                        <td class="searchRow">
                                            <select class="widthForSelect" :class="filterDummy.likelihood == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="filterDummy.likelihood">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in dbLikelihood" :key="pair" :value="pair.value">{{ pair.key }}</option>
                                            </select>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody v-if="sortedData.length == 0">
                                    <tr class="cursor-pointer rowHeight">
                                        <td :colspan="6">
                                            <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="nonEmptyTable" v-else>
                                    <tr v-for="(riskItem, index) in sortedData" class="rowHeight" :key="index">
                                        <td @mouseout="doFalse()">
                                            <input class="checkbox" type="checkbox" v-model="checkedItems" :value="riskItem._id" @change="changeInfo(riskItem, $event)" />
                                        </td>
                                        <td class="relative cursor-pointer" @mouseout="doFalse()">
                                            <!-- <div class=""> -->
                                                <div class="flex items-center justify-center rounded-full ml-3 w-5 h-5" @mousemove="toolTip(riskItem, 'severity', riskItem.severity, index, $event)" :class="colorPropBg('border', riskItem.severity)">
                                                    <div class="severityCircle rounded-full" :class="colorPropBg('circle', riskItem.severity)"></div>
                                                </div>
                                            <!-- </div> -->
                                        </td>

                                        <td class="relative" @mouseout="doFalse()">
                                            <div class="table-ellipsis">
                                                <p class="cursor-pointer text-xs" @mousemove="toolTip(riskItem, 'risk', riskItem.risk, index, $event)">{{ riskItem.risk }}</p>
                                            </div>
                                        </td>
                                        <td class="relative" @mouseout="doFalse()">
                                            <div class="table-ellipsis">
                                                <p class="cursor-pointer text-xs" @mousemove="toolTip(riskItem, 'riskCategory', riskItem.riskCategory, index, $event)">{{ riskItem.riskCategory }}</p>
                                            </div>
                                        </td>
                                      
                                        <td>
                                            <div class="flex items-center justify-center relative rounded regular text-xs w-24 h-5" :class="colorRiskText(riskItem.likelihood)">
                                                <div class="w-full h-full absolute rounded flex items-center justify-center " v-if="!isNaN(riskItem.likelihood)" :class="colorRiskBg(riskItem.likelihood)">
                                                    {{ getRiskStateValue(riskItem.likelihood) }}
                                                </div>
                                            </div>
                                            <!-- <div class="flex items-center relative rounded regular text-xs w-24 h-5" :class="colorRiskText(riskItem.likelihood)">
                                                <div class="w-full h-full absolute rounded" :class="colorRiskBg(riskItem.likelihood)"></div>
                                                <div class="flex rounded w-24 items-center regular text-xs tip">
                                                    <div v-if="!isNaN(riskItem.likelihood)">
                                                        <div class="flex w-24 items-center justify-center regular pb-px">
                                                            {{ getRiskStateValue(riskItem.likelihood) }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>
                        <div v-if="sortedData.length > 0">
                            <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData"></pagination>
                        </div>
                    </div>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
            </div>
        </div>
        
        <popup v-if="openRiskPopup" :popUp="openRiskPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Risk Information</h1>
                    <button class="float-right text-3xl" @click="onCancelButton('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <!-- Risk Div open -->
                            <div class="formcontainer flex flex-col">
                                <div>
                                    <div class="w-full upperspacing">
                                        <label class="controllabel">Risk</label>
                                        <textarea type="text" class="inputboxstyling" @input ="restrictCharacters('Risk')" v-model.trim="orgRisksTab.risk" placeholder="Risk" :class="{ 'is-invalid': (v$.orgRisksTab.risk.$error||displayErrorMessageOfRisk) }" />
                                        <div v-if="v$.orgRisksTab.risk.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.risk.required.$invalid">Required</div>
                                        </div>
                                        <div v-else-if="displayErrorMessageOfRisk == true">
                                            <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Risk div closed -->

                            <!-- Risk category , Risk owner div opened -->
                            <div class="formcontainer flex flex-col upperspacing">
                                <div class="row flex flex-row w-full">
                                    <div class="w-6/12">
                                        <label class="controllabel">Risk Category</label>
                                        <multiselect 
                                            v-model="orgRisksTab.riskCategory"
                                            :options="riskCategoryList" 
                                            :searchable="true" 
                                            :close-on-select="true" 
                                            :show-labels="false"
                                            :hide-selected="true"
                                            placeholder="Risk Category"
                                            open-direction="bottom"
                                            class="custom-multiselect"
                                            :class="{
                                                'is-invalid redBorder': v$.orgRisksTab.riskCategory.$error,
                                                'has-options': riskCategoryList && riskCategoryList.length > 0,
                                                'no-options':  !riskCategoryList.length,
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Categories Found</span>
                                        </template>
                                    </multiselect>

                                    <div v-if="v$.orgRisksTab.riskCategory.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.orgRisksTab.riskCategory.required.$invalid">Required</div>
                                    </div>
                                    </div>
                                    <div class="w-3/6 leftspacing">
                                        <label class="controllabel">Risk Owner</label>
                                        <multiselect
                                            v-model="orgRisksTab.riskOwner"
                                            :options="userOptions"
                                            :showNoOptions="false"
                                            @search-change="asyncFind"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Risk Owner"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid redBorder': v$.orgRisksTab.riskOwner.$error,
                                                'has-options': userOptions && userOptions.length > 0,
                                                'no-options': !userOptions.length &&  searchValue !== '',
                                            }"
                                        >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Members Found</span>
                                            </template>
                                        </multiselect>

                                        <div v-if="v$.orgRisksTab.riskOwner.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.riskOwner.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Risk category , Risk owner div closed -->

                            <!-- Threat div opened -->
                            <div class="formcontainer flex flex-col upperspacing">
                                <div>
                                    <div class="w-full">
                                        <label class="controllabel">Threat</label>
                                        <textarea type="text" class="inputboxstyling" v-model.trim="orgRisksTab.threatEvent" @input ="restrictCharacters('Threat')" placeholder="Threat" :class="{ 'is-invalid': (v$.orgRisksTab.threatEvent.$error||displayErrorMessageOfThreat) }" />
                                        <div v-if="v$.orgRisksTab.threatEvent.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.threatEvent.required.$invalid">Required</div>
                                        </div>
                                        <div v-else-if="displayErrorMessageOfThreat == true">
                                            <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Threat div closed -->
                            <!-- Threat source , Threat Category opened -->
                            <div class="formcontainer flex flex-col upperspacing">
                                <div class="row flex flex-row w-full">
                                    <div class="w-6/12">
                                        <label class="controllabel">Threat Source</label>
                                        <multiselect 
                                            v-model="orgRisksTab.threatSource"
                                            :options="threatSourceList" 
                                            :searchable="true" 
                                            :close-on-select="true" 
                                            :show-labels="false"
                                            :hide-selected="true"
                                            placeholder="Threat Source"
                                            open-direction="bottom"
                                            class="custom-multiselect"
                                            :class="{
                                                'is-invalid redBorder': v$.orgRisksTab.threatSource.$error,
                                                'has-options': threatSourceList && threatSourceList.length > 0,
                                                'no-options':  !threatSourceList.length,
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Source Found</span>
                                        </template>
                                    </multiselect>

                                    <div v-if="v$.orgRisksTab.threatSource.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.orgRisksTab.threatSource.required.$invalid">Required</div>
                                    </div>
                                        
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Threat Category</label>
                                        <multiselect 
                                            v-model="orgRisksTab.threatCategory"
                                            :options="threatCategoryList" 
                                            :searchable="true" 
                                            :close-on-select="true" 
                                            :show-labels="false"
                                            :hide-selected="true"
                                            placeholder="Threat Category"
                                            open-direction="bottom"
                                            class="custom-multiselect"
                                            :class="{
                                                'is-invalid redBorder': v$.orgRisksTab.threatCategory.$error,
                                                'has-options': threatCategoryList && threatCategoryList.length > 0,
                                                'no-options':  !threatCategoryList.length,
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Categories Found</span>
                                        </template>
                                    </multiselect>

                                    <div v-if="v$.orgRisksTab.threatCategory.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.orgRisksTab.threatCategory.required.$invalid">Required</div>
                                    </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <!-- Threat source , Threat Category closed -->

                            <!-- vulnerability div opened -->
                            <div class="formcontainer flex flex-col upperspacing">
                                <div>
                                    <div class="w-full">
                                        <label class="controllabel">Vulnerability</label>
                                        <textarea type="text" class="inputboxstyling" @input ="restrictCharacters('Vulnerability')" v-model.trim="orgRisksTab.vulnerability" placeholder="Vulnerability" :class="{ 'is-invalid': v$.orgRisksTab.vulnerability.$error||displayErrorMessageOfVulnerability }" />
                                        <div v-if="v$.orgRisksTab.vulnerability.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.vulnerability.required.$invalid">Required</div>
                                        </div>
                                        <div v-else-if="displayErrorMessageOfVulnerability == true">
                                            <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- vulnerability div closed -->

                            <h1 class="upperspacing">Impact Analysis</h1>
                            <div class="border-b mt-1 border-solid border-lightgrey"></div>

                            <div class="formcontainer flex flex-col upperspacing">
                                <div class="row flex flex-row w-full">
                                    <div class="w-6/12">
                                        <label class="controllabel">Strategic</label>
                                        <select class="inputboxstylingForSelect" v-model="orgRisksTab.business" :class="{ 'is-invalid': v$.orgRisksTab.business.$error,'inputboxstylingForSelectAtPopup': orgRisksTab.business === '','stylingForInputboxstylingForSelectAtPopup': orgRisksTab.business !== '' }" @change="calculateOverallImpact()">
                                            <option value="" selected disabled hidden>Please select one</option>

                                            <option class="optionColors" v-for="i in dbBussiness" :value="i.value" :key="i">
                                                {{ i.key }}
                                            </option>
                                        </select>
                                        <div v-if="v$.orgRisksTab.business.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.business.required.$invalid">Required</div>
                                        </div>
                                    </div>

                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Financial</label>
                                        <select class="inputboxstylingForSelect" v-model="orgRisksTab.financial" :class="{ 'is-invalid': v$.orgRisksTab.financial.$error,'inputboxstylingForSelectAtPopup': orgRisksTab.financial === '','stylingForInputboxstylingForSelectAtPopup': orgRisksTab.financial !== '' }" @change="calculateOverallImpact()">
                                            <option value="" selected disabled hidden>Please select one</option>

                                            <option class="optionColors" v-for="i in dbFinancial" :value="i.value" :key="i">
                                                {{ i.key }}
                                            </option>
                                        </select>
                                        <div v-if="v$.orgRisksTab.financial.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.financial.required.$invalid">Required</div>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>

                            <div class="formcontainer flex flex-col upperspacing">
                                <div class="row flex flex-row w-full">
                                    <div class="w-6/12">
                                        <label class="controllabel">Operations</label>
                                        <select class="inputboxstylingForSelect" v-model="orgRisksTab.operations" :class="{ 'is-invalid': v$.orgRisksTab.operations.$error,'inputboxstylingForSelectAtPopup': orgRisksTab.operations === '','stylingForInputboxstylingForSelectAtPopup': orgRisksTab.operations !== ''  }" @change="calculateOverallImpact()">
                                            <option value="" selected disabled hidden>Please select one</option>

                                            <option class="optionColors" v-for="i in dbOperations" :value="i.value" :key="i">
                                                {{ i.key }}
                                            </option>
                                        </select>
                                        <div v-if="v$.orgRisksTab.operations.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.operations.required.$invalid">Required</div>
                                        </div>
                                    </div>

                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Compliance</label>
                                        <select class="inputboxstylingForSelect" v-model="orgRisksTab.legal" :class="{ 'is-invalid': v$.orgRisksTab.legal.$error,'inputboxstylingForSelectAtPopup': orgRisksTab.legal === '','stylingForInputboxstylingForSelectAtPopup': orgRisksTab.legal !== ''  }" @change="calculateOverallImpact()">
                                            <option value="" selected disabled hidden>Please select one</option>

                                            <option class="optionColors" v-for="i in dbLegal" :value="i.value" :key="i">
                                                {{ i.key }}
                                            </option>
                                        </select>
                                        <div v-if="v$.orgRisksTab.legal.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.legal.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        
                            <h1 class="upperspacing">Risk Analysis</h1>
                            <div class="border-b pt-1 border-solid border-lightgrey"></div>
                            <div class="formcontainer">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-3/6">
                                        <label class="controllabel">Likelihood</label>
                                        <select class="inputboxstylingForSelect" v-model="orgRisksTab.likelihood" :class="{ 'is-invalid': v$.orgRisksTab.likelihood.$error,'inputboxstylingForSelectAtPopup': orgRisksTab.likelihood === '','stylingForInputboxstylingForSelectAtPopup': orgRisksTab.likelihood !== ''  }">
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option class="optionColors" v-for="i in dbLikelihood" :value="i.value" :key="i">
                                                {{ i.key }}
                                            </option>
                                        </select>
                                        <div v-if="v$.orgRisksTab.likelihood.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.likelihood.required.$invalid">Required</div>
                                        </div>
                                    </div>

                                     <div class="w-3/6 leftspacing">
                                        <label class="controllabel">Overall Impact</label>
                                        <select class="inputboxstyling" disabled v-model="orgRisksTab.overallImpact">
                                            <option value="" selected hidden>Please select one</option>
                                            <option v-for="i in dbOverAllImpact" :value="i.value" :key="i">
                                                {{ i.key }}
                                            </option>
                                        </select>
                                          
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttonposition flex justify-center items-center mt-2 mb-4 mx-0">
                        <button :disabled="clickOnButton" class="btnprimary mr-2 gap-2" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelButton('cancel')">Cancel</button>
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" v-if="addingRisk" @click="addOrUpdaterisk(orgRisksTab)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" v-else @click="addOrUpdaterisk(orgRisksTab)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Update</button>
                    </div>
                    <!-- <div class="buttonposition flex justify-center items-center mb-4 mx-0">
                            <button class="btnprimary mr-1.5" @click="onCancelButton('cancel')" :class="JSON.stringify(riskObjCopy) == JSON.stringify(orgRisksTab) ? 'btndisabled pointer-events-none' : 'btnprimary'">Cancel</button>

                            <button class="btnprimary" @click="submitForm" :class="JSON.stringify(riskObjCopy) == JSON.stringify(orgRisksTab) ? 'btndisabled pointer-events-none' : 'btnprimary'">Update</button>
                        </div> -->
                </div>
            </fieldset>
        </popup>

        <popup v-if="showInfoPopup" :popUp="showInfoPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Information</h1>
                    <button class="float-right text-3xl" @click="onCloseInformation()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <!-- <fieldset :disabled="information._id"> -->
            <fieldset>
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full" v-if="information.information != '' && !addInfo">
                                        <label class="controllabel">Information Category</label>
                                        <div class="text-sm">
                                            {{information.informationCategory}}
                                        </div>
                                    </div>
                                    <div class="w-full" v-else>
                                        <label class="controllabel">Information Category</label>
                                        <multiselect 
                                            v-model="information.informationCategory"
                                            :options="infoCategoryList" 
                                            :searchable="true" 
                                            :close-on-select="true" 
                                            :show-labels="false"
                                            :hide-selected="true"
                                            :clear-on-select="true"
                                            @select="changeInfoCategory(information.informationCategory)"
                                            placeholder="Information Category"
                                            open-direction="bottom"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid redBorder': v$.information.informationCategory.$error,
                                                'has-options': infoCategoryList && infoCategoryList.length > 0,
                                                'no-options':  !infoCategoryList.length,
                                            }"
                                            >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Categories Found</span>
                                            </template>
                                        </multiselect>

                                        <div v-if="v$.information.informationCategory.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.informationCategory.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full upperspacing">
                                    <div class="w-full" v-if="information.information != '' && !addInfo">
                                        <label class="controllabel">Information</label>
                                        <div>
                                            <div class="text-sm">{{information.information}}</div>
                                            <!-- <input type="text" class="disabledinputboxstyling" disabled v-model="information.information" /> -->
                                        </div>
                                    </div>
                                    <div class="w-full" v-else>
                                        <label class="controllabel">Information</label>
                                        
                                        <multiselect 
                                            v-model="information.information"
                                            :options="infoList" 
                                            :searchable="true" 
                                            :close-on-select="true" 
                                            :show-labels="false"
                                            :hide-selected="true"
                                            :disabled="!addInfo || information.informationCategory == ''"
                                            @select="changeInformation(information.information)"
                                            @deselect="changeInformation(information.information)" 
                                            placeholder="Information"
                                            open-direction="bottom"
                                            class="custom-multiselect"
                                            :class="{
                                                'is-invalid redBorder': v$.information.information.$error,
                                                'has-options': infoList && infoList.length > 0,
                                                'no-options':  !infoList.length,
                                            }"
                                        >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Info Found</span>
                                            </template>
                                        </multiselect>
                                        <div v-if="v$.information.information.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.information.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12" v-if="information.information != '' && !addInfo">
                                        <label class="controllabel">Information Owner</label>
                                        <div class="text-sm">
                                            {{information.informationOwner.label}}
                                        </div>
                                    </div>
                                    <div class="w-6/12" v-else>
                                        <label class="controllabel">Information Owner</label>
                                         <multiselect
                                            v-model="information.informationOwner"
                                            :options="optionsUserArray"
                                            :multiple="false"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            label="label"
                                            open-direction="bottom"
                                            :hide-selected="true"
                                            track-by="value"
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Information Owner"
                                            deselectLabel=""
                                            :showNoOptions="false"
                                            selectLabel=""
                                            disabled
                                            class="custom-multiselect userSelect"
                                            
                                        >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Members Found</span>
                                            </template>
                                        </multiselect>

                                        <!-- :class="{
                                                'is-invalid redBorder': v$.information.informationOwner.$error
                                            }"
                                        <div v-if="v$.information.informationOwner.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.informationOwner.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                    <div class="w-6/12" :class="!information._id ? 'leftspacing': ''" v-if="information.confidentiality != '' || !addInfo || !addCIAInfo">
                                        <label class="controllabel">Confidentiality</label>
                                        <div v-for="(dataValue, indexNum) in dbConfidentiality" :key="indexNum">
                                            <div v-if="information.confidentiality == dataValue.value">
                                                <div class="text-sm">{{dataValue.key}}</div>
                                                <!-- <input type="text" class="disabledinputboxstyling" disabled v-model="dataValue.key" placeholder="Security Category" /> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing" v-else>
                                        <label class="controllabel">Confidentiality</label>
                                        <select
                                            v-on:change="getMaxIdValue()"
                                            class="disabledinputboxstyling disabledselect inputboxstyling"
                                            v-model="information.confidentiality"
                                            disabled
                                            
                                        >
                                            <option value="" selected disabled hidden>Confidentiality</option>
                                            <option v-for="obj in dbConfidentiality" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>

                                        <!-- :class="{
                                                'is-invalid': v$.information.confidentiality.$error
                                            }"

                                        <div v-if="v$.information.confidentiality.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.confidentiality.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12" v-if="information.integrity != '' || !addInfo || !addCIAInfo">
                                        <label class="controllabel">Integrity</label>
                                        <div v-for="(dataValue, indexNum) in dbIntegrity" :key="indexNum">
                                            <div v-if="information.integrity == dataValue.value">
                                               <div class="text-sm">{{dataValue.key}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-6/12" v-else>
                                        <label class="controllabel">Integrity</label>
                                        <select
                                            v-on:change="getMaxIdValue()"
                                            class="disabledinputboxstyling disabledselect inputboxstyling"
                                            v-model="information.integrity"
                                            disabled
                                            
                                        >
                                            <option value="" selected disabled hidden>Integrity</option>
                                            <option v-for="obj in dbIntegrity" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>

                                        <!-- :class="{
                                                'is-invalid': v$.information.integrity.$error
                                            }"

                                        <div v-if="v$.information.integrity.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.integrity.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                    <div class="w-6/12" :class="information._id ? '' : 'leftspacing'" v-if="information.availability != '' || !addInfo || !addCIAInfo">
                                        <label class="controllabel">Availability</label>
                                        <div v-for="(dataValue, indexNum) in dbAvailability" :key="indexNum">
                                            <div v-if="information.availability == dataValue.value">
                                                 <div class="text-sm">{{dataValue.key}}</div>
                                                <!-- <input type="text" class="disabledinputboxstyling" disabled v-model="dataValue.key" placeholder="Security Category" /> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing" v-else>
                                        <label class="controllabel">Availability</label>
                                        <select
                                            v-on:change="getMaxIdValue()"
                                            class="disabledinputboxstyling disabledselect inputboxstyling"
                                            v-model="information.availability"
                                            disabled
                                            
                                        >
                                            <option value="" selected disabled hidden>Availability</option>
                                            <option v-for="obj in dbAvailability" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>

                                        <!-- :class="{
                                                'is-invalid': v$.information.availability.$error
                                            }"

                                        <div v-if="v$.information.availability.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.availability.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
                            <div class="row flex flex-row w-full upperspacing">
                                <div class="w-6/12" v-if="information.classification != '' || !addInfo">
                                        <label class="controllabel">Classification</label>
                                        <div v-for="(dataValue, indexNum) in dbInformationClassification" :key="indexNum">
                                            <div v-if="information.classification == dataValue.value">
                                                <div class="text-sm">{{dataValue.key}}</div>
                                                <!-- <input type="text" class="disabledinputboxstyling disabledselect" disabled v-model="dataValue.key" placeholder="Classification" /> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-6/12" v-else>
                                        <label class="controllabel">Classification</label>
                                        <select
                                            class="disabledinputboxstyling disabledselect inputboxstyling"
                                            v-model="information.classification"
                                            disabled
                                            :class="{
                                                'is-invalid': v$.information.classification.$error
                                            }"
                                        >
                                            <option value="" selected disabled hidden>Classification</option>
                                            <option v-for="obj in dbInformationClassification" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>

                                        <div v-if="v$.information.classification.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.classification.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12" v-if="information.securityCategory != '' && !addInfo">
                                        <div v-for="(dataValue, indexNum) in dbSecurityCategory" :key="indexNum">
                                            <div v-if="information.securityCategory == dataValue.value">
                                                <label class="controllabel">Security Category</label>
                                                <div class="text-sm">{{dataValue.key}}</div>
                                                <!-- <input type="text" class="disabledinputboxstyling" disabled v-model="dataValue.key" placeholder="Security Category" /> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="addInfo" class="buttonposition flex justify-center items-center my-4 mx-0">
                        <button class="btnprimary mr-1.5" :class="disableStatusInfo ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelBtnInfo()">Cancel</button>
                        <button @click="addInformationToObject()" :class="disableStatusInfo ? 'btnprimary' : 'btndisabled pointer-events-none'">Add</button>
                    </div>

                    <div class="mx-4 border-b border-solid border-lightgrey" v-if="addInformationObj.length > 0"></div>
                    <div class="px-4 flex flex-row justify-between" v-for="(info, index) in addInformationObj" :key="info">
                        <div class="statuscontainer p-2 w-11/12 my-2 border-solid border border-lightgrey rounded" v-if="addInformationObj.length > 0">
                            <div class="text-sm font-light text-gray bg-white">
                                <ul>
                                    <li class="text-sm flex flex-row items-center justify-between">
                                        {{ info.information }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="flex justify-center items-center">
                            <button class="float-right" @click="onInfoDelete(index)">
                                <img src="@/assets/deleteIcon.svg" class="h-5 w-5 cursor-pointer" />
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="addInformationObj.length > 0" class="buttonposition flex justify-center items-center my-4 mx-0">
                    <button class="btnprimary mr-1.5" @click="onResetBtnInfo(information)">Reset</button>
                    <button class="btnprimary" @click="saveInformation()">Save</button>
                </div>
            </fieldset>
        </popup>

        <popup class="applyPopup" v-if="applyPopup" :popUp="applyPopup">
            <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-end items-start flex">
                <div class="relative w-full max-w-xl">
                    <!--content-->
                    <div class="border-0 rounded relative flex flex-col w-full bg-white risksAcceptance h-screen">
                        <!--header-->
                        <div class="flex items-center justify-between p-3 rounded popupheadingcontainer">
                            <h1 v-if="thisScreen == 'Acceptance'">Risk Accept</h1>
                            <h1 v-if="thisScreen == 'reset'">Risk Reset</h1>
                            <h1 v-if="thisScreen == 'deactive'">Risk Delete</h1>
                            <h1 v-if="thisScreen == 'Avoid'">Risk Avoid</h1>
                            <h1 v-if="thisScreen == 'Transfer'">Risk Avoid</h1>
                            <button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="onClosebtnAction()">
                                <!-- <XIcon class="h-5 w-5" /> -->
                                <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                            </button>
                        </div>
                        <div class="border-b border-solid border-lightgrey ml-4 mr-4"></div>
                        <div>
                            <div class="impactcontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-col">
                                        <div class="upperspacing">
                                            <label class="controllabel">Organization</label>
                                            <div class="text-xs flex flex-wrap">
                                            <div v-for="org in mainOrg" :key="org.value" class="mr-2 mb-2 bg-primary text-white px-2 py-1 rounded">
                                                {{ mappedOrgOptions.find(option => option.value === org.value)?.label || "-" }}
                                            </div>
                                        </div>
                                            <!-- {{mappedOrgOptions}} -->
                                            <!-- <multiselect
                                                v-model="mainOrg"
                                                :options="mappedOrgOptions"
                                                open-direction="bottom"
                                                :multiple="true"
                                                :disabled="true"
                                                label="label"
                                                track-by="value"
                                                placeholder="Systems"
                                                class="custom-multiselect"
                                            >
                                            </multiselect> -->
                                            
                                        </div>
                                        <div class="border-b border-solid border-lightgrey upperspacing"></div>
                                        <div class="w-full upperspacing">
                                            <label v-if="thisScreen == 'Acceptance'" class="controllabel">Reason for Accepting Risk / Justification</label>
                                            <label v-if="thisScreen == 'reset'" class="controllabel">Reason for Reset Risk / Justification</label>
                                            <label v-if="thisScreen == 'deactive'" class="controllabel">Reason for Delete Risk / Justification</label>
                                            <label v-if="thisScreen == 'Avoid'" class="controllabel">Reason for Avoid Risk</label>
                                            <label v-if="thisScreen == 'Transfer'" class="controllabel">Reason for Transfer Risk</label>
                                            <textarea type="text" class="inputboxstyling h-20 max-h-48" v-model.trim="applyObj.comments" placeholder="Reason / Justification"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--footer-->
                        <div class="buttonposition flex justify-center items-center">
                            <button :disabled="clickOnButton" @click="cancelAceeptResetBtn()" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" class="mr-3">Cancel</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'Acceptance'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="createTaskForAction(applyObj, 10403)">Apply</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'Avoid'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="createTaskForAction(applyObj, 10419)">Apply</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'Transfer'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'">Transfer</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'reset'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary" @click="createTaskForAction(applyObj, 10407)">Apply</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'deactive'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary" @click="createTaskForAction(applyObj, 10404)">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </popup>

        <popup v-if="showPriorityPopUp">
            <template v-slot:header>
                <div class="flex items-center justify-between py-4 px-2 rounded">
                    <h1>Risk Prioritize</h1>

                    <button class="float-right text-3xl" @click="closePriorityPopup()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>

            <div class="popup-priority">
                <div class="w-full p-3 space-y-2">
                    <div class="w-full">
                        <label class="controllabel font-normal text-sm mb-1" for="vulnerability">Risk Name</label>
                        <input
                            v-model.trim="riskPriorityShow.risk"
                            disabled
                            placeholder="Name of the Risk"
                            id="id"
                            class="inputboxstyling"
                            :class="{
                                'is-invalid': v$.riskPriorityShow.risk.$error
                            }"
                        />
                        <div class="text-red-500" v-if="v$.riskPriorityShow.risk.$error">
                            <div v-if="v$.riskPriorityShow.risk.required.$invalid" class="error-text text-xs">Risk name is required</div>
                        </div>
                    </div>

                    <div class="w-1/2">
                        <label class="controllabel">Priority</label>
                        <select class="inputboxstyling" v-model="riskPriorityShow.priority">
                            <option v-for="(priority, index) in dbPriority" :key="index" :value="priority.value">{{ priority.key }}</option>
                        </select>
                    </div>
                    <div class="text-red-500" v-if="v$.riskPriorityShow.priority.$error">
                        <div v-if="v$.riskPriorityShow.priority.required.$invalid" class="error-text text-xs">Please change priority</div>
                    </div>
                    <div v-if="this.riskPriorityShow.priority !== this.priorityDummy.priority">
                        <div class="row flex flex-row w-full">
                            <div class="w-full">
                                <label for="Risk" class="controllabel">Justification</label>
                                <textarea
                                    class="inputboxstyling"
                                    placeholder="Justification"
                                    v-model.trim="riskPriorityShow.justification"
                                    :class="{
                                        'is-invalid': v$.riskPriorityShow.justification.$error
                                    }"
                                />
                            </div>
                        </div>
                        <div class="text-red-500" v-if="v$.riskPriorityShow.justification.$error">
                            <div v-if="v$.riskPriorityShow.justification.required.$invalid" class="error-text text-xs">Justification is required</div>
                        </div>
                    </div>

                    <div class="buttonposition flex justify-center items-center mt-9">
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" :class="JSON.stringify(riskPriorityShow) !== JSON.stringify(priorityDummy) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="cancelPriorityBtn()">Cancel</button>
                        <button :disabled="clickOnButton" @click="addOrUpdatePriority()" :class="JSON.stringify(riskPriorityShow) !== JSON.stringify(priorityDummy) ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
                    </div>
                </div>
            </div>
        </popup>
    </div>
</template>
<style scoped>
.tab-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 60px !important; 
}
.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #152a43;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}
.progressBarHeight {
    height:4px;
    position:relative;
}
.midHead {
    width: 160px !important;
}
.widthForSelect{
    width : 100px !important;
}
.redBorder {
	border: 1px solid red !important;
	border-radius: 5px !important;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.nonEmptyTable tr:last-child {
    border-bottom: 1px solid #e9e9e9;
}
.anime-left {
    animation-name: moveLeft;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
.widthForSelect{
    width : 100px !important;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
@keyframes moveLeft {
    0% {
        opacity: 1;
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(0);
    }
}
.anime-right {
    animation-name: moveRight;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
@keyframes moveRight {
    0% {
        opacity: 1;
        transform: translateX(-100vw);
    }
    100% {
        transform: translateX(0);
    }
}
/* .progressbarSI {
    border: 2px solid #e9e9e9;
    background: #e9e9e9;
    position: relative;
    top: -8px;
    border-radius: 2px;
} */
.progressbarB {
    border: 2px solid #e9e9e9;
    background: #e9e9e9;
    position: relative;
    top: -6px;
}
.progress {
    background: #152a43;
    top: 2px;
}
.setBox {
    width: initial;
}

.redBorder {
    border: 1px solid red !important;
}
.adjust {
    flex-shrink: 0;
}
.button-adjust {
    position: relative;
    top: -30px;
    z-index: 1;
    cursor: pointer;
}
.borderBottom {
    position: relative;
    z-index: 2;
    cursor: pointer;
}
.businesssPopup label {
    font-size: 16px;
    font-family: Open Sans !important;
}
.z-index {
    z-index: 100;
}
.accept {
    width: 100px;
}
.ellipsis-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.veryLowOpacity{
    background-color: rgba(0, 176, 80, 0.2);
}
.LowOpacity{
    background-color: rgba(146, 208, 80, 0.2);
}
.ModerateOpacity{
    background-color: rgba(255, 153, 0, 0.2);
}
.HighOpacity{
    background-color: rgba(255, 0, 0, 0.2);
}
.veryHighOpacity{
    background-color: rgba(192, 0, 0, 0.2);
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import popup from '@/components/popup.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers, requiredIf, email } from '@vuelidate/validators';
const numeric = helpers.regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
import { emitter, toast } from '@/main';
import moment from 'moment';
import store from '@/store';
import { mapGetters } from 'vuex';
import dropdown from '@/components/dropdown.vue';
import breadCrumb from '@/components/breadCrumb.vue';

import pagination from '@/components/pagination.vue';
const alphabet = helpers.regex(/^[a-zA-Z]+$/);
import newTable from '@/components/newTable.vue';
import multiselect from 'vue-multiselect'
// import { zip } from 'lodash';
const zip = helpers.regex(/^\d{5}(?:\d{1})?$/);
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            b:[],
            pop: false,
            xPos: '',
            yPos: '',
            routesArray: [],
            //Pagination
            dynamicPagination: '',
            paginationObj: [],

            pageSize: 20,
            currentPage: 1,
            pageBoxes: 3,
            pageIndexStart: [],
            leftBox: 'left',
            rightBox: 'right',
            pageIndexEnd: [],
            totalPages: '',
            clickOnButton: false,
            pagesArray: [],
            sideButton: false,
            start: '',
            end: '',
            //Sort
            currentSort: 'name',
            currentSortDir: 'asc',
            className: '',
            addingRisk: false,
            classSort: '',
            selectedIndex: null,
            showFilters: false,
            selectedCategory: [],
            systemAssesmentList: [],
            selectAll: false,
            checkedItems: [],
            disabled: false,
            discovery: false,
            dummyOrgObject: {},
            openRiskPopup: false,
            rowData: [],
            rootRisksList: [],
            riskCategoryList: [],
            threatSourceList: [],
            threatCategoryList: [],
            objToBeModified: {},
            thisScreen: '',
            riskObjCopy: [],
            optionsUserArray: [],
            users: [],
            mappedOrgOptions: [],
            mainOrg: [],
            applyPopup: false,
            risksDataJson: [],
            navIndexNums: [],
            showInfoPopup: false,
            rootInformationsList: [],
            mappedInfoForProcess: [],
            savedInformationSets: [],
            infoCategoryList: [],
            infoList: [],
            addInfo: false,
            addInformationObj: [],
            selectedTabObj: { displayName: 'Risks', name: 'Risk', id: 0 },
            informationHeaders: ['entityId', '_id', 'informationCategory', 'information', 'securityCategory'],
            riskHeaders: ['severity', 'risk', 'riskOwnerName', 'createdAt', 'updatedAt', 'statusCode', 'priority', 'History', 'actionButton'],
            carouselHead: [
                { displayName: 'Risks', name: 'Risk', id: 0 },
                { displayName: 'Information', name: 'Information', id: 1 },
                { displayName: 'Properties', name: 'Properties', id: 2 },
                { displayName: 'Risk Discovery', name: 'Risk Discovery', id: 3 }
            ],
            applyObj: {
                organization: [],
                comments: ''
            },
            filterDummy: {
                severity: '',
                overallImpact: '',
                likelihood: '',
                statusCode: ''
            },
            organizationList: [],
            tasksData: [],
            businesss: {
                entityName: '',
                entityDisplayName: '',
                industrySectorName: '',
                emailAddress: '',
                phoneNumber: '',
                addressLineOne: '',
                addressLineTwo: '',
                entityType: '',
                city: '',
                state: '',
                zipCode: '',
                country: ''
            },
            information: {
                id: '',
                information: '',
                informationCategory: '',
                informationOwner: '',
                confidentiality: '',
                integrity: '',
                availability: '',
                securityCategory: '',
                org: [],
                classification: ''
            },
            countriesStatesCities: [
                {
                    CountryName: 'India',
                    States: [
                        {
                            StateName: 'Maharashtra',
                            Cities: ['Pune', 'Nagpur', 'Mumbai']
                        },
                        {
                            StateName: 'Kerala',
                            Cities: ['Kochi', 'Munnar']
                        },
                        {
                            StateName: 'Telangana',
                            Cities: ['Hyderabad', 'Nizamabad', 'Warangal']
                        },
                        {
                            StateName: 'Andhra Pradesh',
                            Cities: ['Vijayawada', 'Vizag', 'Rajamundry']
                        }
                    ]
                },
                {
                    CountryName: 'Australia',
                    States: [
                        {
                            StateName: 'Aukland',
                            Cities: ['GlenField', 'Henderson', 'MilFord']
                        },
                        {
                            StateName: 'Melbourne',
                            Cities: ['Melbourne', 'South Oakleigh']
                        }
                    ]
                }
            ],
            orgType: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Subsidiary'
                },
                {
                    id: 3,
                    value: 'Branch'
                }
            ],
            ownerTypeValues: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Secondary'
                }
            ],
            orgRisksTab: {
                risk: '',
                threatCategory: '',
                threatSource: '',
                vulnerability: '',
                threatEvent: '',
                business: '',
                operations: '',
                legal: '',
                financial: '',
                likelihood: '',
                overallImpact:'',
                riskCategory: '',
                riskOwner: '',
            },
            showPriorityPopUp: false,
            riskPriorityShow: {},
            mainRisk: {},
            priorityDummy: {},
            visibleTabs: [], // Tabs visible on screen
            currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
            totalGroups: 0, // Total number of groups for small screens
            userOptions:[],

            addCIAInfo: true,
            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,
			displayErrorMessageOfRisk : false,
			displayErrorMessageOfThreat : false,
			displayErrorMessageOfVulnerability : false,
            searchValue:''
        };
    },
    components: {
        popup,
        newTable,
        breadCrumb,
        dropdown,
        pagination,
        multiselect,
        toolTip
    },
    validations() {
        return {
            businesss: {
                entityName: { required },
                entityDisplayName: { required },
                industrySectorName: { required },
                // emailAddress: { required, email },
                // phoneNumber: { required, numeric },
                addressLineOne: { required },
                entityType: { required },
                city: { required, alphabet },
                state: { required, alphabet },
                country: { required, alphabet },
                zipCode: { required, zip }
            },
            information: {
                informationCategory: { required },
                information: { required },
                informationOwner: { required },
                confidentiality: { required },
                integrity: { required },
                availability: { required },
                securityCategory: { required },
                classification: { required }
            },
            orgRisksTab: {
                risk: { required },
                riskOwner: { required },
                riskCategory: { required },
                threatEvent: { required },
                threatSource: { required },
                threatCategory: { required },
                vulnerability: { required },
                business: { required },
                operations: { required },
                legal: { required },
                financial: { required },
                likelihood: { required },
            },
            riskPriorityShow: {
                risk: { required },
                priority: { required },
                justification: { required }
            }
        };
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo',
            dbSeverity: 'dbSeverity',
            dbLikelihood: 'dbLikelihood',
            dbBussiness: 'dbBussiness',
            dbOrganization: 'dbOrganization',
            dbOperations: 'dbOperations',
            dbLegal: 'dbLegal',
            dbFinancial: 'dbFinancial',
            dbTechnical: 'dbTechnical',
            dbProcessCriticality: 'dbProcessCriticality',
            dbAvailability: 'dbAvailability',
            dbConfidentiality: 'dbConfidentiality',
            dbIntegrity: 'dbIntegrity',
            dbInformationClassification: 'dbInformationClassification',
            dbSecurityCategory: 'dbSecurityCategory',
            dbPriority: 'dbPriority',
            dbOverAllImpact: 'dbOverAllImpact'

        }),

        disableStatusInfo: function (): any {
            let output = false;
            let val: any = [];
            Object.values(this.information).forEach((value: any) => {
                val.push(value);
            });
            for (let n of val) {
                if (n != '') {
                    output = true;
                }
            }
            return output;
        },
        sortedData: function (): any {
            return [...this.paginationObj]
                .filter((row: any) => {
                    for (const key in this.filterDummy) {
                        const inputValue = this.filterDummy[key];
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
        disableStatus: function (): any {
            if (this.selectedIndex == 0) {
                let disableStatusCondition;
                if (this.orgRisksTab && !this.addingRisk) {
                    if (JSON.stringify(this.orgRisksTab) == JSON.stringify(this.riskObjCopy)) {
                        disableStatusCondition = false;
                    } else {
                        disableStatusCondition = true;
                    }
                } else {
                    let valueArray: any = [];
                    Object.values(this.orgRisksTab).forEach((value: any) => {
                        valueArray.push(value);
                    });
                    for (let value of valueArray) {
                        if (value != '') {
                            disableStatusCondition = true;
                        }
                    }
                }
                return disableStatusCondition;
            } else if (this.selectedIndex == 2) {
                console.log('this.selectedIndex', this.selectedIndex);
                let disableStatusCondition;
                if (this.businesss) {
                    if (JSON.stringify(this.businesss) == JSON.stringify(this.dummyOrgObject)) {
                        disableStatusCondition = false;
                    } else {
                        disableStatusCondition = true;
                    }
                }
                return disableStatusCondition;
            } else {
                let output;
                if (this.editPop) {
                    let objCopy = Object.assign({}, this.information);
                    if (JSON.stringify(objCopy) == JSON.stringify(this.dummyObj)) {
                        output = false;
                    } else {
                        output = true;
                    }
                } else {
                    let val: any = [];
                    Object.values(this.information).forEach((value: any) => {
                        val.push(value);
                    });
                    for (let n of val) {
                        if (n != '') {
                            output = true;
                        }
                    }
                }
                return output;
            }
        },

        tabHead(): any {
            return this.carouselHead.filter((row: any, index: any) => {
                let start = (this.currentPage - 1) * this.pageSize;
                this.index = 0;
                let end = this.currentPage * this.pageSize;

                if (index >= start && index < end) return true;
            });
        }
    },

    async mounted() {
        this.dataLoading = true;
        
        this.visibleTabs = [...this.carouselHead];
        // this.selectedIndex = 0;
        
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.updateButtonStates();
        await this.getAllUsers();
        // await this.getRisksData();
        await this.getAllTasks();
        await this.getRootRisksList();

        this.dataLoading = false;

        this.navIndexNums = { index: 0, name: 'Risks' };
        let orgId = this.$route.params.id;
        this.routesArray = [
            { name: 'Organization', routeName: 'organization' },
            { name: this.businesss.entityName, routeName: 'organizationInformation', id: `${orgId}` }
        ];


        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });


        emitter.off('showData123');
        emitter.on('showData123', (isData: any) => {
            let comingId = isData.entityData._id;
            if (this.selectedIndex == 1) {
                let showObj = isData.entityData;
                this.showInfoEditPopUp(showObj);
            } else {
                let showObj = this.risksDataJson.find((obj: any) => {
                    return obj._id === comingId;
                });
                this.showPopupbyRow(showObj);
            }
        });

        emitter.off('changeRouteInOrgInfo');
        emitter.on('changeRouteInOrgInfo', (selectedActionObj: any) => {
            console.log('selectedActionObjs', selectedActionObj);
            let risk = selectedActionObj.data;
            let route = selectedActionObj.route;
            
            this.changeRoute(risk, route);
        });

        emitter.off('showPriorityPopup');
        emitter.on('showPriorityPopup', (isData: any) => {
            this.showPriorityPopUpAction(isData);
        });
    },
    methods: {
         async calculateOverallImpact(){
            let a1 = this.orgRisksTab.business == '' ? 0 : this.orgRisksTab.business;
            let a2 = this.orgRisksTab.operations == '' ? 0 : this.orgRisksTab.operations;
            let a3 = this.orgRisksTab.legal == '' ? 0 : this.orgRisksTab.legal;
            let a4 = this.orgRisksTab.financial == '' ? 0 : this.orgRisksTab.financial;

            let arr = [a1, a2, a3, a4]

            let avg =  arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
            this.orgRisksTab.overallImpact = Math.max(Math.round(avg), 1);
        },
        sort: function (s: any) {
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        asyncFind(query:any){
            this.searchValue = query
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        asyncFindSystems(systems:any){
            this.searchValue = systems;
            if(systems==''){
                this.userSystemOptions = [];
            }
            else {
                this.userSystemOptions = this.mappedOrgOptions.filter((obj:any)=>{return obj.label.toLowerCase().includes(systems.toLowerCase())})
            }
        },
        doFalse() {
            emitter.emit('deActiviteToolTip')
        },
        toolTip(row: any, key: any, columnValue: any, index: any, e: any) {
            if (key == 'severity') {
                let findVal = this.dbSeverity.find((obj:any)=>{return obj.value == columnValue})

                if(findVal != undefined){
                    this.toolTipText = findVal.key
                }else{
                    this.toolTipText = columnValue
                }

                let obj:any = {
                    tipText : this.toolTipText
                }
                emitter.emit('activiteToolTip',obj)
            } else {
                this.handleTextTooltip(e, key, columnValue, index);
            }
        },
        handleTextTooltip(e: any, key: any, columnValue: any, index: any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                this.toolTipText = columnValue
                let obj:any = {
                    tipText : this.toolTipText
                }

                emitter.emit('activiteToolTip',obj)
            }
        },
        isTextOverflowed(element: any) {
            return element.scrollHeight > element.clientHeight;
        },
        cancelAceeptResetBtn() {
            this.applyObj.comments = '';
        },
        getMaxIdValue() {
            this.information.securityCategory = Math.max(this.information.confidentiality, this.information.availability, this.information.integrity);
        },
        getRiskStateValue(value: any) {
            let label = this.dbSeverity.find((l: any) => {
                return l.value == value;
            });
            if (label == undefined) {
                return '';
            } else {
                return label.key;
            }
        },
        onClosebtnAction() {
            this.v$.information.$reset();
            this.applyPopup = false;
            this.applyObj.comments = '';
            this.mappedOrgOptions = [];
            this.mainOrg = [];
        },
        onCancelButton(status: any) {
            this.v$.$reset();
            if (status == 'close') {
                this.openRiskPopup = false;
                this.displayErrorMessageOfRisk = false
                this.displayErrorMessageOfThreat = false
                this.displayErrorMessageOfVulnerability = false
            } else {
                if (this.orgRisksTab._id) {
                    this.orgRisksTab = { ...this.riskObjCopy };
                    this.displayErrorMessageOfRisk = false
                    this.displayErrorMessageOfThreat = false
                    this.displayErrorMessageOfVulnerability = false
                } else {
                    Object.keys(this.orgRisksTab).forEach((prop) => {
                        Array.isArray(this.orgRisksTab[prop]) ? (this.orgRisksTab[prop] = []) : (this.orgRisksTab[prop] = '');
                    });
                    this.displayErrorMessageOfRisk = false
                    this.displayErrorMessageOfThreat = false
                    this.displayErrorMessageOfVulnerability = false
                }
            }
        },
        onCancelBtnInfo() {
            this.addCIAInfo = true;
            this.v$.information.$reset();
            Object.keys(this.information).forEach((prop) => {
                Array.isArray(this.information[prop]) ? (this.information[prop] = []) : (this.information[prop] = '');
            });
        },
        restrictCharacters(code: any){
            if(code === 'Threat'){
                if(this.orgRisksTab.threatEvent.length > 300){
                this.displayErrorMessageOfThreat = true;
                }else if(this.orgRisksTab.threatEvent.length < 300 || this.orgRisksTab.threatEvent.length == 300){
                    this.displayErrorMessageOfThreat = false;
                }
            } else if(code === 'Risk'){
                if(this.orgRisksTab.risk.length > 300){
                    this.displayErrorMessageOfRisk = true;
                }else if(this.orgRisksTab.risk.length < 300 || this.orgRisksTab.risk.length == 300){
                    this.displayErrorMessageOfRisk = false;
                }
            } else if(code === 'Vulnerability'){
                if(this.orgRisksTab.vulnerability.length > 300){
                    this.displayErrorMessageOfVulnerability = true;
                }else if(this.orgRisksTab.vulnerability.length < 300 || this.orgRisksTab.vulnerability.length == 300){
                    this.displayErrorMessageOfVulnerability = false;
                }
            }
            
        },
        showInfoEditPopUp(obj: any) {  
            this.showInfoPopup = true;
            this.optionsUserArray.filter((optionsobj:any)=>{
                if(optionsobj.value == obj.informationOwner) {
                let a={label:optionsobj.label,value:optionsobj.value};
                obj.informationOwner = a;                
                }  
            })
            this.addInfo = false;
            this.information = { ...obj };
        },
        showPopupbyRow(obj: any) {            
            this.openRiskPopup = true;
            this.optionsUserArray.filter((optionsobj:any)=>{
              
                if(optionsobj.value == obj.riskOwner) {
                let a={label:optionsobj.label,value:optionsobj.value};
                obj.riskOwner = a;
                }  
            })
            this.orgRisksTab = { ...obj };
            this.addingRisk = false;
            this.riskObjCopy = { ...this.orgRisksTab };
        },
        changeInfoCategory(id: any) {
            this.infoList = [];
            this.information.confidentiality = '';
            this.information.availability = '';
            this.information.integrity = '';
            this.information.securityCategory = '';
            this.information.information = '';

            this.rootInformationsList.map((obj: any) => {
                if (obj.informationCategory == id) {
                    const isInTable = this.mappedInfoForProcess.some((row: any) => row.information === obj.information);
                    if (!isInTable) {
                        this.infoList.push(obj.information);
                        console.log("infoList after adding", this.infoList);
                    }
                    // this.mappedInfoForProcess.map((filterinfo: any) => {  
                    //     if(this.infoList.includes(filterinfo.information)){
                    //         const index = this.infoList.indexOf(filterinfo.information);
                    //             if (index !== -1) {
                    //             const removed = this.infoList.splice(index, 1);
                    //             }
                    //     }                          
                    //  console.log("Updated infoList", this.infoList);
                    // });
                }
            });
        },
        changeInformation(name: any) {
            if (name == '' || name == null || name == undefined) {
                this.addCIAInfo = true;
                this.information.id = '';
                this.information.confidentiality = '';
                this.information.availability = '';
                this.information.integrity = '';
                this.information.informationOwner = '';
            } else {
                this.addCIAInfo = false;
                let findObj: any = this.rootInformationsList.find((obj: any) => {
                    return obj.information == name;
                });
                this.information.id = findObj._id;
                this.information.confidentiality = findObj.confidentiality;
                this.information.availability = findObj.availability;
                this.information.integrity = findObj.integrity;
                this.information.classification = findObj.classification
                this.information.informationOwner = this.optionsUserArray.find((obj:any)=>{return obj.value == findObj.informationOwner});
                this.getMaxIdValue();
            }
        },
        riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        average(arr: any) {
            return arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
        },
        onCancelbtn(status: any, type: any) {
            if (type == 'businesss') {
                this.orgRisksTab = { ...this.riskObjCopy };
                this.businesss = { ...this.dummyOrgObject };
                this.objToBeModified = this.orgRisksTab;
            } else {
                this.v$.$reset();
                Object.keys(this.information).forEach((prop) => {
                    Array.isArray(this.information[prop]) ? (this.information[prop] = []) : (this.information[prop] = '');
                });
            }
        },
        colorRiskBg(status: any) {
            if (status == 1) {
                return ['veryLowOpacity'];
            } else if (status == 2) {
                return ['LowOpacity'];
            } else if (status == 3) {
                return ['ModerateOpacity'];
            } else if (status == 4) {
                return ['HighOpacity'];
            } else if (status == 5) {
                return ['veryHighOpacity'];
            }
        },
        colorRiskSeverity(status: any) {
            if (status == 1) {
                return ['font-semibold text-lightgreen'];
            } else if (status == 2) {
                return ['font-semibold text-green'];
            } else if (status == 3) {
                return ['font-semibold text-yellow'];
            } else if (status == 4) {
                return ['font-semibold text-error'];
            } else if (status == 5) {
                return ['font-semibold text-darkred'];
            }
        },
        colorRiskText(status: any) {
            if (status == 1) {
                return ['text-lightgreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-lightgreen'];
            } else if (status == 2) {
                return ['text-green', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-green'];
            } else if (status == 3) {
                return ['text-yellow', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-yellow'];
            } else if (status == 4) {
                return ['text-error', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-error'];
            } else if (status == 5) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            }
        },
        colorPropBg(format: any, scaleScore: any) {
            if (format == 'circle') {
                if (scaleScore == 5) {
                    return ['bg-darkred'];
                } else if (scaleScore == 4) {
                    return ['bg-error'];
                } else if (scaleScore == 3) {
                    return ['bg-yellow'];
                } else if (scaleScore == 2) {
                    return ['bg-green'];
                } else if (scaleScore == 1) {
                    return ['bg-lightgreen'];
                }
            } else if (format == 'border') {
                if (scaleScore == 5) {
                    return ['border-darkred border-2'];
                } else if (scaleScore == 4) {
                    return ['border-error border-2'];
                } else if (scaleScore == 3) {
                    return ['border-yellow border-2'];
                } else if (scaleScore == 2) {
                    return ['border-green border-2'];
                } else if (scaleScore == 1) {
                    return ['border-lightgreen border-2'];
                }
            }
        },
        paginationUpdations(currentPage: any, pageSize: any) {
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.systemAssesmentList;
            } else {
                this.systemAssesmentList = this.dummyList;
                for (const key in this.filterDummy) {
                    this.filterDummy[key] = '';
                }
            }
            emitter.emit('visible', this.showFilters);
        },
        async toggleTabs(navIndex: any) {
            this.dataLoading = true;

            this.index = navIndex.id;
            this.selectedIndex = navIndex.id;
            sessionStorage.setItem('selectedTab', navIndex.id);
            this.selectedTabObj = navIndex
            
            this.infoTableRow = [];
            if (navIndex.id == 0) {
                await this.getRisksData();
                this.navIndexNums.index = 0;
                this.navIndexNums.name = 'Risks';
                emitter.emit('visible', false);
                this.showFilters = false;
            } else if (navIndex.id == 1) {
                await this.getSingleEntity();
                await this.getRootInformationList();
                this.navIndexNums.index = 1;
                this.navIndexNums.name = 'Information';
                emitter.emit('visible', false);
                this.showFilters = false;
            } else if (navIndex.id == 2) {
                await this.getSingleEntity();
                this.navIndexNums.index = 2;
                this.navIndexNums.name = 'Properties';
            } else if (navIndex.id == 3) {
                this.discovery = true;
                await this.getRisksData();
                await this.getRisksDiscoveryForOrg();
                this.checkedItems = [];
                this.navIndexNums.index = 3;
                this.navIndexNums.name = 'Risk Discovery';
                emitter.emit('visible', false);
                this.showFilters = false;
                this.loadAndDisplayTable();
            }

            this.dataLoading = false;
        },
        loadAndDisplayTable() {
            setTimeout(() => {
                this.discovery = false;
            }, 300);
        },
        changeInfo(risk: any, e: any) {
            this.riskId = this.checkedItems;
            if (this.checkedItems.length === this.systemAssesmentList.length) {
                this.selectAll = true;
            } else {
                this.selectAll = false;
            }
        },
        selectAllOptions(e: any) {
            this.checkedItems = [];
            const checked = e.target.checked;
            if (checked) {
                this.riskId = this.checkedItems;
                for (let obj of this.systemAssesmentList) {
                    this.checkedItems.push(obj._id);
                    this.objLen = this.checkedItems.length;
                }
            } else {
                this.checkedItems = [];
            }
        },
        addRiskForOrganiztaion() {            
            this.v$.$reset();
            this.openRiskPopup = true;
            this.addingRisk = true;
            Object.keys(this.orgRisksTab).forEach((prop) => {
                if (prop == '_id' || prop == 'createdAt' || prop == 'updatedAt') {
                    delete this.orgRisksTab[prop];
                } else {
                    Array.isArray(this.orgRisksTab[prop]) ? (this.orgRisksTab[prop] = []) : (this.orgRisksTab[prop] = '');
                }
            });
        },
        addInformationForProcess() {
            this.showInfoPopup = true;
            this.addInfo = true;
},

        onResetBtnInfo() {
            this.addCIAInfo = true;
            this.addInformationObj = [];
        },
        onInfoDelete(index: any): any {
            this.addInformationObj.splice(index, 1);
            toast.error('Inormation Deleted', {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });
        },
        onCloseInformation() {
            if(this.addInformationObj && this.addInformationObj.length >0){
                this.addInformationObj = [];
            }
            this.v$.information.$reset();
            this.showInfoPopup = false;
            Object.keys(this.information).forEach((prop) => {
                if (prop == '_id' || prop == 'createdAt' || prop == 'updatedAt') {
                    delete this.information[prop];
                } else {
                    Array.isArray(this.information[prop]) ? (this.information[prop] = []) : (this.information[prop] = '');
                }
            });
        },
        changeRoute(risk: any, route: any) {
            this.mainRisk = risk;
            if (route == '/riskAcceptance' && Object.keys(this.mainRisk).includes('riskOwner')) {
                this.thisScreen = 'Acceptance';
                if (this.mainRisk.statusCode == 10403) {
                    toast.error(`Cannot Accept the Risk in this Status`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    if (Object.keys(this.mainRisk).includes('tasks')) {
                        let conditionForAccept = this.mainRisk.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForAccept) {
                            let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                            this.applyPopup = true;
                            this.mappedOrgOptions.push(orgObj);
                            this.mainOrg.push(orgObj);
                            this.applyObj.organization = [...this.mainOrg];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                        this.applyPopup = true;
                        this.mappedOrgOptions.push(orgObj);
                        this.mainOrg.push(orgObj);
                        this.applyObj.organization = [...this.mainOrg];
                    }
                }
            } else if (route == '/mitigateRisk' && Object.keys(this.mainRisk).includes('riskOwner')) {
                if (this.mainRisk.statusCode == 10402 || this.mainRisk.statusCode == 10406) {
                    this.thisScreen = 'Mitigate';
                    let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                    const mitigateProcesses = [];
                    mitigateProcesses.push(orgObj.value);
                    localStorage.setItem(this.mainRisk.mappedRisk, JSON.stringify(mitigateProcesses));
                    // this.$router.push({name:'processControlsMap',params:{riskId: this.mainRisk.mappedRisk,orgId:this.mainRisk.orgId}});

                    console.log('Hi 1');
                    this.$router.push({ name: 'systemsControlsMapWithId', params: { riskId: this.mainRisk.mappedRisk, type: 'organization', id: this.mainRisk.orgId }, query: { organization: true } });
                } else if (Object.keys(this.mainRisk).includes('tasks')) {
                    let conditionForMitigate = this.mainRisk.tasks.every((taskId: any) => {
                        let a = this.tasksData.find((task: any) => {
                            return task._id == taskId;
                        });
                        return a.statusCode == 10403 || a.statusCode == 10410;
                    });
                    console.log('conditionForMitigateconditionForMitigate', conditionForMitigate);
                    if (this.mainRisk.statusCode != 10402 && this.mainRisk.statusCode != 10406 && conditionForMitigate) {
                        this.thisScreen = 'Mitigate';
                        let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                        const mitigateProcesses = [];
                        mitigateProcesses.push(orgObj.value);
                        if (this.mainRisk.statusCode == 10405 || this.mainRisk.statusCode == 10403) {
                            toast.error(`Cannot Mitigate the Risk in Mitigate Status`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        } else {
                            localStorage.setItem(this.mainRisk.mappedRisk, JSON.stringify(mitigateProcesses));
                            console.log('Hi 2');
                            this.$router.push({ name: 'systemsControlsMapWithId', params: { riskId: this.mainRisk.mappedRisk, type: 'organization', id: this.mainRisk.orgId }, query: { organization: true } });
                        }
                    } else {
                        toast.error(`Tasks In pending for this Risk`, {
                            timeout: 5000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                } else {
                    this.thisScreen = 'Mitigate';
                    let orgObj = this.$route.params.orgId;
                    const mitigateProcesses = [];
                    mitigateProcesses.push(orgObj);
                    localStorage.setItem(this.mainRisk.mappedRisk, JSON.stringify(mitigateProcesses));
                    console.log('Hi 3');
                    this.$router.push({ name: 'systemsControlsMapWithId', params: { riskId: this.mainRisk.mappedRisk, type: 'organization', id: this.mainRisk.orgId }, query: { organization: true } });
                }
            } else if (route == '/avoid' && Object.keys(this.mainRisk).includes('riskOwner')) {
                this.thisScreen = 'Avoid';
                if (this.mainRisk.statusCode == 10403) {
                    toast.error(`Cannot Avoid the Risk in this Status`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    if (Object.keys(this.mainRisk).includes('tasks')) {
                        let conditionForAvoid = this.mainRisk.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForAvoid) {
                            let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                            this.applyPopup = true;
                            this.mappedOrgOptions.push(orgObj);
                            this.mainOrg.push(orgObj);
                            this.applyObj.organization = [...this.mainOrg];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                        this.applyPopup = true;
                        this.mappedOrgOptions.push(orgObj);
                        this.mainOrg.push(orgObj);
                        this.applyObj.organization = [...this.mainOrg];
                    }
                }
            } else if (route == '/transfer' && Object.keys(this.mainRisk).includes('riskOwner')) {
                this.thisScreen = 'Transfer';
                if (this.mainRisk.statusCode == 10403) {
                    toast.error(`Cannot Transfer the Risk in this Status`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    if (Object.keys(this.mainRisk).includes('tasks')) {
                        let conditionForTransfer = this.mainRisk.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForTransfer) {
                            let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                            this.applyPopup = true;
                            this.mappedOrgOptions.push(orgObj);
                            this.mainOrg.push(orgObj);
                            this.applyObj.organization = [...this.mainOrg];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                        this.applyPopup = true;
                        this.mappedOrgOptions.push(orgObj);
                        this.mainOrg.push(orgObj);
                        this.applyObj.organization = [...this.mainOrg];
                    }
                }
            } else if (route == '/reset' && Object.keys(this.mainRisk).includes('riskOwner')) {
                this.thisScreen = 'reset';
                if (this.mainRisk.statusCode == 10401) {
                    toast.error(`Cannot Reset the Risk in this Status`, {
                        timeout: 5000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    if (Object.keys(this.mainRisk).includes('tasks')) {
                        let conditionForReset = this.mainRisk.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForReset) {
                            let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                            this.applyPopup = true;
                            this.mappedOrgOptions.push(orgObj);
                            this.mainOrg.push(orgObj);
                            this.applyObj.organization = [...this.mainOrg];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                        this.applyPopup = true;
                        this.mappedOrgOptions.push(orgObj);
                        this.mainOrg.push(orgObj);
                        this.applyObj.organization = [...this.mainOrg];
                    }
                }
            } else if (route == '/deactive') {
                this.thisScreen = 'deactive';
                if (Object.keys(this.mainRisk).includes('tasks')) {
                    let conditionForDelete = this.mainRisk.tasks.every((taskId: any) => {
                        let a = this.tasksData.find((task: any) => {
                            return task._id == taskId;
                        });
                        return a.statusCode == 10403 || a.statusCode == 10410;
                    });
                    if (conditionForDelete) {
                        let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                        this.applyPopup = true;
                        this.mappedOrgOptions.push(orgObj);
                        this.mainOrg.push(orgObj);
                        this.applyObj.organization = [...this.mainOrg];
                    } else {
                        toast.error(`Tasks in Pending for this Risk`, {
                            timeout: 5000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                } else {
                    let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                    this.applyPopup = true;
                    this.mappedOrgOptions.push(orgObj);
                    this.mainOrg.push(orgObj);
                    this.applyObj.organization = [...this.mainOrg];
                }
            } else if (route == '/clone') {
                console.log('riskkkkkk', risk);
                let tierType: any;
                if (risk.tier == 1) {
                    tierType = 'organization';
                } else if (risk.tier == 2) {
                    tierType = 'process';
                } else if (risk.tier == 3) {
                    tierType = 'systems';
                }

                this.$router.push({ name: 'cloner', params: { mappedRisk: risk.mappedRisk, riskId: risk._id, type: tierType }, query: { organization: true } });
            }
        },
        async addOrUpdaterisk(riskData: any) {
            this.v$.orgRisksTab.$touch();

            if (!this.v$.orgRisksTab.$invalid && this.displayErrorMessageOfRisk == false && this.displayErrorMessageOfThreat == false && this.displayErrorMessageOfVulnerability == false) {
                this.clickOnButton = true
                let copyOfriskData: any = {...riskData}
                console.log('riskData', riskData);
                if (this.addingRisk == true) {
                    await this.addRiskList();
                } else {
                    let oldObj: any = {};
                    let newObj: any = {};
                    Object.keys(copyOfriskData).map((key: any) => {
                        if (copyOfriskData[key] != this.riskObjCopy[key] && key != 'updatedValues') {
                            oldObj[key] = this.riskObjCopy[key];
                            newObj[key] = copyOfriskData[key];
                        }
                    });

                    
                    oldObj.riskOwner = this.orgRisksTab.riskOwner.value;
                    newObj.riskOwner = this.orgRisksTab.riskOwner.value;

                    copyOfriskData.updatedValues = { oldRisk: [], newRisk: [] };
                     copyOfriskData.updatedValues.oldRisk.push(oldObj);
                    copyOfriskData.updatedValues.newRisk.push(newObj);

                    // console.log("old objj",oldObj)
                    // copyOfriskData.updatedValues = { oldRisk: [], newRisk: [] };
                   

                    let risk: any = [];

                    risk.push(copyOfriskData);
                    console.log('this is updated risk object', risk);
                    await this.updateRisk(risk);
                }
            } 
            // else {
            //     toast.error(`error`, {
            //         timeout: 1000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // }
        },
        async updateRisk(riskObj: any) {
            let copyOfriskObj:any = {...riskObj}
            try {
                
                let riskId = copyOfriskObj[0]._id;
                delete copyOfriskObj[0]._id;
                delete copyOfriskObj[0].overallImpact;
                delete copyOfriskObj[0].severity;


                copyOfriskObj[0].updatedAt = new Date();
                copyOfriskObj[0].updatedBy = this.userInfo._id;
                copyOfriskObj[0].riskOwner = copyOfriskObj[0].riskOwner.value;

                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/${riskId}/update?org==true`, copyOfriskObj[0]).then((result: any) => {
                    toast.info(`Updated succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                    this.v$.$reset();
                });
                this.openRiskPopup = false;
                this.showPriorityPopUp = false;
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
            await this.getRisksData();
        },
        async addRiskList() {
            const finalArray: Record<string, any> = [];
            let copyOforgRisksTabData:any = {...this.orgRisksTab}
            delete copyOforgRisksTabData.overallImpact;
            delete copyOforgRisksTabData.severity;

            
            finalArray.push(copyOforgRisksTabData);
            finalArray.map((obj: any) => {
                obj.statusCode = 10401;
                obj.createdAt = new Date();
                obj.createdBy = this.userInfo._id;
                obj.riskOwner = copyOforgRisksTabData.riskOwner.value;
                obj.org = [];
                let orgId: any = this.$route.params.orgId;
                obj.org.push(orgId);
            });      
            console.log("payload data",finalArray)      
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/create?org==true`, finalArray).then((result: any) => {
                    let riskId = result.data.insertedIds[0];
                    const payload: Record<string, any> = [];

                    finalArray[0].org.map((presentProcessId: any) => {
                        let newObj = { orgId: '', riskId: [`${riskId}`], statusCode: 10401 };
                        newObj.orgId = presentProcessId;
                        payload.push(newObj);
                    });
                    this.createRiskTask(payload);
                    this.openRiskPopup = false;
                });
            } catch (e) {
                toast.info('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
        },
        async createAssesmentTask() {
            try {
                let payload: any = [];
                this.checkedItems.map((id: any) => {
                    let riskItems = this.systemAssesmentList.filter((item: any) => item._id == id).map((item: any) => item._id);
                    console.log('riskItems', riskItems);
                    // let riskIds = riskItems.map((item: any) => item._id);
                    let finalOutput = {
                        orgId: this.$route.params.orgId,
                        riskId: riskItems,
                        statusCode: 10401,
                        createdAt: new Date(),
                        createdBy: this.userInfo._id,
                        updatedStatus: 10401,
                        tier: 1,
                        riskOwner: this.userInfo._id
                    };
                    payload.push(finalOutput);
                });
                console.log('payload', payload);

                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/create/risks`, payload).then((result: any) => {
                    this.checkedItems = [];
                    toast.info('created', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
                
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
            await this.getRisksData();
            await this.getRisksDiscoveryForOrg();
        },
        async createRiskTask(payload: any) {
            console.log('payload', payload);
            payload[0].tier = 1;
            payload[0].createdAt = new Date();
            payload[0].createdBy = this.userInfo._id;
            try {
                payload.createdAt = new Date();
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/create/risks`, payload).then((result: any) => {
                    toast.info('created', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
                this.openRiskPopup = false;
                this.v$.$reset();
                this.addingRisk = false;
                await this.getRisksData();
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async createTaskForAction(applyObj: any, actionCode: any) {
            this.clickOnButton = true
            console.log('applyObj', applyObj);
            let payload: any = [
                {
                    org: [this.$route.params.orgId],
                    mappedRisk: this.mainRisk.mappedRisk,
                    createdAt: new Date(),
                    createdBy: this.userInfo._id,
                    action: actionCode
                }
            ];
            payload[0].comments = applyObj.comments;
            await this.createWorkFlowTask(payload);

            Object.keys(this.applyObj).forEach((prop) => {
                Array.isArray(this.applyObj[prop]) ? (this.applyObj[prop] = []) : (this.applyObj[prop] = '');
            });
            
            await this.getRisksData();
            await this.getAllTasks();

            this.v$.information.$reset();
            this.applyPopup = false;
            this.applyObj.comments = '';
            this.mappedOrgOptions = [];
            this.mainOrg = [];

            this.mainProcess = [];
        },
        async createWorkFlowTask(payload: any) {
            try {
                
                payload[0].tier = 1;
                payload[0].createdAt = new Date();
                payload[0].createdBy = this.userInfo._id;
                await this.$http.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/task`, payload).then((result: any) => {
                    this.insertedIds = result.data;
                    console.log("this.thisScreen",this.thisScreen)
                    if(this.thisScreen == 'Acceptance'){
                        toast.info('Task Accepted', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    } else if(this.thisScreen == 'reset'){
                        toast.info('Task Reset', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    } else if(this.thisScreen == 'deactive'){
                        toast.info('Task Deleted', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    } else if(this.thisScreen == 'Avoid'){
                        toast.info('Task Avoided', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    } else if(this.thisScreen == 'Transfer'){
                        toast.info('Task Transferd', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    } else  {
                        toast.info('Task Created', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                });
                payload[0].insertedIds = this.insertedIds;
                await this.createActionForRisk(payload);
                this.applyPopup = false;
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
        },
        async getRisksDiscoveryForOrg() {
            try {
                await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/${this.$route.params.orgId}/assesment`).then((result: any) => {
                    let AssesmentRisks = result.data;
                    let filteredAssesmentArrayOfRisks: any = [];

                    filteredAssesmentArrayOfRisks = AssesmentRisks.filter((assObj: any) => {
                        let findMainObjInRiskList = this.risksDataJson.find((obj: any) => {
                            return obj.mappedRisk == assObj._id;
                        });
                        if (findMainObjInRiskList == undefined) {
                            return true;
                        } else if (findMainObjInRiskList != undefined && findMainObjInRiskList.statusCode == 10404) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    this.systemAssesmentList = filteredAssesmentArrayOfRisks;
                    this.selectAll = false;
                    this.systemAssesmentList.forEach((risk: any) => {
                        let averageImpact = Math.round(this.average([ risk.business, risk.operations, risk.legal, risk.financial]));
                        risk.overallImpact = averageImpact;
                        risk.severity = this.riskSeverity(risk.overallImpact * risk.likelihood);
                    });
                    this.paginationObj = [...this.systemAssesmentList];

                    this.dummyList = result.data;
                });
            } catch (e) {
                console.log('error');
            }
        },
        async createActionForRisk(payload: any) {
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/risk/accept`, payload).then((result: any) => {
                    console.log('Done');
                });
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async getAllTasks() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/tasks/getall`).then((result: any) => {
                this.tasksData = result.data;
            });
        },
        async getAllTenants() {
            let entityId = 20001;
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                let orgId = this.$route.params.id;
                this.organizationList = result.data.filter((object: any) => object.id == orgId);
                console.log('organizationList', this.organizationList);
                this.businesss = this.organizationList[0];
                this.objToBeModified = this.organizationList[0];
                this.dummyOrgObject = { ...this.organizationList[0] };
            });
        },
        async getSingleEntity() {
            //this.$store.dispatch('updateDotsLoading', true);
            if (this.$route.params.orgId) {
                await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntity/${this.$route.params.orgId}/get`).then((res: any) => {
                    this.organizationList = res.data;
                    if (this.$route.params.orgId == this.organizationList[0]._id) {
                        console.log('dsfgsfg');
                        this.businesss = this.organizationList[0];
                    }
                    this.objToBeModified = this.organizationList[0];
                    this.dummyOrgObject = { ...this.businesss };
                });
            }
            // this.$store.dispatch('updateDotsLoading', false);
        },
        async getAllUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.users = res.data;
                this.orgUsersList = res.data;
                this.orgUsersList = this.orgUsersList.filter((user: any) => {
                    return user.isActive == true;
                });
            });
            for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                }
        },
        async getRootRisksList() {
            this.rootRisksList = [];
            this.riskCategoryList = [];
            this.threatCategoryList = [];
            this.threatSourceList = [];
            const result = await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/risks/getall`);

            result.data.forEach((risk: any) => {
                !this.riskCategoryList.includes(risk.riskCategory) ? this.riskCategoryList.push(risk.riskCategory) : '';

                !this.threatCategoryList.includes(risk.threatCategory) ? this.threatCategoryList.push(risk.threatCategory) : '';

                !this.threatSourceList.includes(risk.threatSource) ? this.threatSourceList.push(risk.threatSource) : '';

                if (risk.operations) {
                    let averageImpact = Math.round(this.average([ risk.business, risk.operations, risk.legal, risk.financial]));
                    risk.overallImpact = averageImpact;
                    risk.severity = this.riskSeverity(risk.overallImpact * risk.likelihood);
                    this.rootRisksList.push(risk);
                }
            });
        },
        async getRisksData() {
            //this.$store.dispatch('updateDotsLoading', true);
            this.rowData = [];
            this.risksDataJson = [];
            let id = this.$route.params.orgId;
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/bussiness/${id}/get/risks`).then((result: any) => {
                this.rowData = result.data.filter((obj: any) => {
                    return obj.statusCode != 10404;
                });   
                this.rowData.forEach((risk: any) => {
                    this.openList = risk;
                    let condition1 = Object.keys(risk).includes('residual') && risk['residual'].length > 0;
                    let averageImpact;
                    let impact;
                    let severity;

                    if (condition1 == true) {
                        let lengthOfArray = risk['residual'].length;
                        let indexObj = risk['residual'][lengthOfArray - 1];

                        if (indexObj.isActive == true) {
                            averageImpact = this.average([ indexObj['business'], indexObj['operations'], indexObj['legal'], indexObj['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * indexObj['likelihood']);

                            risk.business = indexObj['business'];
                            risk.operations = indexObj['operations'];
                            risk.legal = indexObj['legal'];
                            risk.financial = indexObj['financial'];
                            risk.likelihood = indexObj['likelihood'];
                        } else {
                            averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * this.openList['likelihood']);
                        }
                    } else if (condition1 == false) {
                        averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                        impact = Math.round(averageImpact);
                        severity = this.riskSeverity(impact * this.openList['likelihood']);
                    }
                    risk.overallImpact = impact;
                    risk.severity = severity;

                    if (!Object.keys(risk).includes('updatedAt') && risk.updatedAt != '') {
                        risk.updatedAt = '';
                    }

                    risk.entityId = 20001;
                    risk.riskOwner = this.optionsUserArray.find((value: any)=>{return value.value == risk.riskOwner});
                    risk.riskOwnerName = risk.riskOwner != undefined ? risk.riskOwner.label : 'N/A'
                    this.risksDataJson.push(risk);
                });
            });
            // this.$store.dispatch('updateDotsLoading', false);
            this.riskObjCopy = { ...this.risksDataJson };
            console.log('riskObjCopy', this.riskObjCopy);
            console.log('risksDataJson', this.risksDataJson);
            // });
        },
        async updateProcess(obj: any, entityId: any) {
            this.v$.businesss.$touch();
            if (!this.v$.businesss.$invalid) {
                let comingObjectId = obj._id;

                delete obj._id;
                obj.updatedAt = new Date();

                await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntity/${this.$route.params.orgId}/update`, this.businesss).then(async (result: any) => {
                    toast.info('Updated', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
                await this.getSingleEntity();
            } else {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async addInformationToObject() {
            this.v$.information.$touch();
            if (!this.v$.information.$invalid) {
                let obj = { ...this.information };
                obj.org = []
                obj.org.push(this.$route.params.orgId);
                this.addInformationObj.push(obj);
                this.infoList = [];
                console.log("infodataaa",this.mappedInfoForProcess);
                

                Object.keys(this.information).forEach((prop) => {
                    Array.isArray(this.information[prop]) ? (this.information[prop] = []) : (this.information[prop] = '');
                });
                this.addCIAInfo = true;
                this.v$.$reset();
            } 
            // else {
            //     toast.error(`error`, {
            //         timeout: 1000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // }
        },
        async saveInformation() {                        
            this.showInfoPopup = false;
            let orgId = this.$route.params.orgId;
            let payload: any = [];
            
            this.addInformationObj.map((obj: any) => {                
                payload.push(obj.id);
            });

            await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/bussiness/${orgId}/add/information`, payload).then(async (result: any) => {
                this.addInformationObj = [];
                let infoTabObj = { displayName: 'Information', name: 'Information', id: 1 };
                await this.toggleTabs(infoTabObj);

                toast.info('Info Added', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            });
        },
        async updateMapping(payload: any) {
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/mapping/create`, payload).then(async (result: any) => {
                    console.log('updated');
                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async getRootInformationList() {
            //this.$store.dispatch('updateDotsLoading', true);
            this.rootInformationsList = [];

            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall?org=true`).then(async (result: any) => {
                result.data.forEach((info: any) => {
                    this.rootInformationsList.push(info);
                });
            });
            this.rootInformationsList.forEach((info: any) => {
                !this.infoCategoryList.includes(info.informationCategory) ? this.infoCategoryList.push(info.informationCategory) : '';
                // !this.infoList.includes(info.information) ? this.infoList.push(info.information) : '';                        


                let obj = { value: info._id, label: info.information };
                this.savedInformationSets.push(obj);
            });

            let present = this.rootInformationsList.filter((obj: any) => {
                if (obj.org) {
                    return obj.org.includes(this.$route.params.orgId)
                }
                else{
                    return false;
                }
            });
            this.mappedInfoForProcess = present;
            // this.$store.dispatch('updateDotsLoading', false);
        },
        showPriorityPopUpAction(data: any) {
            this.mainRisk = { ...data };
            this.riskObjCopy = { ...data };

            this.showPriorityPopUp = true;

            this.riskPriorityShow.risk = data.risk;
            this.riskPriorityShow.priority = data.priority;
            this.riskPriorityShow.justification = '';

            this.priorityDummy.risk = data.risk;
            this.priorityDummy.priority = data.priority;
            this.priorityDummy.justification = '';
        },

        closePriorityPopup() {
            this.resetRiskPriority();
            this.showPriorityPopUp = false;
        },

        cancelPriorityBtn() {
            this.riskPriorityShow.risk = this.priorityDummy.risk;
            this.riskPriorityShow.priority = this.priorityDummy.priority;
            this.riskPriorityShow.justification = '';
            this.priorityDummy.justification = '';
            this.v$.$reset();
        },

        async addOrUpdatePriority() {
            console.log('add clicked');
            this.v$.$touch();
            if (!this.v$.riskPriorityShow.$invalid) {
                this.clickOnButton = true
                this.mainRisk.priority = this.riskPriorityShow.priority;
                this.mainRisk.priorityComments = this.riskPriorityShow.justification;

                let riskData = { ...this.mainRisk };

                let oldObj: any = {};
                let newObj: any = {};
                Object.keys(riskData).map((key: any) => {
                    if (riskData[key] != this.riskObjCopy[key] && key != 'updatedValues' && key != 'priorityComments') {
                        oldObj[key] = this.riskObjCopy[key];
                        newObj[key] = riskData[key];
                    }
                });
                riskData.updatedValues = { oldRisk: [], newRisk: [] };
                riskData.updatedValues.oldRisk.push(oldObj);
                riskData.updatedValues.newRisk.push(newObj);

                let risk: any = [];

                risk.push(riskData);
                //updatingRisk
                await this.updateRisk(risk);

                this.v$.$reset();
                this.mainRisk = {};
            } else {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },

        resetRiskPriority() {
            this.riskPriorityShow.risk = '';
            this.riskPriorityShow.priority = null;
            this.riskPriorityShow.justification = '';
            // this.riskPriorityShow._id=null;
            this.priorityDummy.risk = '';
            this.priorityDummy.priority = null;
            this.priorityDummy.justification = '';
            // this.priorityDummy._id=null;
            this.v$.$reset();
        },
        updateButtonStates() {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    this.canScrollLeft = tabContainer.scrollLeft > 0;
                    this.canScrollRight = tabContainer.scrollLeft + tabContainer.clientWidth < tabContainer.scrollWidth;
                } else {
                    this.canScrollLeft = this.currentGroupIndex > 0;
                    this.canScrollRight = this.currentGroupIndex < this.totalGroups - 1;
                }
            }
        },
        handleResize() {
            this.isSmallScreen = window.innerWidth <= 768;
            if (this.isSmallScreen) {
                this.calculateTabsPerGroup();
                if (this.currentGroupIndex > this.totalGroups - 1) {
                    this.scrollTabs('left');
                }
            } else {
                this.tabsPerGroup = this.calculateTabsForLargeScreen();
                this.updateVisibleTabs();
            }
            this.updateButtonStates();
        },
        calculateTabsPerGroup() {
            const tabContainerWidth = this.$refs.tabContainer.clientWidth;

            let sumWidth = 0;
            let count = 0;
            const tabs = this.carouselHead;

            for (let i = 0; i < tabs.length; i++) {
                const tabWidth = this.getTabWidth(tabs[i]);

                if (sumWidth + tabWidth > tabContainerWidth) {
                    break;
                }

                sumWidth += tabWidth;
                count++;
            }
            this.tabsPerGroup = count;
            this.updateVisibleTabs();
        },
        calculateTabsForLargeScreen() {
            const tabContainerWidth = this.$refs.tabContainer.clientWidth;

            const tabs = this.carouselHead;
            let sumWidth = 0;
            let count = 0;

            for (let i = 0; i < tabs.length; i++) {
                const tabWidth = this.getTabWidth(tabs[i]);

                if (sumWidth + tabWidth > tabContainerWidth) {
                    break;
                }

                sumWidth += tabWidth;
                count++;
            }
            return count;
        },
        getTabWidth(tab: any) {
            // Simulate tab width calculation; replace with your actual logic
            const dummyDiv = document.createElement('div');
            dummyDiv.innerHTML = tab.displayName;
            dummyDiv.style.display = 'inline-block';
            dummyDiv.style.visibility = 'hidden';
            document.body.appendChild(dummyDiv);
            const width = dummyDiv.clientWidth;
            document.body.removeChild(dummyDiv);
            return width + 24; // Adjust padding or margin as needed
        },
        async updateVisibleTabs() {
            if (!this.isSmallScreen) {
                this.visibleTabs = [...this.carouselHead];
            } else {
                const startIdx = this.currentGroupIndex * this.tabsPerGroup;
                const endIdx = startIdx + this.tabsPerGroup;
                this.visibleTabs = this.carouselHead.slice(startIdx, endIdx);
                if (this.visibleTabs) {
                    let savedTabKey: any = sessionStorage.getItem('selectedTab');
                    savedTabKey = parseInt(savedTabKey)
                    if (savedTabKey) {
                        const savedTab = this.visibleTabs.find((tab: any) => tab.id == savedTabKey);
                        console.log("savedTab", savedTab)
                        if (savedTab) {
                            await this.toggleTabs(savedTab);
                        }else{
                            this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
                            this.selectedIndex = 0;
                        }
                    }
                    else{
                        this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
                        this.selectedIndex = 0;
                        await this.toggleTabs(this.selectedTabObj);
                    }
                }
                this.totalGroups = Math.ceil(this.carouselHead.length / this.tabsPerGroup);
            }
            this.updateButtonStates();
        },
        scrollTabs(direction: any) {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    const scrollAmount = this.calculateScrollAmount(direction);
                    if (direction === 'left') {
                        tabContainer.scrollLeft -= scrollAmount;
                    } else if (direction === 'right') {
                        tabContainer.scrollLeft += scrollAmount;
                    }
                    this.updateButtonStates();
                } else {
                    if (direction === 'left' && this.currentGroupIndex > 0) {
                        this.currentGroupIndex--;
                    } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                        this.currentGroupIndex++;
                    }
                    this.updateVisibleTabs();
                    this.updateButtonStates(); // Update button states after changing currentGroupIndex
                }
            }
        },
        calculateScrollAmount(direction: any) {
            const tabContainer = this.$refs.tabContainer;
            const visibleTabs = this.visibleTabs;
            let sumWidth = 0;
            let scrollAmount = 0;

            if (direction === 'left') {
                for (let i = visibleTabs.length - 1; i >= 0; i--) {
                    const tabWidth = this.getTabWidth(visibleTabs[i]);
                    if (sumWidth + tabWidth > tabContainer.clientWidth) {
                        break;
                    }
                    sumWidth += tabWidth;
                    scrollAmount += tabWidth;
                }
            } else if (direction === 'right') {
                for (let i = 0; i < visibleTabs.length; i++) {
                    const tabWidth = this.getTabWidth(visibleTabs[i]);
                    if (sumWidth + tabWidth > tabContainer.clientWidth) {
                        break;
                    }
                    sumWidth += tabWidth;
                    scrollAmount += tabWidth;
                }
            }
            return scrollAmount;
        },
        scrollGroups(direction: any) {
            if (direction === 'left' && this.currentGroupIndex > 0) {
                this.currentGroupIndex--;
            } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                this.currentGroupIndex++;
            }
            this.updateVisibleTabs();
            this.updateButtonStates(); // Update button states after changing currentGroupIndex
        },
        handleScroll() {
            this.updateButtonStates();
        }
    },
    async created() {
        await this.getSingleEntity();
        let savedTabKey: any = sessionStorage.getItem('selectedTab');
        console.log("savedTabKey",savedTabKey)
        savedTabKey = parseInt(savedTabKey)
		if (savedTabKey) {
		    const savedTab = this.carouselHead.find((tab: any) => tab.id == savedTabKey);
            console.log("savedTab", savedTab)
            if (savedTab) {
                await this.toggleTabs(savedTab);
            }else{
                this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
                this.selectedIndex = 0;
            }
		}
		else{
			this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
			this.selectedIndex = 0;
            await this.toggleTabs(this.selectedTabObj);
		}
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        sessionStorage.removeItem('selectedTab')
    }
});
</script>

