<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="flex justify-between items-center h-8">
            <div>
                <h1>{{ businessProcess.businessProcessName }}</h1>
            </div>
            <div class="flex items-center">
                <button class="btnprimary mr-3 flex items-center" @click="addRiskForProcess" v-if="selectedTabIndex == 0">Add</button>
                <button class="btnprimary mr-3 flex items-center" @click="addInformationForProcess" v-if="selectedTabIndex == 1">Add</button>
                <button class="mr-3 flex items-center" @click="createAssesmentTask" :class="checkedRisks.length > 0 ? 'btnprimary' : 'btndisabled pointer-events-none'" v-if="selectedTabIndex == 3">Create</button>
                <button
                    class="flex"
                    v-if="selectedTabIndex != 2"
                    :class="(selectedTabIndex == 0 && risksList.length != 0) || (selectedTabIndex == 1 && mappedInfoForProcess.length != 0) || (selectedTabIndex == 3 && sortedData.length >= 0) ? 'filterButton' : 'btndisabled pointer-events-none'"
                    @click="filterOpen"
                >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <div class="mt-5 px-5 pb-5 bg-white w-full border border-lightgrey pt-5 relative rounded">
            <!-- <div class="mx-2.5">
                <div class="w-6/12 pt-5 flex flex-row borderBottom justify-between overflow-hidden cursor-pointer">
                    <div class="pb-1 w-12/12 adjust mr-1" v-for="(tab, tabIndex) in carouselHead" :key="tabIndex" @click="toggleTabs(tab.id)">
                        <div :class="{ 'anime-left': !sideButton, 'anime-right': sideButton }">
                            <div ref="actualIndex" class="tab-name">{{ tab.displayName }}</div>
                            <div :class="{ progress: selectedTabIndex == tab.id }" class="h-1 p-0.75"></div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="progressbarSI h-1 mx-2.5"></div> -->
            <div class="group-buttons" v-if="isSmallScreen">
                <!-- Group navigation buttons for smaller screens -->
                <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === 0 || currentGroupIndex === 0) && isSmallScreen }">
                    <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                </div>
                <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === carouselHead.length - 1 || currentGroupIndex >= totalGroups - 1) && isSmallScreen }">
                    <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                </div>
            </div>
            <!-- <div v-else>
                Scroll buttons for larger screens
                <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': !canScrollLeft && !isSmallScreen }">
                    <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                </div>
                <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': !canScrollRight && !isSmallScreen }">
                    <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                </div>
            </div> -->
                        <div>
            <div>
                <div class="flex flex-row borderBottom cursor-pointer tab-container" ref="tabContainer" @scroll="handleScroll">
                    <div class="pb-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold text-primary':'text-sm text-disabledgrey'" v-for="(tab, index) in visibleTabs" :key="index" @click="toggleTabs(tab)">
                        <div>
                            <div ref="actualIndex" class="cursor-pointer">{{ tab.displayName }}</div>
                            <div :class="{ progress: selectedTabIndex === tab.id }" class="h-1 p-0.75 relative rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="progressbarB rounded h-1"></div>
            </div>
            <div class="w-full">
                <div v-if="selectedTabIndex == 0">
                    <newTable :tableRowData="risksList" :headersData="riskHeaders" :openPopup="true" :loadData="dataLoading"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>

                <div class="businesssPopup" v-if="selectedTabIndex == 1">
                    <newTable :tableRowData="mappedInfoForProcess" :headersData="informationHeaders" :openPopup="true" :loadData="dataLoading"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>

                <div v-if="selectedTabIndex == 2">
                    <!-- <fieldset :disabled="disabled"> -->
                        <div class="statuscontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Business Process Name</label>
                                        <div class="text-sm">{{businessProcess.businessProcessName  || 'N/A'}}</div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Name</label>
                                        <div class="text-sm">
                                            {{businessProcess.name != undefined ? `${businessProcess.name.label}`:'N/A'}}
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Department</label>
                                        <div class="text-sm">
                                            {{businessProcess.department != undefined ? `${businessProcess.department.label}`:'N/A'}}
                                        
                                    </div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Job Title</label>
                                        <div class="text-sm">{{businessProcess.role || "N/A"}}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Description</label>
                                         <div class="text-sm">{{businessProcess.description || 'N/A'}}</div>
                                    </div>
                                </div>

                                <div class="row flex flex-row w-full upperspacing">
                                    
                                    <div class="w-full">
                                        <label class="controllabel">Criticality</label>
                                        <div class="text-sm">{{businessProcess.criticality.key  || 'N/A'}}</div>
                                        <!-- <select
                                            class="inputboxstyling"
                                            v-model="businessProcess.criticality"
                                            :class="{
                                                'is-invalid': v$.businessProcess.criticality.$error
                                            }"
                                            disabled
                                        >
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option v-for="obj in dbProcessCriticality" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select> -->
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                <div v-if="Object.keys(businessProcess).includes('systems')" class="w-full">
                                    <label class="controllabel">System(s)</label>
                                        <div v-if="businessProcess.systems.length > 0" class="text-sm flex mt-2 flex-wrap">
                                            <div v-for="system in businessProcess.systems" :key="system.value" class="pb-2">
                                                <div class="w-auto h-6 bg-primary mr-3 px-2 rounded-sm flex items-center text-white text-sm">
                                                    {{ systems.find(item => item.value === system.value)?.label}}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="text-sm">
                                            {{'N/A'}}
                                        </div>

                                        <!-- <multiselect
                                            v-model="businessProcess.systems"
                                            :options="systems"
                                            :multiple="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            label="label"
                                            open-direction="bottom"
                                            :hide-selected="true"
                                            track-by="value"
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Please Select One"
                                            deselectLabel=""
                                            :showNoOptions="false"
                                            selectLabel=""
                                            disabled
                                            :class="{
                                                borderRed: v$.businessProcess.systems.$error
                                            }"
                                        ></multiselect> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="buttonposition flex justify-center items-center mt-2 mx-0">
                                <button class="mr-1.5" :class="JSON.stringify(dummyPresentProcess) == JSON.stringify(businessProcess) ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="onCancelBtnProcess()">Cancel</button>
                                <button :class="JSON.stringify(dummyPresentProcess) == JSON.stringify(businessProcess) ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="UpdateProcess(businessProcess)">Update</button>
                            </div> -->
                        </div>
                    <!-- </fieldset> -->
                </div>

                <div v-if="selectedTabIndex == 3">
                    <div v-if="discovery == true">
                        <div class="flex-col justify-center items-center">
                            <div class="loader mr-2"></div>
                        </div>
                        <p class="text-center font-semibold">Discovering Risks</p>
                    </div>
                    <div class="tableCornerRadius" v-if="discovery == false">
                        <div class="table-container">
                            <table>
                                <thead>
                                    <th class="header cursor-pointer" style="width: 5%;">
                                        <div class="flex items-center w-4">
                                            <input type="checkbox" v-model="selectAllOption" class="checkbox cursor-pointer" :disabled="riskAssesmentList.length == 0" @change="selectAllOptions($event)" />
                                        </div>
                                    </th>
                                    <th scope="col" v-for="column in discoverHeaders" @click="sort(column)" :key="column" :class="getColumnHeadWidth(column)" class="bg-white capitalize">
                                        <div class="flex items-center" :class="getFilterWidth(column)">
                                            <div>{{ column }}</div>
                                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                                        </div>
                                    </th>
                                    <tr v-if="showFilters">
                                        <td></td>
                                        <td v-for="(name, index) in discoverHeaders" class="bg-white" :key="index">
                                            <select v-if="name == 'severity'"  class="widthForSelect" :class="assesmentFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="assesmentFilters[name]">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in dbSeverity" :key="pair" :value="pair.value">{{ pair.key }}</option>
                                            </select>
                                            <select v-else-if="name == 'likelihood'" class="widthForSelect" :class="assesmentFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="assesmentFilters[name]">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in dbLikelihood" :key="pair" :value="pair.value">{{ pair.key }}</option>
                                            </select>
                                            <input v-else type="text" class="searchForInputBoxStyling filter-font-size" placeholder="Search" v-model="assesmentFilters[name]" />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody v-if="sortedData.length == 0">
                                    <tr class="cursor-pointer rowHeight">
                                        <td class="relative h-10" :colspan="discoverHeaders.length">
                                            <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="nonEmptyTable" v-else>
                                    <tr v-for="(riskObj, indexx) in sortedData" :key="riskObj" class="cursor-pointer rowHeight">
                                        <td>
                                            <input class="checkbox" type="checkbox" v-model="checkedRisks" @change="changeInRiskAssesmentTd" :value="riskObj._id" />
                                        </td>
                                        <td v-for="column in discoverHeaders" @mouseout="doFalse()" :key="column" class="cursor-pointer relative">
                                            <div v-if="column == 'severity'">
                                                <div class="relative flex w-28 regular pl-5">
                                                    <div class="flex items-center justify-center rounded-full w-5 h-5" @mousemove="toolTip(column, $event, indexx, riskObj, 'circleButton')" :class="colorPropBg('border', riskObj.severity)">
                                                        <div class="severityCircle rounded-full" :class="colorPropBg('circle', riskObj.severity)"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else-if="column == 'likelihood'">
                                                <div class="flex items-center justify-center rounded h-5 w-24" :class="colorRiskBg(riskObj[column])">
                                                    <div :class="colorRiskText(riskObj[column])">
                                                        {{
                                                            dbLikelihood.find((l) => {
                                                                return l.value == riskObj[column];
                                                            }).key
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else @mousemove="toolTip(column, $event, indexx, riskObj, 'text')">
                                                <div class="table-ellipsis">
                                                    <div @mousemove="toolTip(column, $event, indexx, riskObj, 'text')">
                                                        {{ riskObj[column] }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="sortedData.length > 0">
                            <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData"></pagination>
                        </div>
                    </div>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
            </div>
        </div>

        <popup v-if="openRiskPopup" :popUp="openRiskPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Risk Information</h1>
                    <button class="float-right text-3xl" @click="onCloseBtnRisk()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <div class="popup-business">
                <div>
                    <div>
                        <div>
                            <div class="statuscontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Risk</label>
                                            <textarea
                                                class="inputboxstyling"
                                                placeholder="Risk"
                                                @input ="restrictCharacters('Risk')"
                                                v-model.trim="riskThreatData.risk"
                                                :class="{
                                                    'is-invalid': (v$.riskThreatData.risk.$error||displayErrorMessageOfRisk)
                                                }"
                                            />
                                            <div v-if="v$.riskThreatData.risk.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.risk.required.$invalid">Required</div>
                                            </div>
                                            <div v-else-if="displayErrorMessageOfRisk == true">
                                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-3/6">
                                            <div>
                                                <label class="controllabel">Risk Category</label>
                                                <multiselect
                                                    v-model="riskThreatData.riskCategory"
                                                    :options="riskCategoryList"
                                                    :multiple="false"
                                                    :close-on-select="true"
                                                    :clear-on-select="true"
                                                    open-direction="bottom"
                                                    :hide-selected="true"
                                                    :searchable="true"
                                                    :hideArrow="false"
                                                    placeholder="Risk Category"
                                                    deselectLabel=""
                                                    :showNoOptions="false"
                                                    selectLabel=""
                                                    class="custom-multiselect userSelect"
                                                    :class="{
                                                        'is-invalid borderRed': v$.riskThreatData.riskCategory.$error,
                                                        'has-options': riskCategoryList && riskCategoryList.length > 0,
                                                        'no-options':  !riskCategoryList.length,
                                                    }"
                                                >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No Categories Found</span>
                                                </template>
                                                </multiselect>
                                                <div v-if="v$.riskThreatData.riskCategory.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.riskCategory.required.$invalid">Required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-3/6 leftspacing">
                                            <label class="controllabel">Risk Owner</label>
                                            <multiselect
                                                v-model="riskThreatData.riskOwner"
                                                :options="userOptions"
                                                :showNoOptions="false"
                                                @search-change="asyncFind"
                                                :limit-text="limitText"
                                                :multiple="false"
                                                :hide-selected="true"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"    
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="Risk Owner"
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'is-invalid borderRed': v$.riskThreatData.riskOwner.$error,
                                                    'has-options': userOptions && userOptions.length > 0,
                                                    'no-options': !userOptions.length && searchValue !== '',
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No Members Found</span>
                                                </template>
                                            </multiselect>
                                            <div v-if="v$.riskThreatData.riskOwner.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.riskOwner.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Threat</label>
                                            <textarea
                                                class="inputboxstyling"
                                                placeholder="Threat"
                                                @input ="restrictCharacters('Threat')"
                                                v-model.trim="riskThreatData.threatEvent"
                                                :class="{
                                                    'is-invalid': (v$.riskThreatData.threatEvent.$error||displayErrorMessageOfThreat)
                                                }"
                                            />

                                            <div v-if="v$.riskThreatData.threatEvent.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.threatEvent.required.$invalid">Required</div>
                                            </div>
                                            <div v-else-if="displayErrorMessageOfThreat == true">
                                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-3/6">
                                            <label class="controllabel">Threat Source</label>
                                            <multiselect
                                                v-model="riskThreatData.threatSource"
                                                :options="threatSourceList"
                                                :multiple="false"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                :hide-selected="true"
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="Threat Source"
                                                deselectLabel=""
                                                selectLabel=""
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'has-options': threatSourceList && threatSourceList.length > 0,
                                                    'no-options':  !threatSourceList.length,
                                                }"
                                            >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No sources Found</span>
                                            </template>
                                            </multiselect>
                                        </div>
                                        <div class="w-3/6 leftspacing">
                                            <label class="controllabel">Threat Category</label>
                                            <multiselect
                                                v-model="riskThreatData.threatCategory"
                                                :options="threatCategoryList"
                                                :multiple="false"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                :hide-selected="true"
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="Threat Category"
                                                deselectLabel=""
                                                :showNoOptions="false"
                                                selectLabel=""
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'has-options': threatCategoryList && threatCategoryList.length > 0,
                                                    'no-options':  !threatCategoryList.length,
                                                }"
                                            >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Categories Found</span>
                                            </template>
                                            </multiselect>
                                        </div>
                                    </div>

                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Vulnerability</label>
                                            <textarea
                                                class="inputboxstyling"
                                                placeholder="Vulnerability"
                                                @input ="restrictCharacters('Vulnerability')"
                                                v-model.trim="riskThreatData.vulnerability"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.vulnerability.$error||displayErrorMessageOfVulnerability
                                                }"
                                            />

                                            <div v-if="v$.riskThreatData.vulnerability.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.vulnerability.required.$invalid">Required</div>
                                            </div>
                                            <div v-else-if="displayErrorMessageOfVulnerability == true">
                                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                            </div>
                                        </div>
                                    </div>

                                    <h1 class="upperspacing">Impact Analysis</h1>
                                    <div class="border-b mt-1 border-solid border-lightgrey"></div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <label class="controllabel">Strategic</label>
                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.business"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.business.$error,'inputboxstylingForSelectAtPopup': riskThreatData.business === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.business !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="option in dbBussiness" :key="option" :value="option.value">
                                                    {{ option.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.business.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.business.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                        <div class="w-6/12 leftspacing">
                                            <label class="controllabel">Financial</label>

                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.financial"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.financial.$error,'inputboxstylingForSelectAtPopup': riskThreatData.financial === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.financial !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="option in dbFinancial" :key="option" :value="option.value">
                                                    {{ option.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.financial.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.financial.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <label class="controllabel">Operations</label>
                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.operations"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.operations.$error,'inputboxstylingForSelectAtPopup': riskThreatData.operations === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.operations !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="option in dbOperations" :key="option" :value="option.value">
                                                    {{ option.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.operations.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.operations.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                        <div class="w-6/12 leftspacing">
                                            <label class="controllabel">Compliance</label>
                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.legal"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.legal.$error,'inputboxstylingForSelectAtPopup': riskThreatData.legal === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.legal !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="option in dbLegal" :key="option" :value="option.value">
                                                    {{ option.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.legal.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.legal.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>

                                    <h1 class="upperspacing">Risk Analysis</h1>
                                    <div class="border-b pt-1 border-solid border-lightgrey"></div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <label class="controllabel">Likelihood</label>

                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.likelihood"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.likelihood.$error,'inputboxstylingForSelectAtPopup': riskThreatData.likelihood === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.likelihood !== ''
                                                }"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="option in dbLikelihood" :key="option" :value="option.value">
                                                    {{ option.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.likelihood.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.likelihood.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                        <div class="w-6/12 leftspacing">
                                            <label class="controllabel">Overall Impact</label>
                                            <select class="inputboxstyling" disabled v-model="riskThreatData.overallImpact">
                                                <option value="" selected hidden>Please select one</option>
                                                <option v-for="i in dbOverAllImpact" :value="i.value" :key="i">
                                                    {{ i.key }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="flex mt-2 justify-center">
                                        <button :disabled="clickOnButton" class="btnprimary mr-2 gap-2" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelRiskData()">Cancel</button>
                                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" v-if="addingRisk" @click="addOrUpdaterisk(riskThreatData)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Add</button>
                                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" v-else @click="addOrUpdaterisk(riskThreatData)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </popup>

        <popup v-if="showInfoPopup" :popUp="showInfoPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Information</h1>
                    <button class="float-right text-3xl" @click="onCloseInformation()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset :disabled="information._id">
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full" v-if="information.information != '' && !addInfo">
                                        <label class="controllabel">Information Category</label>
                                        <div class="text-sm">
                                            {{information.informationCategory}}
                                        </div>
                                    </div>
                                    <div class="w-full" v-else>
                                        <label class="controllabel">Information Category</label>
                                        <multiselect
                                            v-model="information.informationCategory"
                                            :options="infoCategoryList"
                                            :multiple="false"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            :hide-selected="true"
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Information Category"
                                            @select="changeInfoCategory(information.informationCategory)"
                                            deselectLabel=""
                                            :showNoOptions="false"
                                            selectLabel=""
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed': v$.information.informationCategory.$error,
                                                'has-options': infoCategoryList && infoCategoryList.length > 0,
                                                'no-options':  !infoCategoryList.length,
                                            }"
                                        >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Categories Found</span>
                                            </template>
                                        </multiselect>
                                        <div v-if="v$.information.informationCategory.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.informationCategory.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full upperspacing" v-if="information.information != '' && !addInfo">
                                    <label class="controllabel">Information</label>
                                    <div class="text-sm">
                                        {{information.information}}
                                    </div>
                                </div>
                                <div class="w-full upperspacing" v-else>
                                    <label class="controllabel">Information</label>
                                    <multiselect
                                        v-model="information.information"
                                        :options="infoList"
                                        :disabled="!addInfo || information.informationCategory == ''"
                                        @select="changeInformation(information.information)"
                                        @deselect="changeInformation(information.information)"
                                        :searchable="true" 
                                        :close-on-select="true" 
                                        :show-labels="false"
                                        :hide-selected="true"
                                        placeholder="Information"
                                        open-direction="bottom"
                                        class="custom-multiselect userSelect"
                                        :class="{
                                            'is-invalid borderRed': v$.information.information.$error,
                                            'has-options': infoList && infoList.length > 0,
                                            'no-options':  !infoList.length,
                                        }"
                                    >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Information Found</span>
                                        </template>
                                    </multiselect>
                                    <div v-if="v$.information.information.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.information.information.required.$invalid">Required</div>
                                    </div>
                                </div>

                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12" v-if="information.information != '' && !addInfo">
                                        <label class="controllabel">Information Owner</label>
                                        <div class="text-sm">
                                            {{information.informationOwner.label}}
                                        </div>
                                    </div>
                                    <div class="w-6/12" v-else>
                                        <label class="controllabel">Information Owner</label>
                                        <multiselect
                                            v-model="information.informationOwner"
                                            :options="optionsUserArray"
                                            :multiple="false"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            label="label"
                                            open-direction="bottom"
                                            :hide-selected="true"
                                            track-by="value"
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Information Owner"
                                            deselectLabel=""
                                            :showNoOptions="false"
                                            selectLabel=""
                                            disabled
                                            class="custom-multiselect userSelect"
                                        >
                                        </multiselect>
                                        <!-- :class="{
                                                'is-invalid borderRed': v$.information.informationOwner.$error
                                            }"
                                        <div v-if="v$.information.informationOwner.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.informationOwner.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                    <div class="w-6/12" :class="!information._id ? 'leftspacing': ''" v-if="information.confidentiality != '' || !addInfo || !addCIAInfo">
                                        <label class="controllabel">Confidentiality</label>
                                        <div v-for="(dataValue, indexNum) in dbConfidentiality" :key="indexNum">
                                            <div v-if="information.confidentiality == dataValue.value">
                                                <div class="text-sm">{{dataValue.key}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing" v-else>
                                        <label class="controllabel">Confidentiality</label>
                                        <select
                                            v-on:change="getMaxIdValue()"
                                            class="disabledinputboxstyling disabledselect inputboxstyling"
                                            disabled
                                            v-model="information.confidentiality"
                                            
                                        >
                                            <option value="" selected disabled hidden>Confidentiality</option>
                                            <option v-for="obj in dbConfidentiality" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>
                                        
                                        <!-- :class="{
                                                'is-invalid': v$.information.confidentiality.$error
                                            }"
                                        <div v-if="v$.information.confidentiality.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.confidentiality.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12" v-if="information.integrity != '' || !addInfo || !addCIAInfo">
                                        <label class="controllabel">Integrity</label>
                                        <div v-for="(dataValue, indexNum) in dbIntegrity" :key="indexNum">
                                            <div v-if="information.integrity == dataValue.value">
                                                <div class="text-sm">{{dataValue.key}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-6/12" v-else>
                                        <label class="controllabel">Integrity</label>
                                        <select
                                            v-on:change="getMaxIdValue()"
                                            class="disabledinputboxstyling disabledselect inputboxstyling"
                                            disabled
                                            v-model="information.integrity"
                                            
                                        >
                                            <option value="" selected disabled hidden>Integrity</option>
                                            <option v-for="obj in dbIntegrity" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>

                                        <!-- :class="{
                                                'is-invalid': v$.information.integrity.$error
                                            }"

                                        <div v-if="v$.information.integrity.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.integrity.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                    <div class="w-6/12" :class="information._id ? '' : 'leftspacing'" v-if="information.availability != '' || !addInfo || !addCIAInfo">
                                        <label class="controllabel">Availability</label>
                                        <div v-for="(dataValue, indexNum) in dbAvailability" :key="indexNum">
                                            <div v-if="information.availability == dataValue.value">
                                                <div class="text-sm">{{dataValue.key}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing" v-else>
                                        <label class="controllabel">Availability</label>
                                        <select
                                            v-on:change="getMaxIdValue()"
                                            class="disabledinputboxstyling disabledselect inputboxstyling"
                                            disabled
                                            v-model="information.availability"
                                            
                                        >
                                            <option value="" selected disabled hidden>Availability</option>
                                            <option v-for="obj in dbAvailability" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>

                                        <!-- :class="{
                                                'is-invalid': v$.information.availability.$error
                                            }"

                                        <div v-if="v$.information.availability.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.availability.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12" v-if="information.classification != '' || !addInfo">
                                        <label class="controllabel">Classification</label>
                                        <div v-for="(dataValue, indexNum) in dbInformationClassification" :key="indexNum">
                                            <div v-if="information.classification == dataValue.value">
                                                <div class="text-sm">{{dataValue.key}}</div>
                                                <!-- <input type="text" class="disabledinputboxstyling" disabled v-model="dataValue.key" placeholder="Classification" /> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="w-6/12">
                                        <label class="controllabel">Classification</label>

                                        <select
                                            class="disabledinputboxstyling disabledselect inputboxstyling"
                                            v-model="information.classification"
                                            disabled
                                            :class="{
                                                'is-invalid': v$.information.classification.$error
                                            }"
                                        >
                                            <option value="" selected disabled hidden>Classification</option>

                                            <option v-for="option in dbInformationClassification" :key="option" :value="option.value">
                                                {{ option.key }}
                                            </option>
                                        </select>

                                        <div v-if="v$.information.classification.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.classification.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12" v-if="information.securityCategory != '' && !addInfo">
                                        <div v-for="(dataValue, indexNum) in dbSecurityCategory" :key="indexNum">
                                            <div v-if="information.securityCategory == dataValue.value">
                                                <label class="controllabel">Security Category</label>
                                                <div class="text-sm">{{dataValue.key}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="addInfo" class="buttonposition flex justify-center items-center my-4 mx-0">
                                <button class="btnprimary mr-1.5" :class="disableStatusInfo ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelBtnInfo()">Cancel</button>
                                <button @click="addInformationToObject()" :class="disableStatusInfo ? 'btnprimary' : 'btndisabled pointer-events-none'">Add</button>
                            </div>

                            <div class="border-b border-solid border-lightgrey" v-if="addInformationObj.length > 0"></div>
                            <div class="flex flex-row justify-between" v-for="(info, index) in addInformationObj" :key="info">
                                <div class="statuscontainer p-2 w-11/12 my-2 border-solid border border-lightgrey rounded" v-if="addInformationObj.length > 0">
                                    <div class="text-sm font-light text-gray bg-white">
                                        <ul>
                                            <li class="text-sm flex flex-row items-center justify-between">
                                                {{ info.information }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex justify-center items-center">
                                    <button class="float-right" @click="onInfoDelete(index)">
                                        <img src="@/assets/deleteIcon.svg" class="h-5 w-5 cursor-pointer" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="addInformationObj.length > 0" class="buttonposition flex justify-center items-center my-4 mx-0">
                            <button class="btnprimary mr-1.5" @click="onResetBtnInfo(information)">Reset</button>
                            <button class="btnprimary" @click="saveInformation()">Save</button>
                        </div>
                    </div>
                </div>
            </fieldset>
        </popup>

        <popup v-if="showPriorityPopUp">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Risk Prioritize</h1>

                    <button class="float-right text-3xl" @click="closePriorityPopup()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>

            <div class="popup-priority">
                <div class="w-full popupbodycontainer">
                    <div class="w-full upperspacing">
                        <label class="controllabel font-normal text-sm mb-1" for="vulnerability">Risk Name</label>
                        <input
                            v-model.trim="riskPriorityShow.risk"
                            disabled
                            placeholder="Name of the Risk"
                            id="id"
                            class="disabledinputboxstyling"
                            :class="{
                                'is-invalid': v$.riskPriorityShow.risk.$error
                            }"
                        />
                        <div class="text-red-500" v-if="v$.riskPriorityShow.risk.$error">
                            <div v-if="v$.riskPriorityShow.risk.required.$invalid" class="error-text text-xs">Risk name is required</div>
                        </div>
                    </div>

                    <div class="w-1/2 upperspacing">
                        <label class="controllabel">Priority</label>
                        <select class="inputboxstyling" v-model="riskPriorityShow.priority">
                            <option v-for="(priority, index) in dbPriority" :key="index" :value="priority.value">{{ priority.key }}</option>
                        </select>
                    </div>

                    <div class="text-red-500" v-if="v$.riskPriorityShow.priority.$error">
                        <div v-if="v$.riskPriorityShow.priority.required.$invalid" class="error-text text-xs">Please change priority</div>
                    </div>
                    <div v-if="this.riskPriorityShow.priority !== this.priorityDummy.priority">
                        <div class="row flex flex-row w-full">
                            <div class="w-full upperspacing">
                                <label for="Risk" class="controllabel">Justification</label>
                                <textarea
                                    class="inputboxstyling"
                                    placeholder="Justification"
                                    v-model.trim="riskPriorityShow.justification"
                                    :class="{
                                        'is-invalid': v$.riskPriorityShow.justification.$error
                                    }"
                                />
                            </div>
                        </div>
                        <div class="text-red-500" v-if="v$.riskPriorityShow.justification.$error">
                            <div v-if="v$.riskPriorityShow.justification.required.$invalid" class="error-text text-xs">Justification is required</div>
                        </div>
                    </div>

                    <div class="buttonposition flex justify-center items-center my-4 mx-0">
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" :class="JSON.stringify(riskPriorityShow) !== JSON.stringify(priorityDummy) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="cancelPriorityBtn()">Cancel</button>
                        <button :disabled="clickOnButton" @click="addOrUpdatePriority()" :class="JSON.stringify(riskPriorityShow) !== JSON.stringify(priorityDummy) ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
                    </div>
                </div>
            </div>
        </popup>

        <popup class="applyPopup" v-if="applyPopup" :popUp="applyPopup">
            <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-end items-start flex">
                <div class="relative w-full max-w-xl">
                    <!--content-->
                    <div class="border-0 rounded relative flex flex-col w-full bg-white risksAcceptance h-screen">
                        <!--header-->
                        <div class="flex items-center justify-between p-3 rounded popupheadingcontainer">
                            <h1 v-if="thisScreen == 'Acceptance'">Risk Accept</h1>
                            <h1 v-if="thisScreen == 'reset'">Risk Reset</h1>
                            <h1 v-if="thisScreen == 'deactive'">Risk Delete</h1>
                            <h1 v-if="thisScreen == 'Avoid'">Risk Avoid</h1>
                            <h1 v-if="thisScreen == 'Transfer'">Risk Transfer</h1>
                            <button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="onClosebtnAction()">
                                <!-- <XIcon class="h-5 w-5" /> -->
                                <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                            </button>
                        </div>

                        <div class="border-b border-solid border-lightgrey ml-4 mr-4"></div>

                        <div>
                            <div class="impactcontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-col">
                                        <div class="upperspacing">
                                            <label class="controllabel">Process</label>
                                            <div class="text-xs flex flex-wrap">
                                                <div v-for="process in mainProcess" :key="process.value" class="mr-2 mb-2 px-2 py-1 bg-primary text-white rounded">
                                                    {{ mappedProcessOptions.find(option => option.value === process)?.label || "Unknown" }}
                                                </div>
                                                <div v-if="mainProcess.length === 0">No processes selected</div>
                                            </div>

                                            <!-- <multiselect
                                                v-model="mainProcess"
                                                :options="mappedProcessOptions"
                                                :multiple="true"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                label="label"
                                                track-by="value"
                                                :searchable="true"
                                                :hideArrow="false"
                                                deselectLabel=""
                                                selectLabel=""
                                                class="custom-multiselect"
                                                :disabled="true"
                                                placeholder="Name"
                                            ></multiselect> -->
                                             <!-- <multiselect v-model="mainProcess" mode="tags" :close-on-select="false" :options="mappedProcessOptions" :caret="false" :showOptions="false" :canClear="false" :disabled="true" placeholder="Systems"> 
                                                 <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                    <div
                                                        class="multiselect-tag"
                                                        :class="{
                                                            'is-disabled': disabled
                                                        }"
                                                    >
                                                        {{ option.label }}
                                                        <span v-if="!disabled" class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
                                                            <span class="multiselect-tag-remove-icon"></span>
                                                        </span>
                                                    </div>
                                                </template> 
                                            </multiselect> -->
                                        </div>
                                        <div class="border-b border-solid border-lightgrey upperspacing"></div>
                                        <div class="w-full upperspacing">
                                            <label v-if="thisScreen == 'Acceptance'" class="controllabel">Reason for Accepting Risk / Justification</label>
                                            <label v-if="thisScreen == 'reset'" class="controllabel">Reason for Reset Risk / Justification</label>
                                            <label v-if="thisScreen == 'deactive'" class="controllabel">Reason for Delete Risk / Justification</label>
                                            <label v-if="thisScreen == 'Avoid'" class="controllabel">Reason for Avoid Risk</label>
                                            <label v-if="thisScreen == 'Transfer'" class="controllabel">Reason for Transfer Risk</label>
                                            <textarea type="text" class="inputboxstyling h-20 max-h-48" v-model.trim="applyObj.comments" placeholder="Reason / Justification"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--footer-->
                        <div class="buttonposition flex justify-center items-center">
                            <button :disabled="clickOnButton" @click="cancelAceeptResetBtn()" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" class="mr-3">Cancel</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'Acceptance'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="createTaskForAction(applyObj, 10403)">Apply</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'Avoid'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="createTaskForAction(applyObj, 10419)">Apply</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'Transfer'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'">Apply</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'reset'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary" @click="createTaskForAction(applyObj, 10407)">Apply</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'deactive'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary" @click="createTaskForAction(applyObj, 10404)">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </popup>
    </div>
</template>

<style scoped>
.tab-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 60px !important; 
}
.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #152a43;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}
.midHead {
    width: 160px !important;
}
.widthForSelect{
    width : 100px !important;
}
.redBorder {
	border: 1px solid red !important;
	border-radius: 5px !important;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.anime-left {
    animation-name: moveLeft;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
@keyframes moveLeft {
    0% {
        opacity: 1;
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(0);
    }
}
.anime-right {
    animation-name: moveRight;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
@keyframes moveRight {
    0% {
        opacity: 1;
        transform: translateX(-100vw);
    }
    100% {
        transform: translateX(0);
    }
}
/* .progressbarSI {
    border: 2px solid #e9e9e9;
    background: #e9e9e9;
    position: relative;
    top: -8px;
    border-radius: 2px;
}
.progress {
    background: #152a43;
    z-index: 1;
} */
.borderRed {
    border: 1px solid red !important;
}
.widthForLikelihood {
    width: 15%;
}
.widthForSeverity {
    width: 5%;
}
.veryLowOpacity{
    background-color: rgba(0, 176, 80, 0.2);
}
.LowOpacity{
    background-color: rgba(146, 208, 80, 0.2);
}
.ModerateOpacity{
    background-color: rgba(255, 153, 0, 0.2);
}
.HighOpacity{
    background-color: rgba(255, 0, 0, 0.2);
}
.veryHighOpacity{
    background-color: rgba(192, 0, 0, 0.2);
}
</style>
<script lang="ts">
import _ from 'lodash';
import popup from '@/components/popup.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers, requiredIf, email } from '@vuelidate/validators';
const numeric = helpers.regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
import { emitter, toast } from '@/main';
import store from '@/store';
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import breadCrumb from '@/components/breadCrumb.vue';
import multiselect from "vue-multiselect";
import newTable from '@/components/newTable.vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            selectedTabObj: { displayName: 'Risks', name: 'Risk', id: 0 },
            carouselHead: [
                { displayName: 'Risks', name: 'Risk', id: 0 },
                { displayName: 'Information', name: 'Information', id: 1 },
                { displayName: 'Properties', name: 'Properties', id: 2 },
                { displayName: 'Risk-Discovery', name: 'Discovery', id: 3 }
            ],
            discovery: false,
            selectedTabIndex: null,
            permissions: [],
            showFilters: false,
            risksList: [],
            informationsList: [],
            informationHeaders: ['entityId', '_id', 'informationCategory', 'information', 'securityCategory'],
            riskHeaders: ['severity', 'risk', 'riskOwnerName', 'createdAt', 'updatedAt', 'statusCode', 'priority', 'History', 'actionButton'],
            openRiskPopup: false,
            sideButton: false,
            clickOnButton: false,
            riskThreatData: {
                risk: '',
                threatCategory: '',
                threatSource: '',
                threatEvent: '',
                vulnerability: '',
                business: '',
                operations: '',
                legal: '',
                financial: '',
                likelihood: '',
                riskCategory: '',
                overallImpact: '',
                severity: '',
                riskOwner: ''
            },
            ownerTypeValues: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Secondary'
                }
            ],
            information: {
                id: '',
                information: '',
                informationCategory: '',
                informationOwner: '',
                confidentiality: '',
                integrity: '',
                availability: '',
                securityCategory: '',
                processes: [],
                classification: ''
            },
            risksSeverityValue: [
                {
                    id: 1,
                    value: 'Very Low'
                },
                {
                    id: 2,
                    value: 'Low'
                },
                {
                    id: 3,
                    value: 'Moderate'
                },
                {
                    id: 4,
                    value: 'High'
                },
                {
                    id: 5,
                    value: 'Very High'
                }
            ],
            applyObj: {
                processes: [],
                comments: ''
            },
            addingRisk: false,
            tenants: [],
            tenantsSelect: [],
            orgUsersList: [],
            usersList: [],
            optionsUserArray: [],
            rootRisksList: [],
            riskCategoryList: [],
            threatSourceList: [],
            userOptions:[],
            searchValue: '',
            threatCategoryList: [],
            showInfoPopup: false,
            rootInformationsList: [],
            infoCategoryList: [],
            infoList: [],
            addInformationObj: [],
            businessProcess: {
                criticality: '',
                businessProcessName: '',
                description: '',
                ownerType: '',
                name: '',
                department: '',
                role: '',
                emailAddress: '',
                phoneNumber: '',
                systems: [],
                informations: []
            },
            businessProcessList: [],
            presentBusinessProcess: {},
            savedInformationSets: [],
            systems: [],
            departmentList: [],
            dummyPresentProcess: {},
            rowData: [],
            riskObjCopy: {},
            mainRisk: {},
            thisScreen: '',
            mappedProcessOptions: [],
            mainProcess: [],
            applyPopup: false,
            insertedIds: [],
            routesArray: [],
            navIndexNums: [],
            mappedInfoForProcess: [],
            addInfo: false,
            tasksData: [],
            riskAssesmentList: [],
            discoverHeaders: ['severity', 'risk', 'riskCategory', 'likelihood'],
            checkedRisks: [],
            selectAllOption: false,

            pop: false,
            styling: { left: '', bottom: '' },
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            addCIAInfo: true,
            className: '',
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',

            pageSize: 20,
            currentPage: 1,
            dynamicPagination: '',
            assesmentFilters: {
                severity: '',
                likelihood: '',
                overallImpact: ''
            },
            dummyAssesmentList: [],
            showPriorityPopUp: false,
            riskPriorityShow: {},
            priorityDummy: {},
            visibleTabs: [], // Tabs visible on screen
            currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
            totalGroups: 0, // Total number of groups for small screens

            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,
			displayErrorMessageOfRisk : false,
			displayErrorMessageOfThreat : false,
			displayErrorMessageOfVulnerability : false,
        };
    },
    components: {
        newTable,
        popup,
        multiselect,
        breadCrumb,
        toolTip,
        pagination
    },
    validations() {
        return {
            riskThreatData: {
                risk: { required },
                threatEvent: { required },
                vulnerability: { required },
                business: { required },
                operations: { required },
                legal: { required },
                financial: { required },
                likelihood: { required },
                riskCategory: { required },
                riskOwner: { required }
            },
            information: {
                informationCategory: { required },
                information: { required },
                informationOwner: { required },
                confidentiality: { required },
                integrity: { required },
                availability: { required },
                securityCategory: { required },
                classification: { required }
            },
            businessProcess: {
                criticality: { required },
                businessProcessName: { required },
                systems: { required },
                name: { required },
                department: { required },
                role: { required },
                description: { required }
            },
            riskPriorityShow: {
                risk: { required },
                priority: {
                    required: requiredIf(() => {
                        return this.priorityDummy.priority === this.riskPriorityShow.priority;
                    })
                },
                justification: { required }
            }
        };
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo',
            dbSeverity: 'dbSeverity',
            dbLikelihood: 'dbLikelihood',
            dbBussiness: 'dbBussiness',
            dbOrganization: 'dbOrganization',
            dbOperations: 'dbOperations',
            dbLegal: 'dbLegal',
            dbFinancial: 'dbFinancial',
            dbTechnical: 'dbTechnical',
            dbProcessCriticality: 'dbProcessCriticality',
            dbAvailability: 'dbAvailability',
            dbConfidentiality: 'dbConfidentiality',
            dbIntegrity: 'dbIntegrity',
            dbInformationClassification: 'dbInformationClassification',
            dbSecurityCategory: 'dbSecurityCategory',
            dbPriority: 'dbPriority',
            dbOverAllImpact: 'dbOverAllImpact'
        }),

        disableStatus: function (): any {
            let disableStatusCondition;
            if (!this.addingRisk) {
                if (JSON.stringify(this.riskThreatData) == JSON.stringify(this.riskObjCopy)) {
                    disableStatusCondition = false;
                } else {
                    disableStatusCondition = true;
                }
            } else {
                let valueArray: any = [];
                Object.values(this.riskThreatData).forEach((value: any) => {
                    valueArray.push(value);
                });
                for (let value of valueArray) {
                    if (value != '') {
                        disableStatusCondition = true;
                    }
                }
            }
            return disableStatusCondition;
        },
        disableStatusInfo: function (): any {
            let output = false;
            let val: any = [];
            Object.values(this.information).forEach((value: any) => {
                val.push(value);
            });
            for (let n of val) {
                if (n != '') {
                    output = true;
                }
            }
            return output;
        },
        sortedData: function (): any {
            return [...this.riskAssesmentList]
                .filter((row: any) => {
                    for (const key in this.assesmentFilters) {
                        const inputValue = this.assesmentFilters[key];
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        }
    },
    methods: {
        asyncFind(query:any){
            this.searchValue = query
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        async calculateOverallImpact(){
            let a1 = this.riskThreatData.business == '' ? 0 : this.riskThreatData.business;
            let a2 = this.riskThreatData.operations == '' ? 0 : this.riskThreatData.operations;
            let a3 = this.riskThreatData.legal == '' ? 0 : this.riskThreatData.legal;
            let a4 = this.riskThreatData.financial == '' ? 0 : this.riskThreatData.financial;

            let arr = [a1, a2, a3, a4]

            let avg =  arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
            this.riskThreatData.overallImpact = Math.max(Math.round(avg), 1);
        },
        doFalse() {
            emitter.emit('deActiviteToolTip')
        },
        paginationUpdations(currentPage: any, pageSize: any) {
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        sort: function (s: any) {
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        selectAllOptions(e: any) {
            this.checkedRisks = [];
            const checked = e.target.checked;
            if (checked) {
                for (let obj of this.riskAssesmentList) {
                    this.checkedRisks.push(obj._id);
                }
            } else {
                this.checkedItems = [];
            }
        },
        changeInRiskAssesmentTd() {
            if (this.checkedRisks.length === this.riskAssesmentList.length) {
                this.selectAllOption = true;
            } else {
                this.selectAllOption = false;
            }
        },
        getColumnHeadWidth(column:any){
            // 'severity', 'risk', 'riskCategory', 'likelihood'
            if (column === 'severity') {
                return 'widthForSeverity';
            } else if (column === 'risk') {
                return 'w-1/4';
            } else if (column === 'riskCategory') {
                return 'w-1/4';
            } else if (column === 'likelihood') {
                return 'widthForLikelihood';
            } else {
                return '';
            }
        },
        getFilterWidth(column:any){
            // 'severity', 'risk', 'riskCategory', 'likelihood'
            if (column === 'severity') {
                return 'w-28';
            } else if (column === 'risk') {
                return 'w-44';
            } else if (column === 'riskCategory') {
                return 'w-32';
            } else if (column === 'likelihood') {
                return 'pl-3 w-28';
            } else {
                return '';
            }
        },
        colorRiskText(value: any) {
            if (value == 1) {
                return ['text-lightgreen font-semibold'];
            } else if (value == 2) {
                return ['text-green font-semibold'];
            } else if (value == 3) {
                return ['text-yellow font-semibold'];
            } else if (value == 4) {
                return ['text-error font-semibold'];
            } else if (value == 5) {
                return ['text-darkred font-semibold'];
            }
        },
        colorRiskBg(value: any) {
            if (value == 1) {
                return ['veryLowOpacity border-solid border border-lightgreen'];
            } else if (value == 2) {
                return ['LowOpacity border-solid border border-green'];
            } else if (value == 3) {
                return ['ModerateOpacity border-solid border border-yellow'];
            } else if (value == 4) {
                return ['HighOpacity border-solid border border-error'];
            } else if (value == 5) {
                return ['veryHighOpacity border-solid border border-darkred'];
            }
        },
        colorPropBg(format: any, scaleScore: any) {
            if (format == 'circle') {
                if (scaleScore == 5) {
                    return ['bg-darkred'];
                } else if (scaleScore == 4) {
                    return ['bg-error'];
                } else if (scaleScore == 3) {
                    return ['bg-yellow'];
                } else if (scaleScore == 2) {
                    return ['bg-green'];
                } else if (scaleScore == 1) {
                    return ['bg-lightgreen'];
                }
            } else if (format == 'border') {
                if (scaleScore == 5) {
                    return ['border-darkred border-2'];
                } else if (scaleScore == 4) {
                    return ['border-error border-2'];
                } else if (scaleScore == 3) {
                    return ['border-yellow border-2'];
                } else if (scaleScore == 2) {
                    return ['border-green border-2'];
                } else if (scaleScore == 1) {
                    return ['border-lightgreen border-2'];
                }
            }
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
            this.toolTipText = row[column];
            this.tipColumn = column;
            this.tipIndex = index;

            switch (status) {
                case 'text':
                    this.handleTextTooltip(e);
                    break;
                case 'circleButton':
                    let findVal = this.dbSeverity.find((obj:any)=>{return obj.value == row[column]})

                    if(findVal != undefined){
                        this.toolTipText = findVal.key
                    }else{
                        this.toolTipText = row[column]
                    }

                    let obj:any = {
                        tipText : this.toolTipText
                    }
                    emitter.emit('activiteToolTip',obj)
                }
        },
        handleTextTooltip(e: any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                let obj:any = {
                    tipText : this.toolTipText
                }

                emitter.emit('activiteToolTip',obj)
            }
        },
        isTextOverflowed(element: any) {
            return element.scrollHeight > element.clientHeight;
        },
        restrictCharacters(code: any){
            if(code === 'Threat'){
                if(this.riskThreatData.threatEvent.length > 300){
                this.displayErrorMessageOfThreat = true;
                }else if(this.riskThreatData.threatEvent.length < 300 || this.riskThreatData.threatEvent.length == 300){
                    this.displayErrorMessageOfThreat = false;
                }
            } else if(code === 'Risk'){
                if(this.riskThreatData.risk.length > 300){
                    this.displayErrorMessageOfRisk = true;
                }else if(this.riskThreatData.risk.length < 300 || this.riskThreatData.risk.length == 300){
                    this.displayErrorMessageOfRisk = false;
                }
            } else if(code === 'Vulnerability'){
                if(this.riskThreatData.vulnerability.length > 300){
                    this.displayErrorMessageOfVulnerability = true;
                }else if(this.riskThreatData.vulnerability.length < 300 || this.riskThreatData.vulnerability.length == 300){
                    this.displayErrorMessageOfVulnerability = false;
                }
            }
            
        },
        async toggleTabs(tabId: any) {
            this.dataLoading = true
            this.selectedTabIndex = tabId.id;
            sessionStorage.setItem('selectedTab', tabId.id);
            this.selectedTabObj = tabId
            if (tabId.id == 0) {
                //this.$store.dispatch('updateDotsLoading', true);
                await this.getOrgProcessRisks();
                emitter.emit('visible', false);
                this.showFilters = false;
                this.navIndexNums.index = 0;
                this.navIndexNums.name = 'Risks';
            } else if (tabId.id == 1) {
                //this.$store.dispatch('updateDotsLoading', true);
                this.navIndexNums.index = 1;
                this.navIndexNums.name = 'Information';
                await this.getRootInformationList();
            } else if (tabId.id == 2) {
                //this.$store.dispatch('updateDotsLoading', true);
                await this.getBusinessProcess();
                this.navIndexNums.index = 2;
                this.navIndexNums.name = 'Properties';
            } else if (tabId.id == 3) {
                this.discovery = true;
                console.log("liuyfuiyfi")
                await this.getOrgProcessRisks();
                await this.getRisksDiscoveryForProcess();
                setTimeout(() => {
                    this.discovery = false;
                }, 300);
                this.navIndexNums.index = 3;
                this.navIndexNums.name = 'Risk Discovery';
                emitter.emit('visible', false);
                this.showFilters = false;
            }
            this.dataLoading = false
        },
        async getRisksDiscoveryForProcess() {
            try {
                await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/process/${this.$route.params.processId}/assesment`).then((result: any) => {
                    //this.$store.dispatch('updateDotsLoading', true);
                    let AssesmentRisks = result.data;
                    this.riskAssesmentList = [];
                    this.dummyAssesmentList = [];
                    let filteredAssesmentArrayOfRisks: any = [];
                    this.checkedRisks = [];
                    this.selectAllOption = false;
                    console.log("liuyfuiyfi",AssesmentRisks)
                    //Assesment Logic
                    filteredAssesmentArrayOfRisks = AssesmentRisks.filter((assObj: any) => {
                        let findMainObjInRiskList = this.risksList.find((obj: any) => {
                            return obj.mappedRisk == assObj._id;
                        });
                        if (findMainObjInRiskList == undefined) {
                            return true;
                        } else if (findMainObjInRiskList != undefined && findMainObjInRiskList.statusCode == 10404) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    this.riskAssesmentList = filteredAssesmentArrayOfRisks;
                    this.dummyAssesmentList = [...this.riskAssesmentList];

                    //SeverityCalculation
                    this.riskAssesmentList.forEach((risk: any) => {
                        let averageImpact = Math.round(this.average([ risk.business, risk.operations, risk.legal, risk.financial]));
                        risk.overallImpact = averageImpact;
                        risk.severity = this.riskSeverity(risk.overallImpact * risk.likelihood);
                    });
                });
                // this.$store.dispatch('updateDotsLoading',false);
            } catch (e) {
                // this.$store.dispatch('updateDotsLoading', false);
                toast.error('Error While Assesment', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyAssesmentList = this.riskAssesmentList;
            } else {
                this.riskAssesmentList = this.dummyAssesmentList;
                for (const key in this.assesmentFilters) {
                    this.assesmentFilters[key] = '';
                }
            }
            emitter.emit('visible', this.showFilters);
        },
        changeInfoCategory(id: any) {
            this.infoList = [];
            this.information.confidentiality = '';
            this.information.availability = '';
            this.information.integrity = '';
            this.information.securityCategory = '';
            this.information.information = '';
            console.log("rootInformationsList",this.rootInformationsList)
            this.rootInformationsList.map((obj: any) => {
                if (obj.informationCategory == id) {
                    const isInTable = this.mappedInfoForProcess.some((row: any) => row.information === obj.information);
                    if (!isInTable) {
                        this.infoList.push(obj.information);
                        console.log("infoList after adding", this.infoList);
                    }
                    //     this.mappedInfoForProcess.map((filterinfo: any) => {  
                    //         console.log("infolist",this.infoList);
                    //         console.log("mappedInfoForProcess",this.mappedInfoForProcess);
                            
                    //         if(this.infoList.includes(filterinfo.information)){
                    //             const index = this.infoList.indexOf(filterinfo.information);
                    //                 if (index !== -1) {
                    //                 const removed = this.infoList.splice(index, 1);
                    //                 }
                    //         }                          
                    //     console.log("Updated infoList", this.infoList);
                    // });             
                       }
            });
        },
        changeInformation(name: any) {
            console.log('kjdku', name);
            if (name == '' || name == null || name == undefined) {
                this.addCIAInfo = true;
                this.information.id = '';
                this.information.confidentiality = '';
                this.information.availability = '';
                this.information.integrity = '';
                this.information.informationOwner = '';
            } else {
                let findObj: any = this.rootInformationsList.find((obj: any) => {
                    return obj.information == name;
                });
                this.addCIAInfo = false;
                this.information.id = findObj._id;
                this.information.confidentiality = findObj.confidentiality;
                this.information.availability = findObj.availability;
                this.information.integrity = findObj.integrity;
                this.information.classification = findObj.classification
                this.information.informationOwner = this.optionsUserArray.find((obj:any)=>{return obj.value == findObj.informationOwner});
                this.getMaxIdValue();
            }
        },
        onCancelRiskData() {
            this.v$.$reset();
            if (this.addingRisk) {
                Object.keys(this.riskThreatData).forEach((prop) => {
                    Array.isArray(this.riskThreatData[prop]) ? (this.riskThreatData[prop] = []) : (this.riskThreatData[prop] = '');
                });
                this.displayErrorMessageOfRisk = false
                this.displayErrorMessageOfThreat = false
                this.displayErrorMessageOfVulnerability = false
            } else {
                this.riskThreatData = { ...this.riskObjCopy };
                this.displayErrorMessageOfRisk = false
                this.displayErrorMessageOfThreat = false
                this.displayErrorMessageOfVulnerability = false
            }
        },
        addRiskForProcess() {
            this.v$.$reset();
            this.openRiskPopup = true;
            this.addingRisk = true;
            Object.keys(this.riskThreatData).forEach((prop) => {
                if (prop == '_id' || prop == 'createdAt' || prop == 'updatedAt') {
                    delete this.riskThreatData[prop];
                } else {
                    Array.isArray(this.riskThreatData[prop]) ? (this.riskThreatData[prop] = []) : (this.riskThreatData[prop] = '');
                }
            });
        },
        async addInformationToObject() {
            this.v$.information.$touch();
            if (!this.v$.information.$invalid) {
                let obj = { ...this.information };
                this.addInformationObj.push(obj);
                this.infoList = [];

                Object.keys(this.information).forEach((prop) => {
                    Array.isArray(this.information[prop]) ? (this.information[prop] = []) : (this.information[prop] = '');
                });
                this.v$.$reset();
                this.addCIAInfo = true;
            } 
            // else {
            //     toast.error(`error`, {
            //         timeout: 1000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // }
        },
        addInformationForProcess() {
            this.showInfoPopup = true;
            this.addInfo = true;
        },
        showInfoEditPopUp(obj: any) {            
            this.showInfoPopup = true;
            this.optionsUserArray.filter((optionsobj:any)=>{
                if(optionsobj.value == obj.informationOwner) {
                let a={label:optionsobj.label,value:optionsobj.value};
                obj.informationOwner = a;                
                }  
            })
            
            this.addInfo = false;
            this.information = { ...obj };
        },
        onCloseInformation() {
            if(this.addInformationObj && this.addInformationObj.length >0){
                this.addInformationObj = [];
            }
            this.showInfoPopup = false;
            this.v$.$reset();
            Object.keys(this.information).forEach((prop) => {
                if (prop == '_id' || prop == 'createdAt' || prop == 'updatedAt') {
                    delete this.information[prop];
                } else {
                    Array.isArray(this.information[prop]) ? (this.information[prop] = []) : (this.information[prop] = '');
                }
            });
        },
        cancelAceeptResetBtn() {
            this.applyObj.comments = '';
        },
        onCloseBtnRisk() {
            this.v$.$reset();
            this.openRiskPopup = false;
            this.addingRisk = false;
            Object.keys(this.riskThreatData).forEach((prop) => {
                if (prop == '_id' || prop == 'createdAt' || prop == 'updatedAt') {
                    delete this.riskThreatData[prop];
                } else {
                    Array.isArray(this.riskThreatData[prop]) ? (this.riskThreatData[prop] = []) : (this.riskThreatData[prop] = '');
                }
            });
            this.displayErrorMessageOfRisk = false
            this.displayErrorMessageOfThreat = false
            this.displayErrorMessageOfVulnerability = false
        },
        onClosebtnAction() {
            this.applyPopup = false;
            this.applyObj.comments = '';
            this.mappedProcessOptions = [];
            this.mainProcess = [];
        },
        async saveInformation() {
            this.showInfoPopup = false;
            let processId = this.$route.params.processId;

            let payload: any = [];
            this.addInformationObj.map((obj: any) => {
                payload.push(obj.id);
            });

            await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/process/${processId}/add/Information`, payload).then(async (result: any) => {
                this.addInformationObj = [];
                toast.info('Info Added', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            });
            let obj = { displayName: 'Information', name: 'Information', id: 1 };
            await this.toggleTabs(obj);
        },
        async updateMapping(payload: any) {
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/mapping/create`, payload).then(async (result: any) => {
                    console.log('updated');
                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        average(arr: any[]) {
            return arr.reduce((p, c) => p + c, 0) / arr.length;
        },
        getMaxIdValue() {
            this.information.securityCategory = Math.max(this.information.confidentiality, this.information.availability, this.information.integrity);
        },
        onInfoDelete(index: any): any {
            this.addInformationObj.splice(index, 1);
            toast.error('Inormation Deleted', {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });
        },
        onCancelBtnInfo(status: any, type: any) {
            this.v$.$reset();
            this.addCIAInfo = true;
            Object.keys(this.information).forEach((prop) => {
                Array.isArray(this.information[prop]) ? (this.information[prop] = []) : (this.information[prop] = '');
            });
        },
        onCancelBtnProcess() {
            this.businessProcess = { ...this.dummyPresentProcess };
            this.v$.$reset();
        },
        onResetBtnInfo() {
            this.addCIAInfo = true;
            this.addInformationObj = [];
        },
        getUserDetails(nameId: any) {
            let mainUser = this.usersList.find((user: any) => {
                return user._id == nameId;
            });
            // this.businessProcess.emailAddress = mainUser.email;
            this.businessProcess.role = mainUser.jobTitle;
        },
        async UpdateProcess(obj: any) {
            console.log("update info",obj);
            
            this.v$.businessProcess.$touch();
            if (!this.v$.businessProcess.$invalid) {
                let comingObjectId = obj._id;

                delete obj._id;
                obj.updatedAt = new Date();

                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/update/${comingObjectId}/${20006}`, obj).then(async (result: any) => {
                    toast.info('updated', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
                await this.getBusinessProcess();
            } else {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        showRiskEditPopUp(obj: any) {
            this.riskThreatData = { ...obj };
            this.riskObjCopy = { ...obj };
            this.addingRisk = false;
            this.openRiskPopup = true;
        },
        async addOrUpdaterisk(riskData: any) {
            this.v$.riskThreatData.$touch();

            if (!this.v$.riskThreatData.$invalid && this.displayErrorMessageOfRisk == false && this.displayErrorMessageOfThreat == false && this.displayErrorMessageOfVulnerability == false) {
                let copyOfriskData:any = {...riskData}
                this.clickOnButton = true;
                if (this.addingRisk == true) {
                    await this.addRiskList();
                } else {
                    let oldObj: any = {};
                    let newObj: any = {};
                    Object.keys(copyOfriskData).map((key: any) => {
                        if (copyOfriskData[key] != this.riskObjCopy[key] && key != 'updatedValues') {
                            oldObj[key] = this.riskObjCopy[key];
                            newObj[key] = copyOfriskData[key];
                        }
                    });

                    oldObj.riskOwner = this.riskThreatData.riskOwner.value;
                    newObj.riskOwner = this.riskThreatData.riskOwner.value;

                    copyOfriskData.updatedValues = { oldRisk: [], newRisk: [] };
                    copyOfriskData.updatedValues.oldRisk.push(oldObj);
                    copyOfriskData.updatedValues.newRisk.push(newObj);


                    let risk: any = [];

                    risk.push(copyOfriskData);
                    console.log('this is updated risk object', risk);
                    await this.updateRisk(risk);
                }
            } else {
                console.log("error")
                // toast.error(`error`, {
                //     timeout: 1000,
                //     closeOnClick: true,
                //     closeButton: 'button',
                //     icon: true
                // });
            }
        },
        async updateRisk(riskObj: any) {
            let copyOfriskObj:any = {...riskObj}
            try {
                let riskId = copyOfriskObj[0]._id;
                delete copyOfriskObj[0]._id;
                delete copyOfriskObj[0].overallImpact;
                delete copyOfriskObj[0].severity;
                copyOfriskObj[0].riskOwner = copyOfriskObj[0].riskOwner.value;
                copyOfriskObj[0].updatedAt = new Date();
                copyOfriskObj[0].updatedBy = this.userInfo._id;
                // copyOfriskObj[0].tier = 2;

                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/${riskId}/update?org==true`, copyOfriskObj[0]).then((result: any) => {
                    toast.info(`Updated succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                });
                this.openRiskPopup = false;
                this.showPriorityPopUp = false;
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
				this.clickOnButton = false;
            }
            await this.getOrgProcessRisks();
        },
        async addRiskList() {
            const finalArray: Record<string, any> = [];
            let copyOfriskThreatData:any = {...this.riskThreatData}
            delete copyOfriskThreatData.overallImpact;
            delete copyOfriskThreatData.severity;

            finalArray.push(copyOfriskThreatData);
            finalArray.map((obj: any) => {
                obj.statusCode = 10401;
                obj.createdAt = new Date();
                obj.createdBy = this.userInfo._id;
                obj.riskOwner = obj.riskOwner.value;
                obj.process = [];
                let processId: any = this.$route.params.processId;
                obj.process.push(processId);
            });
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/create?org==true`, finalArray).then((result: any) => {
                    let riskId = result.data.insertedIds[0];
                    const payload: Record<string, any> = [];

                    finalArray[0].process.map((presentProcessId: any) => {
                        let newObj = { processId: '', riskId: [`${riskId}`], statusCode: 10401 };
                        newObj.processId = presentProcessId;
                        payload.push(newObj);
                    });
                    this.createRiskTask(payload);
                });
                this.openRiskPopup = false;
            } catch (e) {
                toast.info('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
				this.clickOnButton = false;
			}
        },
        async createRiskTask(payload: any) {
            console.log('payload', payload);
            payload[0].tier = 2;
            payload[0].createdAt = new Date();
            payload[0].createdBy = this.userInfo._id;
            try {
                payload.createdAt = new Date();
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/create/risks`, payload).then((result: any) => {
                    toast.info('created', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
                this.openRiskPopup = false;
                this.v$.$reset();
                this.addingRisk = false;
                await this.getOrgProcessRisks();
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        changeRoute(risk: any, route: any) {
            this.mainRisk = risk;
            if (route == '/riskAcceptance' && Object.keys(this.mainRisk).includes('riskOwner')) {
                this.thisScreen = 'Acceptance';
                if (this.mainRisk.statusCode == 10403) {
                    toast.error(`Cannot Accept the Risk in this Status`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    if (Object.keys(this.mainRisk).includes('tasks')) {
                        let conditionForAccept = this.mainRisk.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForAccept) {
                            let processObj = { value: this.$route.params.processId, label: this.businessProcess.businessProcessName };
                            this.applyPopup = true;
                            this.mappedProcessOptions.push(processObj);
                            this.mainProcess.push(processObj.value);
                            this.applyObj.processes = [...this.mainProcess];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        let processObj = { value: this.$route.params.processId, label: this.businessProcess.businessProcessName };
                        this.applyPopup = true;
                        this.mappedProcessOptions.push(processObj);
                        this.mainProcess.push(processObj.value);
                        this.applyObj.processes = [...this.mainProcess];
                    }
                }
            } else if (route == '/mitigateRisk' && Object.keys(this.mainRisk).includes('riskOwner')) {
                if (this.mainRisk.statusCode == 10402 || this.mainRisk.statusCode == 10406) {
                    this.thisScreen = 'Mitigate';
                    let processObj = { value: this.$route.params.processId, label: this.businessProcess.businessProcessName };
                    const mitigateProcesses = [];
                    mitigateProcesses.push(processObj.value);
                    localStorage.setItem(this.mainRisk.mappedRisk, JSON.stringify(mitigateProcesses));
                    // this.$router.push({name:'processControlsMap',params:{riskId: this.mainRisk.mappedRisk,processId:this.mainRisk.processId}});

                    console.log('Hi 1');
                    this.$router.push({ name: 'systemsControlsMapWithId', params: { riskId: this.mainRisk.mappedRisk, type: 'process', id: this.mainRisk.processId }, query: { process: true } });
                } else if (Object.keys(this.mainRisk).includes('tasks')) {
                    let conditionForMitigate = this.mainRisk.tasks.every((taskId: any) => {
                        let a = this.tasksData.find((task: any) => {
                            return task._id == taskId;
                        });
                        return a.statusCode == 10403 || a.statusCode == 10410;
                    });
                    console.log('conditionForMitigateconditionForMitigate', conditionForMitigate);
                    if (this.mainRisk.statusCode != 10402 && this.mainRisk.statusCode != 10406 && conditionForMitigate) {
                        this.thisScreen = 'Mitigate';
                        let processObj = { value: this.$route.params.processId, label: this.businessProcess.businessProcessName };
                        const mitigateProcesses = [];
                        mitigateProcesses.push(processObj.value);
                        if (this.mainRisk.statusCode == 10405 || this.mainRisk.statusCode == 10403) {
                            toast.error(`Cannot Mitigate the Risk in Mitigate Status`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        } else {
                            localStorage.setItem(this.mainRisk.mappedRisk, JSON.stringify(mitigateProcesses));
                            console.log('Hi 2');
                            this.$router.push({ name: 'systemsControlsMapWithId', params: { riskId: this.mainRisk.mappedRisk, type: 'process', id: this.mainRisk.processId }, query: { process: true } });
                        }
                    } else {
                        toast.error(`Tasks In pending for this Risk`, {
                            timeout: 5000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                } else {
                    this.thisScreen = 'Mitigate';
                    let processObj = { value: this.$route.params.processId, label: this.businessProcess.businessProcessName };
                    const mitigateProcesses = [];
                    mitigateProcesses.push(processObj.value);
                    localStorage.setItem(this.mainRisk.mappedRisk, JSON.stringify(mitigateProcesses));
                    console.log('Hi 3');
                    this.$router.push({ name: 'systemsControlsMapWithId', params: { riskId: this.mainRisk.mappedRisk, type: 'process', id: this.mainRisk.processId }, query: { process: true } });
                }
            }  else if (route == '/avoid' && Object.keys(this.mainRisk).includes('riskOwner')) {
                this.thisScreen = 'Avoid';
                if (this.mainRisk.statusCode == 10403) {
                    toast.error(`Cannot Avoid the Risk in this Status`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    if (Object.keys(this.mainRisk).includes('tasks')) {
                        let conditionForAvoid = this.mainRisk.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForAvoid) {
                            let processObj = { value: this.$route.params.processId, label: this.businessProcess.businessProcessName };
                            this.applyPopup = true;
                            this.mappedProcessOptions.push(processObj);
                            this.mainProcess.push(processObj.value);
                            this.applyObj.processes = [...this.mainProcess];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        let processObj = { value: this.$route.params.processId, label: this.businessProcess.businessProcessName };
                        this.applyPopup = true;
                        this.mappedProcessOptions.push(processObj);
                        this.mainProcess.push(processObj.value);
                        this.applyObj.processes = [...this.mainProcess];
                    }
                }
            } else if (route == '/transfer' && Object.keys(this.mainRisk).includes('riskOwner')) {
                this.thisScreen = 'Transfer';
                if (this.mainRisk.statusCode == 10403) {
                    toast.error(`Cannot Transfer the Risk in this Status`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    if (Object.keys(this.mainRisk).includes('tasks')) {
                        let conditionForTransfer = this.mainRisk.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForTransfer) {
                            let processObj = { value: this.$route.params.processId, label: this.businessProcess.businessProcessName };
                            this.applyPopup = true;
                            this.mappedProcessOptions.push(processObj);
                            this.mainProcess.push(processObj.value);
                            this.applyObj.processes = [...this.mainProcess];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        let processObj = { value: this.$route.params.processId, label: this.businessProcess.businessProcessName };
                        this.applyPopup = true;
                        this.mappedProcessOptions.push(processObj);
                        this.mainProcess.push(processObj.value);
                        this.applyObj.processes = [...this.mainProcess];
                    }
                }
            } else if (route == '/reset' && Object.keys(this.mainRisk).includes('riskOwner')) {
                this.thisScreen = 'reset';
                if (this.mainRisk.statusCode == 10401) {
                    toast.error(`Cannot Reset the Risk in this Status`, {
                        timeout: 5000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    if (Object.keys(this.mainRisk).includes('tasks')) {
                        let conditionForReset = this.mainRisk.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForReset) {
                            let processObj = { value: this.$route.params.processId, label: this.businessProcess.businessProcessName };
                            this.applyPopup = true;
                            this.mappedProcessOptions.push(processObj);
                            this.mainProcess.push(processObj.value);
                            this.applyObj.processes = [...this.mainProcess];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        let processObj = { value: this.$route.params.processId, label: this.businessProcess.businessProcessName };
                        this.applyPopup = true;
                        this.mappedProcessOptions.push(processObj);
                        this.mainProcess.push(processObj.value);
                        this.applyObj.processes = [...this.mainProcess];
                    }
                }
            } else if (route == '/deactive') {
                this.thisScreen = 'deactive';
                if (Object.keys(this.mainRisk).includes('tasks')) {
                    let conditionForDelete = this.mainRisk.tasks.every((taskId: any) => {
                        let a = this.tasksData.find((task: any) => {
                            return task._id == taskId;
                        });
                        return a.statusCode == 10403 || a.statusCode == 10410;
                    });
                    if (conditionForDelete) {
                        let processObj = { value: this.$route.params.processId, label: this.businessProcess.businessProcessName };
                        this.applyPopup = true;
                        this.mappedProcessOptions.push(processObj);
                        this.mainProcess.push(processObj.value);
                        this.applyObj.processes = [...this.mainProcess];
                    } else {
                        toast.error(`Tasks in Pending for this Risk`, {
                            timeout: 5000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                } else {
                    let processObj = { value: this.$route.params.processId, label: this.businessProcess.businessProcessName };
                    this.applyPopup = true;
                    this.mappedProcessOptions.push(processObj);
                    this.mainProcess.push(processObj.value);
                    this.applyObj.processes = [...this.mainProcess];
                }
            } else if (route == '/clone') {
                console.log('riskkkkkk', risk);
                let tierType: any;
                if (risk.tier == 1) {
                    tierType = 'organization';
                } else if (risk.tier == 2) {
                    tierType = 'process';
                } else if (risk.tier == 3) {
                    tierType = 'systems';
                }

                this.$router.push({ name: 'cloner', params: { mappedRisk: risk.mappedRisk, riskId: risk._id, type: tierType }, query: { process: true } });
            }
        },

        async createTaskForAction(applyObj: any, actionCode: any) {
            this.clickOnButton = true
            let payload: any = [
                {
                    process: applyObj.processes,
                    mappedRisk: this.mainRisk.mappedRisk,
                    createdAt: new Date(),
                    createdBy: this.userInfo._id,
                    action: actionCode
                }
            ];
            payload[0].comments = applyObj.comments;
            await this.createWorkFlowTask(payload);

            Object.keys(this.applyObj).forEach((prop) => {
                Array.isArray(this.applyObj[prop]) ? (this.applyObj[prop] = []) : (this.applyObj[prop] = '');
            });
            await this.getAllTasks();
            await this.getOrgProcessRisks();

            this.mainProcess = [];
        },
        async createWorkFlowTask(payload: any) {
            try {
                payload[0].tier = 2;
                await this.$http.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/task`, payload).then((result: any) => {
                    this.insertedIds = result.data;
                    if(this.thisScreen == 'Acceptance'){
                        toast.info('Task Accepted', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    } else if(this.thisScreen == 'reset'){
                        toast.info('Task Reset', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    } else if(this.thisScreen == 'deactive'){
                        toast.info('Task Deleted', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    } else if(this.thisScreen == 'Avoid'){
                        toast.info('Task Avoided', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    } else if(this.thisScreen == 'Transfer'){
                        toast.info('Task Transferd', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    } else  {
                        toast.info('Task Created', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                });
                payload[0].insertedIds = this.insertedIds;
                await this.createActionForRisk(payload);
                this.applyPopup = false;
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
        },
        async createActionForRisk(payload: any) {
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/risk/accept`, payload).then((result: any) => {
                    console.log('Done');
                });
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async createAssesmentTask() {
            try {
                let payload: any = [];
                this.checkedRisks.map((id: any) => {
                    
                    let riskItems = this.riskAssesmentList.filter((item: any) => item._id == id).map((item: any) => item._id);
                    let arrayIds: any = this.checkedRisks;
                    let payloadObj = {
                        processId: this.$route.params.processId,
                        riskId: riskItems,
                        statusCode: 10401,
                        createdAt: new Date(),
                        createdBy: this.userInfo._id,
                        updatedStatus: 10401,
                        tier: 2,
                        riskOwner: this.userInfo._id
                    };
                    payload.push(payloadObj);
                });
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/create/risks`, payload).then((result: any) => {
                    this.checkedRisks = [];
                    toast.info('created', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
                await this.getOrgProcessRisks();
                await this.getRisksDiscoveryForProcess();
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },

        async getOrgProcessRisks() {
            this.risksList = [];
            this.rowData = [];
            let id = this.$route.params.processId;
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/process/${id}/get/risks`).then((result: any) => {
                this.rowData = result.data.filter((obj: any) => {
                    return obj.statusCode != 10404;
                });
                this.rowData.forEach((risk: any) => {
                    this.openList = risk;
                    let condition1 = Object.keys(risk).includes('residual') && risk['residual'].length > 0;
                    let averageImpact;
                    let impact;
                    let severity;

                    if (condition1 == true) {
                        let lengthOfArray = risk['residual'].length;
                        let indexObj = risk['residual'][lengthOfArray - 1];

                        if (indexObj.isActive == true) {
                            averageImpact = this.average([indexObj['business'], indexObj['operations'], indexObj['legal'], indexObj['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * indexObj['likelihood']);

                            risk.business = indexObj['business'];
                            risk.operations = indexObj['operations'];
                            risk.legal = indexObj['legal'];
                            risk.financial = indexObj['financial'];
                            risk.likelihood = indexObj['likelihood'];
                        } else {
                            averageImpact = this.average([ this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * this.openList['likelihood']);
                        }
                    } else if (condition1 == false) {
                        averageImpact = this.average([ this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                        impact = Math.round(averageImpact);
                        severity = this.riskSeverity(impact * this.openList['likelihood']);
                    }
                    risk.overallImpact = impact;
                    risk.severity = severity;

                    if (!Object.keys(risk).includes('updatedAt') && risk.updatedAt != '') {
                        risk.updatedAt = '';
                    }

                    risk.entityId = 20006;
                    
                    risk.riskOwner = this.optionsUserArray.find((value: any)=>{return value.value == risk.riskOwner});
                    risk.riskOwnerName = risk.riskOwner != undefined ?  risk.riskOwner.label:'N/A'
                    this.risksList.push(risk);
                });
            });
            // this.$store.dispatch('updateDotsLoading', false);
        },
        async getallUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.usersList = res.data;
                this.orgUsersList = res.data;
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                }
            });
        },
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id: org._id, name: org.organizationName };
                    this.tenants.push(obj);
                    const object = { value: org._id, label: org.organizationName };
                    this.tenantsSelect.push(object);
                });
            });
        },
        async getRootRisksList() {
            this.rootRisksList = [];
            this.riskCategoryList = [];
            this.threatCategoryList = [];
            this.threatSourceList = [];
            const result = await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/risks/getall`);

            result.data.forEach((risk: any) => {
                !this.riskCategoryList.includes(risk.riskCategory) ? this.riskCategoryList.push(risk.riskCategory) : '';

                !this.threatCategoryList.includes(risk.threatCategory) ? this.threatCategoryList.push(risk.threatCategory) : '';

                !this.threatSourceList.includes(risk.threatSource) ? this.threatSourceList.push(risk.threatSource) : '';

                if (risk.operations) {
                    let averageImpact = Math.round(this.average([risk.business, risk.operations, risk.legal, risk.financial]));
                    risk.overallImpact = averageImpact;
                    risk.severity = this.riskSeverity(risk.overallImpact * risk.likelihood);
                    this.rootRisksList.push(risk);
                }
            });
        },
        async getRootInformationList() {
            this.rootInformationsList = [];

            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall?org=true`).then(async (result: any) => {
                result.data.forEach((info: any) => {
                    this.rootInformationsList.push(info);
                });
            });
            console.log("rootInformationsList",this.rootInformationsList);
            this.rootInformationsList.forEach((info: any) => {
                !this.infoCategoryList.includes(info.informationCategory) ? this.infoCategoryList.push(info.informationCategory) : '';
                // !this.infoList.includes(info.information) ? this.infoList.push(info.information) : '';                        

                let obj = { value: info._id, label: info.information };
                this.savedInformationSets.push(obj);
            });
            await this.getBusinessProcess();

            let present = this.rootInformationsList.filter((obj: any) => {
                if(obj.process){
                    return obj.process.includes(this.$route.params.processId)
                }else{
                    return false
                }

            // if(this.businessProcess.informations)
            // {
            //     return this.businessProcess.informations.includes(obj._id);
            // }
            // else{
            //     return false;
            // }
            });
            this.mappedInfoForProcess = present;            
            // this.$store.dispatch('updateDotsLoading', false);
        },
        async getBusinessProcess() {
            this.businessProcessList = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then(async (result: any) => {                
                result.data.forEach((entity: any) => {
                    this.businessProcessList.push(entity);
                });

                 result.data.forEach((entity:any) => {
                    
                    var BPname = this.optionsUserArray.find((obj:any)=>{
                       return obj.value == entity.name
                    });
                    entity.name = BPname;

                    var BPdepartment = this.departmentList.find((obj:any)=>{
                        return obj.value == entity.department
                    });
                    entity.department = BPdepartment;

                    var BPcriticality = this.dbProcessCriticality.find((obj:any)=>{
                        return obj.value == entity.criticality
                    });
                    entity.criticality = BPcriticality

                    if(entity.systems != undefined){
                        entity.systems.map((systemId:any,index:any)=>{
                            let systemObj = this.systems.find((obj:any)=>{return obj.value == systemId});
                            if(systemObj!=undefined){
                                entity.systems[index] = systemObj
                            }
                        })
                    }
                 })

                await this.getPresentBusinessProcess();
            });
            // this.$store.dispatch('updateDotsLoading', false);
        },
        async getPresentBusinessProcess() {
            let findObj = this.businessProcessList.find((obj: any) => {
                return obj._id == this.$route.params.processId;
            });
            if (findObj != undefined) {
                
                this.businessProcess = findObj;
                console.log("businessProcess",this.businessProcess)
                this.dummyPresentProcess = { ...findObj };
            }
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                console.log("systems info");
                
                this.systems = result.data;
                this.systems = this.systems.map((sys: any) => {
                    return { value: sys._id, label: sys.systemName };
                });
            });
        },
        async getAllTeams() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                for (var i = 0; i < res.data.length; i++) {
                    let obj = { value: res.data[i]._id, label: res.data[i].teamName };
                    this.departmentList.push(obj);
                }
            });
        },
        async getAllTasks() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/tasks/getall`).then((result: any) => {
                this.tasksData = result.data;
            });
        },

        showPriorityPopUpAction(data: any) {
            this.mainRisk = { ...data };
            this.riskObjCopy = { ...data };

            this.showPriorityPopUp = true;

            this.riskPriorityShow.risk = data.risk;
            this.riskPriorityShow.priority = data.priority;
            this.riskPriorityShow.justification = '';

            this.priorityDummy.risk = data.risk;
            this.priorityDummy.priority = data.priority;
            this.priorityDummy.justification = '';
        },

        closePriorityPopup() {
            this.resetRiskPriority();
            this.showPriorityPopUp = false;
        },

        cancelPriorityBtn() {
            this.riskPriorityShow.risk = this.priorityDummy.risk;
            this.riskPriorityShow.priority = this.priorityDummy.priority;
            this.riskPriorityShow.justification = '';
            this.priorityDummy.justification = '';
            this.v$.$reset();
        },

        async addOrUpdatePriority() {
            console.log('add clicked');
            this.v$.$touch();
            if (!this.v$.riskPriorityShow.$invalid) {
                this.clickOnButton = true
                this.mainRisk.priority = this.riskPriorityShow.priority;
                this.mainRisk.priorityComments = this.riskPriorityShow.justification;

                let riskData = { ...this.mainRisk };

                let oldObj: any = {};
                let newObj: any = {};
                Object.keys(riskData).map((key: any) => {
                    if (riskData[key] != this.riskObjCopy[key] && key != 'updatedValues' && key != 'priorityComments') {
                        oldObj[key] = this.riskObjCopy[key];
                        newObj[key] = riskData[key];
                    }
                });
                riskData.updatedValues = { oldRisk: [], newRisk: [] };
                riskData.updatedValues.oldRisk.push(oldObj);
                riskData.updatedValues.newRisk.push(newObj);

                let risk: any = [];

                risk.push(riskData);
                //updatingRisk
                await this.updateRisk(risk);

                this.v$.$reset();
                this.mainRisk = {};
            } else {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },

        resetRiskPriority() {
            this.riskPriorityShow.risk = '';
            this.riskPriorityShow.priority = null;
            this.riskPriorityShow.justification = '';
            // this.riskPriorityShow._id=null;
            this.priorityDummy.risk = '';
            this.priorityDummy.priority = null;
            this.priorityDummy.justification = '';
            // this.priorityDummy._id=null;
            this.v$.$reset();
        },
        updateButtonStates() {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    this.canScrollLeft = tabContainer.scrollLeft > 0;
                    this.canScrollRight = tabContainer.scrollLeft + tabContainer.clientWidth < tabContainer.scrollWidth;
                } else {
                    this.canScrollLeft = this.currentGroupIndex > 0;
                    this.canScrollRight = this.currentGroupIndex < this.totalGroups - 1;
                }
            }
        },
        handleResize() {
            this.isSmallScreen = window.innerWidth <= 768;
            if (this.isSmallScreen) {
                this.calculateTabsPerGroup();
                if (this.currentGroupIndex > this.totalGroups - 1) {
                    this.scrollTabs('left');
                }
            } else {
                this.tabsPerGroup = this.calculateTabsForLargeScreen();
                this.updateVisibleTabs();
            }
            this.updateButtonStates();
        },
        calculateTabsPerGroup() {
            const tabContainerWidth = this.$refs.tabContainer.clientWidth;

            let sumWidth = 0;
            let count = 0;
            const tabs = this.carouselHead;

            for (let i = 0; i < tabs.length; i++) {
                const tabWidth = this.getTabWidth(tabs[i]);

                if (sumWidth + tabWidth > tabContainerWidth) {
                    break;
                }

                sumWidth += tabWidth;
                count++;
            }
            this.tabsPerGroup = count;
            this.updateVisibleTabs();
        },
        calculateTabsForLargeScreen() {
            const tabContainerWidth = this.$refs.tabContainer.clientWidth;

            const tabs = this.carouselHead;
            let sumWidth = 0;
            let count = 0;

            for (let i = 0; i < tabs.length; i++) {
                const tabWidth = this.getTabWidth(tabs[i]);

                if (sumWidth + tabWidth > tabContainerWidth) {
                    break;
                }

                sumWidth += tabWidth;
                count++;
            }
            return count;
        },
        getTabWidth(tab: any) {
            // Simulate tab width calculation; replace with your actual logic
            const dummyDiv = document.createElement('div');
            dummyDiv.innerHTML = tab.displayName;
            dummyDiv.style.display = 'inline-block';
            dummyDiv.style.visibility = 'hidden';
            document.body.appendChild(dummyDiv);
            const width = dummyDiv.clientWidth;
            document.body.removeChild(dummyDiv);
            return width + 24; // Adjust padding or margin as needed
        },
        async updateVisibleTabs() {
            if (!this.isSmallScreen) {
                console.log("isSmallScreen")
                this.visibleTabs = [...this.carouselHead];
            } else {
                console.log("startIdx")
                const startIdx = this.currentGroupIndex * this.tabsPerGroup;
                const endIdx = startIdx + this.tabsPerGroup;
                this.visibleTabs = this.carouselHead.slice(startIdx, endIdx);
                if (this.visibleTabs) {
                    console.log("visibleTabs", this.visibleTabs)
                    let savedTabKey: any = sessionStorage.getItem('selectedTab');
                    savedTabKey = parseInt(savedTabKey)
                    if (savedTabKey) {
                        const savedTab = this.visibleTabs.find((tab: any) => tab.id == savedTabKey);
                        if (savedTab) {
                            await this.toggleTabs(savedTab);
                        }else{
                            this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
                            this.selectedIndex = 0;
                        }
                    }
                    else{
                        this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
                        this.selectedIndex = 0;
                        await this.toggleTabs(this.selectedTabObj);
                    }
                }
                this.totalGroups = Math.ceil(this.carouselHead.length / this.tabsPerGroup);
            }
            this.updateButtonStates();
        },
        scrollTabs(direction: any) {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    const scrollAmount = this.calculateScrollAmount(direction);
                    if (direction === 'left') {
                        tabContainer.scrollLeft -= scrollAmount;
                    } else if (direction === 'right') {
                        tabContainer.scrollLeft += scrollAmount;
                    }
                    this.updateButtonStates();
                } else {
                    if (direction === 'left' && this.currentGroupIndex > 0) {
                        this.currentGroupIndex--;
                    } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                        this.currentGroupIndex++;
                    }
                    this.updateVisibleTabs();
                    this.updateButtonStates(); // Update button states after changing currentGroupIndex
                }
            }
        },
        calculateScrollAmount(direction: any) {
            const tabContainer = this.$refs.tabContainer;
            const visibleTabs = this.visibleTabs;
            let sumWidth = 0;
            let scrollAmount = 0;

            if (direction === 'left') {
                for (let i = visibleTabs.length - 1; i >= 0; i--) {
                    const tabWidth = this.getTabWidth(visibleTabs[i]);
                    if (sumWidth + tabWidth > tabContainer.clientWidth) {
                        break;
                    }
                    sumWidth += tabWidth;
                    scrollAmount += tabWidth;
                }
            } else if (direction === 'right') {
                for (let i = 0; i < visibleTabs.length; i++) {
                    const tabWidth = this.getTabWidth(visibleTabs[i]);
                    if (sumWidth + tabWidth > tabContainer.clientWidth) {
                        break;
                    }
                    sumWidth += tabWidth;
                    scrollAmount += tabWidth;
                }
            }
            return scrollAmount;
        },
        scrollGroups(direction: any) {
            if (direction === 'left' && this.currentGroupIndex > 0) {
                this.currentGroupIndex--;
            } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                this.currentGroupIndex++;
            }
            this.updateVisibleTabs();
            this.updateButtonStates(); // Update button states after changing currentGroupIndex
        },
        handleScroll() {
            this.updateButtonStates();
        }
    },
    async mounted() {
        this.visibleTabs = [...this.carouselHead];
        // this.selectedTabIndex = 0;
        window.addEventListener('resize', this.handleResize);
        this.handleResize(); // Initial setup
        this.updateButtonStates(); // Initial update to button states

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });

        emitter.off('showData123');
        emitter.on('showData123', (isData: any) => {
            console.log('fgvhbkjln', isData);
            let comingId = isData.entityData._id;
            if (this.selectedTabIndex == 0) {
                let showObj = isData.entityData;
                this.showRiskEditPopUp(showObj);
            } else if (this.selectedTabIndex == 1) {
                let showObj = isData.entityData;
                this.showInfoEditPopUp(showObj);
            }
        });

        emitter.off('changeRouteInProcessInfo');
        emitter.on('changeRouteInProcessInfo', (selectedActionObj: any) => {
            let risk = selectedActionObj.data;
            let route = selectedActionObj.route;
            this.changeRoute(risk, route);
        });

        emitter.off('showPriorityPopup');
        emitter.on('showPriorityPopup', (isData: any) => {
            this.showPriorityPopUpAction(isData);
        });
    },
    async created() {
        this.dataLoading = true;

        await this.getallUsers();
        await this.getAllTeams();
        await this.getAllTenants();
        await this.getAllTasks();
        // await this.toggleTabs(0);
        let savedTabKey: any = sessionStorage.getItem('selectedTab');
        console.log("savedTabKey",savedTabKey)
        savedTabKey = parseInt(savedTabKey)
		if (savedTabKey) {
		    const savedTab = this.carouselHead.find((tab: any) => tab.id == savedTabKey);
            console.log("savedTab", savedTab)
            if (savedTab) {
                await this.toggleTabs(savedTab);
            }else{
                this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
                this.selectedIndex = 0;
            }
		}
		else{
			this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
			this.selectedIndex = 0;
            await this.toggleTabs(this.selectedTabObj);
		}

        await this.getSystems();
        await this.getBusinessProcess();
        await this.getRootRisksList();

        this.dataLoading = false;
        
        this.routesArray = [
            { name: 'Business Process', routeName: 'businessProcess' },
            { name: this.businessProcess.businessProcessName, routeName: 'processInformation', processId: `${this.$route.params.processId}` }
        ];
        this.navIndexNums = { index: 0, name: 'Risks' };
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        sessionStorage.removeItem('selectedTab');
    }
});
</script>
