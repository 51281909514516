<template>
    <div class="pt-3 pl-5 pr-5 setBox">
        <div class="flex justify-between items-center">
            <h1 class="title">Vendors</h1>
            <div class="flex items-center">
                <!-- <div class="noteam text-center">
                    <button class="btnprimary mr-2" @click="showPopUp()">Add</button>
                </div> -->
                <input type="file" class="hidden" accept=".xls,.xlsx" ref="file" @change="fileReader($event)" />
                <div>
                    <div @click="filterOpen">
                        <button class="flex" :class="tableRow.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3 text-xs">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </div>

        <div class="mt-5 px-5 pb-5 bg-white w-full border border-lightgrey pt-5 relative rounded">
            <div class="group-buttons" v-if="isSmallScreen">
                <!-- Group navigation buttons for smaller screens -->
                <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': (selectedIndex === 0 || currentGroupIndex === 0) && isSmallScreen }">
                    <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                </div>
                <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': (selectedIndex === carouselHead.length - 1 || currentGroupIndex >= totalGroups - 1) && isSmallScreen }">
                    <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                </div>
            </div>
            
            <div>
                <div>
                    <div class="flex flex-row borderBottom cursor-pointer tab-container" ref="tabContainer" @scroll="handleScroll">
                        <div class="pb-1" :class="selectedIndex == tab.id ? 'adjust header font-semibold primary':'text-sm text-disabledgrey'" v-for="(tab, index) in visibleTabs" :key="index" @click="toggleTabs(tab)">
                            <div>
                                <div ref="actualIndex" class="cursor-pointer">{{ tab.displayName }}</div>
                                <div :class="{ progress: selectedIndex === tab.id }" class="h-1 p-0.75 relative rounded" style="z-index: 1"></div>
                            </div>
                        </div>
                    </div>
                </div>
 
                <div class="progressbarB rounded h-1"></div>
            </div>
            <div>
                <newTable :tableRowData="tableRow" :headersData="headers" :openPopup="true" :loadData="dataLoading"></newTable>
                <div v-if="toolTipActive">
                    <tool-tip :tipText="toolTipText"></tool-tip>
                </div>
            </div>
        </div>
        <popup class="thirdPartyPopup" v-if="selectedIndex == 20003 && popUp" :popUp="popUp">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Third-party</h1>
                    <button class="float-right text-3xl" @click="onCancelbtn('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset :disabled="true">
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Name</label>
                                        <div class="text-sm">{{thirdParty.name != undefined ? thirdParty.name : 'N/A'}}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Display Name</label>
                                        <div class="text-sm">{{thirdParty.displayName  != undefined ? thirdParty.displayName : 'N/A'}}</div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Industry Sector</label>
                                        <div class="text-sm">{{thirdParty.industrySectorName ? thirdParty.industrySectorName : 'N/A'}}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Email</label>
                                        <div class="text-sm">{{thirdParty.emailAddress ? thirdParty.emailAddress : 'N/A'}}</div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Phone</label>
                                        <div class="text-sm">{{thirdParty.phoneNumber ? thirdParty.phoneNumber : 'N/A'}}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Contract Start Date</label>
                                        <div class="text-sm">{{thirdParty.contractStartDate ? thirdParty.contractStartDate : 'N/A'}}</div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Contract Expiry Date</label>
                                        <div class="text-sm">{{thirdParty.contractExpirationDate ? thirdParty.contractExpirationDate : 'N/A'}}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Country</label>
                                        <div class="text-sm">{{thirdParty.country ? thirdParty.country : 'N/A'}}</div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">State</label>
                                        <div class="text-sm">{{thirdParty.state ? thirdParty.state : 'N/A'}}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">{{ 'City' }}</label>
                                        <div class="text-sm">{{thirdParty.city ? thirdParty.city : 'N/A'}}</div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">PIN Code</label>
                                        <div class="text-sm">{{thirdParty.zipCode  ? thirdParty.zipCode : 'N/A'}}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                    <label class="controllabel">Responsibility</label>
                                        <div class="text-sm">{{ entitiesList.find(entity => entity.value === thirdParty.responsibleBusinessEntity)?.label || "N/A" }}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Entities that use the third-party</label>
                                        <div class="text-sm">{{thirdParty.entitiesusethirdparty != undefined ? thirdParty.entitiesusethirdparty[0].label : 'N/A'}}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Address Line 1</label>
                                        <div class="text-sm">{{thirdParty.addressLineOne ? thirdParty.addressLineOne : 'N/A'}}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Address Line 2</label>
                                        <div class="text-sm">{{thirdParty.addressLineTwo || 'N/A'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="buttonposition flex justify-center items-center mb-4 mx-0" v-if="userInfo.currentRole == 10207">
                    <button class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn(thirdParty._id, 20003)">Cancel</button>
                    <button class="btnprimary" v-if="!thirdParty._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdate(thirdParty, 20003)">Save</button>
                    <button class="btnprimary" v-else :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdate(thirdParty, 20003)">Update</button>
                </div> -->
            </fieldset>
        </popup>

        <popup class="thirdPartyContactsPopup" v-if="selectedIndex == 20004 && popUp" :popUp="popUp">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Third-party Contacts</h1>
                    <button class="float-right text-3xl" @click="onCancelbtn('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset :disabled="true">
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer ">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Third Party</label>
                                        <div class="text-sm">{{thirdPartyContacts.thirdParty.label || 'N/A'}}</div>
                                        <!-- <select class="inputboxstyling" disabled v-model="thirdPartyContacts.thirdParty">
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option v-for="(value, index) in companyNameListOne" :key="index">{{ index.label }}</option>
                                        </select> -->
                                    </div>
                                    <div class="leftspacing w-full">
                                        <label class="controllabel">Type</label>
                                        <div class="text-sm">{{ type.find(item => item.id === thirdPartyContacts.type)?.value || "-" }}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Name</label>
                                        <div class="text-sm">{{ thirdPartyContacts.fullName || "N/A" }}</div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Job Title</label>
                                        <div class="text-sm">{{thirdPartyContacts.role}}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Email</label>
                                        <div class="text-sm">{{thirdPartyContacts.emailAddress}}</div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Phone</label>
                                        <div class="text-sm">{{thirdPartyContacts.phoneNumber}}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full pt-2">
                                    <div class="w-6/12">
                                        <label class="controllabel">Country</label>
                                        <div class="text-sm">{{thirdPartyContacts.country}}</div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">State</label>
                                        <div class="text-sm">{{thirdPartyContacts.state}}</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">City</label>
                                        <div class="text-sm">{{thirdPartyContacts.city}}</div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">PIN Code</label>
                                        <div class="text-sm">{{thirdPartyContacts.zipCode}}</div>
                                    </div>
                                </div>
                                <div class="upperspacing w-full">
                                    <label class="controllabel">Address Line 1</label>
                                    <div class="text-sm">{{thirdPartyContacts.addressLineOne}}</div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Address Line 2</label>
                                        <div class="text-sm">{{thirdPartyContacts.addressLineTwo || "N/A"}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="buttonposition flex justify-center items-center mb-4 mx-0" v-if="userInfo.currentRole == 10207">
                        <button class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn(thirdPartyContacts._id, 20004)">Cancel</button>
                        <button class="btnprimary" v-if="!thirdPartyContacts._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdate(thirdPartyContacts, 20004)">Save</button>
                        <button class="btnprimary" v-else :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdate(thirdPartyContacts, 20004)">Update</button>
                    </div> -->
                </div>
            </fieldset>
        </popup>
    </div>
</template>
<style scoped>
.tab-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 60px !important; 
}
.borderRed {
    border: 1px solid red !important;
}
/* .multiselect-wrapper {
    min-height: 20px !important;
}
.multiselect-tags-search-wrapper {
    margin: 0px !important;
} */
 .progressBarHeight{
    height:4px;
    position:relative;
 }
.anime-left {
    animation-name: moveLeft;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
@keyframes moveLeft {
    0% {
        opacity: 1;
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(0);
    }
}
.anime-right {
    animation-name: moveRight;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
@keyframes moveRight {
    0% {
        opacity: 1;
        transform: translateX(-100vw);
    }
    100% {
        transform: translateX(0);
    }
}
/* .progress {
    background: #152a43;
}
.progressbarB {
    border: 2px solid #e9e9e9;
    background: #e9e9e9;
    position: relative;
    top: -8px;
    border-radius: 2px;
} */
.setBox {
    width: initial;
}

.box {
    border: 2px solid red;
}
.adjust {
    flex-shrink: 0;
}
.button-adjust {
    position: relative;
    top: -30px;
    z-index: 1;
    cursor: pointer;
}
.borderBottom {
    position: relative;
    z-index: 2;
    cursor: pointer;
}
.businesssPopup label {
    font-size: 16px;
    font-family: Open Sans !important;
}
</style>
<script lang="ts">
import { forEach } from 'lodash';
import { defineComponent } from 'vue';
import { required, maxLength, minLength, email, helpers, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { emitter, toast } from '../../main';
const numeric = helpers.regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
const alphabet = helpers.regex(/^[a-zA-Z]+$/);
const zip = helpers.regex(/^\d{5}(?:\d{1})?$/);
import dropdown from '@/components/dropdown.vue';
import * as XLSX from 'xlsx';
import popup from '@/components/popup.vue';
import multiselect from "vue-multiselect";
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import newTable from '@/components/newTable.vue';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            objToBeModified: {},
            dummyObj: {},
            companyNameList: [],
            companyNameListOne: [],
            companyType: [],
            companyTypeOne: [],
            showFilters: false,
            id: [],
            autoSuggestion: false,
            selectedCategory: [],
            permissions: [],
            nameList: [],
            carouselHead: [
                // { displayName: 'Business Entity', name: 'businesss', id: 20001 },

                // { displayName: 'Business Entity Contacts', name: 'businessContacts', id: 20002 },

                { displayName: 'Third-party', name: 'thirdParty', id: 20003 },

                { displayName: 'Third-party Contacts', name: 'thirdPartyContacts', id: 20004 },
            
            ],
            selectedTabObj: { displayName: 'Third-party', name: 'thirdParty', id: 20003 },
            index: 0,
            selectedIndex: 0,
            selectedHead: 0,
            popUp: false,
            currentPage: 1,
            pageSize: 6,
            rowData: [],
            thirdPartyPrimaryCondition: false,
            thirdPartyList: [],
            sideButton: false,
            data: {},
            tableRow: [],
            presentUserObj: {},
            headers: ['_id', 'entityId', 'Name', 'Display Name', 'Industry Sector Name'],
            businesss: {
                companyName: '',
                companyDisplayName: '',
                industrySectorName: '',
                emailAddress: '',
                phoneNumber: '',
                addressLineOne: '',
                addressLineTwo: '',
                entityType: '',
                city: '',
                state: '',
                zipCode: '',
                country: ''
            },
            businessContacts: {
                companyName: '',
                contactType: '',
                name: '',
                role: '',
                emailAddress: '',
                phoneNumber: ''
            },
            thirdParty: {
                name: '',
                displayName: '',
                industrySectorName: '',
                emailAddress: '',
                phoneNumber: '',
                addressLineOne: '',
                addressLineTwo: '',
                responsibleBusinessEntity: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
                contractExpirationDate: '',
                contractStartDate: '',
                entitiesusethirdparty: []
            },
            thirdPartyContacts: {
                thirdParty: '',
                type: '',
                fullName: '',
                role: '',
                emailAddress: '',
                phoneNumber: '',
                addressLineOne: '',
                addressLineTwo: '',
                city: '',
                state: '',
                zipCode: '',
                country: ''
            },
            countriesStatesCities: [
                {
                    CountryName: 'India',
                    States: [
                        {
                            StateName: 'Maharashtra',
                            Cities: ['Pune', 'Nagpur', 'Mumbai']
                        },
                        {
                            StateName: 'Kerala',
                            Cities: ['Kochi', 'Munnar']
                        },
                        {
                            StateName: 'Telangana',
                            Cities: ['Hyderabad', 'Nizamabad', 'Warangal']
                        },
                        {
                            StateName: 'Andhra Pradesh',
                            Cities: ['Vijayawada', 'Vizag', 'Rajamundry']
                        }
                    ]
                },
                {
                    CountryName: 'Australia',
                    States: [
                        {
                            StateName: 'Aukland',
                            Cities: ['GlenField', 'Henderson', 'MilFord']
                        },
                        {
                            StateName: 'Melbourne',
                            Cities: ['Melbourne', 'South Oakleigh']
                        }
                    ]
                }
            ],
            systems: [],
            users: [],
            optionsUserArray: [],
            riskRoles: [
                {
                    roleName: 'Root Admin',
                    roleId: 10206,
                    moduleId: 10003,
                    disabled: true
                },
                {
                    roleName: 'Client Admin',
                    roleId: 10207,
                    moduleId: 10003
                },
                {
                    roleName: 'User',
                    roleId: 10208,
                    moduleId: 10003
                }
            ],
            tenantsSelect: [],
            tenants: [],
            savedInformationSets: [],
            teams: [],
            cloud: ['AWS', 'Microsoft Azure', 'Google Cloud', 'DigitalOcean', 'IBM', 'Rackspace', 'Oracle', 'Red Hat', 'Alibaba'],
            departmentList: [],
            editBusiness: false,
            orgType: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Subsidiary'
                },
                {
                    id: 3,
                    value: 'Branch'
                }
            ],
            ownerTypeValues: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Secondary'
                }
            ],
            type: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Secondary'
                }
            ],
            entitiesList: [],
            rowDetails: {},
            countriesList: [],
            statesList: [],
            citiesList: [],
            stateInfoList: [],
            visibleTabs: [], // Tabs visible on screen
            currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
            totalGroups: 0,

            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,
        };
    },
    validations() {
        return {
            businesss: {
                companyName: { required },
                companyDisplayName: { required },
                industrySectorName: { required },
                emailAddress: { required, email },
                phoneNumber: { required, numeric },
                addressLineOne: { required },
                entityType: { required },
                city: { required, alphabet },
                state: { required, alphabet },
                country: { required, alphabet },
                zipCode: { required, zip }
            },
            businessContacts: {
                companyName: { required },
                contactType: { required },
                name: { required },
                role: { required },
                emailAddress: { required, email },
                phoneNumber: { required, numeric }
            },
            thirdParty: {
                name: { required },
                displayName: { required },
                industrySectorName: { required },
                emailAddress: { required, email },
                phoneNumber: { required, numeric },
                addressLineOne: { required },
                responsibleBusinessEntity: { required },
                city: { required, alphabet },
                state: { required, alphabet },
                zipCode: { required, zip },
                country: { required, alphabet },
                contractExpirationDate: {
                    required,
                    minValue: helpers.withMessage('', (value: any) => {
                        return new Date(value) > new Date(this.thirdParty.contractStartDate);
                    })
                },
                contractStartDate: { required },
                entitiesusethirdparty: { required }
            },
            thirdPartyContacts: {
                thirdParty: { required },
                type: { required },
                fullName: { required },
                role: { required },
                emailAddress: { required, email },
                phoneNumber: { required, numeric },
                addressLineOne: { required },
                city: { required, alphabet },
                state: { required, alphabet },
                zipCode: { required, zip },
                country: { required, alphabet }
            }
        };
    },
    async created() {
        await this.getBusinessEntities();
        this.countriesStatesCities.map((obj: any) => {
            this.countriesList.push(obj.CountryName);
        });
        let savedTabKey: any = sessionStorage.getItem('selectedTab');
        console.log("savedTabKey",savedTabKey)
        
		if (savedTabKey) {
            savedTabKey = parseInt(savedTabKey)
		    const savedTab = this.carouselHead.find((tab: any) => tab.id == savedTabKey);
            console.log("savedTab", savedTab)
            if (savedTab) {
                await this.toggleTabs(savedTab);
            }else{
                this.selectedTabObj = { displayName: 'Third-party', name: 'thirdParty', id: 20003 },
                this.selectedIndex = 20003;
            }
		}
		else{
			this.selectedTabObj = { displayName: 'Third-party', name: 'thirdParty', id: 20003 },
			this.selectedIndex = 20003;
            await this.toggleTabs(this.selectedTabObj);
		}
    },

    async mounted() {

        this.visibleTabs = [...this.carouselHead];
        window.addEventListener('resize', this.handleResize);
        this.handleResize(); 
        this.updateButtonStates(); 
        await this.getAllUsers();
        await this.getAllTenants();
        

        

        
        this.windowWidthMethod();
        this.getSystems();
        this.getInformationSets();
        this.getAllTeams();

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });

        emitter.off('showData123');
        emitter.on('showData123', (isData: any) => {
            let comingId = isData.entityData._id;
            let showObj = this.rowData.find((obj: any) => {
                return obj._id == comingId;
            });
            this.showEditPopUp(showObj);
            this.dummyObj = { ...showObj };
            switch (isData.entityData.entityId) {
                case 20003:
                    this.thirdParty = showObj;

                    if(this.thirdParty.entitiesusethirdparty != undefined){
                        this.thirdParty.entitiesusethirdparty.map((id:any,index:any)=>{
                            let systemObj = this.entitiesList.find((obj:any)=>{return obj.value == id});
                            if(systemObj!=undefined){
                                this.thirdParty.entitiesusethirdparty[index] = systemObj
                            }
                        })
                    }

                    if(this.thirdParty.country){
                        this.changeCountry(this.thirdParty.country);
                    }
                    if(this.thirdParty.state){
                        this.changeCountry(this.thirdParty.state);
                    }

                    break;
                case 20004:
                    this.thirdPartyContacts = showObj;
                    var newThirdParty = this.companyNameListOne.find((third: any) => {
                        return third.value == this.thirdPartyContacts.thirdParty
                    });
                                
                    this.thirdPartyContacts.thirdParty = newThirdParty != undefined ? newThirdParty : 'N/A' ;
                                
                    this.changeCountry(this.thirdPartyContacts.country);
                    this.changeState(this.thirdPartyContacts.state);
                    break;
            }
            this.popUp = true;
        });
    },
    components: {
        newTable,
        popup,
        multiselect,
        toolTip
    },
    computed: {
        ...mapGetters({ userInfo: 'userInfo', dbSecurityCategory: 'dbSecurityCategory' }),
        tabHead(): any {
            return this.carouselHead.filter((row: any, index: any) => {
                this.windowWidthMethod();
                let start = (this.currentPage - 1) * this.pageSize;
                this.index = 0;
                let end = this.currentPage * this.pageSize;

                if (index >= start && index < end) return true;
            });
        },
        disableStatus: function (): any {
            let disableStatusCondition;
            if (this.objToBeModified._id) {
                console.log('this.objToBeModified', this.objToBeModified);
                console.log('this.dummyObj', this.dummyObj);
                if (_.isEqual(this.objToBeModified, this.dummyObj)) {
                    disableStatusCondition = false;
                } else {
                    disableStatusCondition = true;
                }
            } else {
                let val: any = [];
                Object.values(this.objToBeModified).forEach((value: any) => {
                    val.push(value);
                });
                for (let n of val) {
                    if (n != '') {
                        disableStatusCondition = true;
                    }
                }
            }
            return disableStatusCondition;
        }
    },
    methods: {
        countryChanged() {
            this.thirdPartyContacts.state = '';
            this.thirdPartyContacts.city = '';
            this.thirdParty.state = '';
            this.thirdParty.city = '';
        },
        changeCountry(Country: any) {
            this.statesList = [];
            this.stateInfoList = [];
            let findobj = this.countriesStatesCities.find((obj: any) => {
                return obj.CountryName == Country;
            });
            if(findobj != undefined){
                findobj.States.map((obj: any) => {
                    this.stateInfoList.push(obj);
                    this.statesList.push(obj.StateName);
                });
            }
        },
        changeState(state: any) {
            this.citiesList = [];
            let findobj = this.stateInfoList.find((obj: any) => {
                return obj.StateName == state;
            });
            this.citiesList = findobj.Cities;
        },
        getUserDetails(nameId: any, selectedIndexId: any) {
            let mainUser = this.users.find((user: any) => {
                return user._id == nameId;
            });
            if (selectedIndexId == 20002) {
                this.businessContacts.emailAddress = mainUser.email;
                this.businessContacts.role = mainUser.jobTitle;
            } else if (selectedIndexId == 20004) {
                this.thirdPartyContacts.emailAddress = mainUser.email;
                this.thirdPartyContacts.role = mainUser.jobTitle;
            }
        },
        getCategories(options: any, status: any) {
            status = status.charAt(0).toLowerCase() + status.replace(/\s+/g, '').substring(1);
            this.filteredCategories(options, status);
        },
        filteredCategoriesAddress(options: any, status: any) {
            if (status == 'country') {
                this.selectedCategory = this.countriesStatesCities
                    .map((country: any) => {
                        return country.CountryName;
                    })
                    .filter((category: any) => {
                        return category.toLowerCase().includes(options.toLowerCase());
                    });
                this.objToBeModified[status] = options;
            }
            if (status == 'state') {
                this.selectedCategory = this.countriesStatesCities
                    .filter((item: any) => item.CountryName == this.objToBeModified.country)
                    .map((state: any) =>
                        state.States.map((statename: any) => {
                            return statename.StateName;
                        })
                    )
                    .flat(1)
                    .filter((category: any) => {
                        return category.toLowerCase().includes(options.toLowerCase());
                    });
                this.objToBeModified[status] = options;
            }
            if (status == 'city') {
                this.selectedCategory = this.countriesStatesCities
                    .filter((item: any) => item.CountryName == this.objToBeModified.country)
                    .map((state: any) =>
                        state.States.map((statename: any) => {
                            if (statename.StateName == this.objToBeModified.state) {
                                return statename.Cities;
                            } else {
                                return [];
                            }
                        })
                    )
                    .flat(2)
                    .filter((category: any) => {
                        return category.toLowerCase().includes(options.toLowerCase());
                    });
                this.objToBeModified[status] = options;
            }
        },
        filteredCategories(options: any, status: any) {
            console.log('statusss', options);
            this.selectedCategory = [];
            this.autoSuggestion = true;
            if (status == 'country' || status == 'state' || status == 'city') {
                this.filteredCategoriesAddress(options, status);
            }
        },
        showEditPopUp(obj: any) {
            this.popUp = true;
            this.editBusiness = true;
            this.objToBeModified = obj;
        },
        showPopUp() {
            this.v$.$reset();
            let name = this.carouselHead.filter((x: any) => x.id == this.selectedIndex);
            name = name[0].name;
            Object.keys(this[name]).forEach((prop) => {
                if (prop == '_id' || prop == 'createdAt' || prop == 'updatedAt') {
                    delete this[name][prop];
                } else {
                    Array.isArray(this[name][prop]) ? (this[name][prop] = []) : (this[name][prop] = '');
                }
            });
            this.objToBeModified = this[name];
            this.popUp = true;
            this.editBusiness = false;
        },
        onCancelbtn(status: any, id: any) {
            if (status === 'close') {
                this.popUp = false;
                let presentIndex = this.rowDetails.index;
                this.rowData[presentIndex] = { ...this.dummyObj };
                this.rowDetails = {};
                this.v$.$reset();
            } else if (status) {
                let name = this.carouselHead.filter((x: any) => x.id == this.selectedIndex);
                name = name[0].name;
                this[name] = { ...this.dummyObj };
                this.objToBeModified = this[name];
                if (id == 20004 || id == 20003) {
                    this.changeCountry(this.objToBeModified.country);
                    this.changeState(this.objToBeModified.state);
                }
            } else if (!undefined) this.showPopUp();
        },
        async toggleTabs(navIndex: any) {
            
            if(navIndex.id == 20004){
                await this.getAllData(20003);
            }
            this.index = navIndex.id;
            this.selectedIndex = navIndex.id;
            this.selectedTabObj = navIndex;
            console.log("navIndex", navIndex)
            sessionStorage.setItem('selectedTab', navIndex.id);
            this.tableRow = [];
            this.getAllData(navIndex.id);
        },
        sort: function (s: any) {
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },

        windowWidthMethod() {
            let x = window.innerWidth;
            if (x > 1280) this.pageSize = 7;
            else if (x >= 1024 && x <= 1280) this.pageSize = 6;
            else if (x >= 768 && x < 1024) this.pageSize = 5;
            else if (x >= 640 && x < 768) this.pageSize = 4;
            else this.pageSize = 1;
        },

        filterOpen() {
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
        },
        async getBusinessEntities() {
            this.entitiesList = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                result.data.map((obj: any) => {
                    let objj = { value: obj._id, label: obj.entityName };
                    this.entitiesList.push(objj);
                });
            });
        },
        async getAllData(entityId: number) {
            if(entityId == 20003){
                this.headers = ['_id', 'entityId', 'Name', 'Display Name', 'Responsibility', 'Contract Start Date', 'Contract Expiry Date'];
            }else{
                this.headers = ['_id', 'entityId', 'Name', 'Job Title', 'type', 'Email', 'Phone', 'Vendor'];
            }
            this.dataLoading = true;
            this.tableRow = []
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.rowData = JSON.parse(JSON.stringify(result.data));
                this.dummyObj = {};
                var type = 'Type';
                emitter.emit('visible', false);
                this.showFilters = false;
                if (entityId == 20003) {
                    this.companyNameListOne = [];
                    this.companyTypeOne = [];
                }
                switch (entityId) {
                    case 20003:
                        
                        result.data.forEach((entity: any) => {
                            var mainCompany = this.entitiesList.find((entityObj: any) => {
                                return entityObj.value == entity.responsibleBusinessEntity;
                            });
                            if (mainCompany == undefined) {
                                this.tableRow.push({
                                    _id: entity._id,
                                    entityId: entityId,
                                    Name: entity.name ? entity.name : 'N/A',
                                    'Display Name': entity.displayName  ? entity.displayName : 'N/A',
                                    Responsibility: entity.responsibleBusinessEntity  ? entity.responsibleBusinessEntity : 'N/A',
                                    'Contract Start Date': entity.contractStartDate  ? entity.contractStartDate : 'N/A',
                                    'Contract Expiry Date': entity.contractExpirationDate  ? entity.contractExpirationDate : 'N/A'
                                });
                            } else {
                                this.tableRow.push(
                                    {   
                                        _id: entity._id, 
                                        entityId: entityId,
                                        Name: entity.name ? entity.name : 'N/A', 
                                        'Display Name': entity.displayName ? entity.displayName : 'N/A',
                                        Responsibility: mainCompany.label ? mainCompany.label : 'N/A', 
                                        'Contract Start Date': entity.contractStartDate  ? entity.contractStartDate : 'N/A', 
                                        'Contract Expiry Date': entity.contractExpirationDate  ? entity.contractExpirationDate : 'N/A'
                                    }
                                );
                            }

                           let properties = {value: entity._id, label: entity.name}
                            this.companyNameListOne.push(properties);
                            this.companyTypeOne.push(entity.entityType);
                        });
                        
                        break;
                    case 20004:
                        this.thirdPartyPrimaryCondition = false;
                        
                        result.data.forEach((entity: any) => {
                           
                            var newThirdParty = this.companyNameListOne.find((third: any) => {
                                return third.value == entity.thirdParty;
                            });
                            var i = this.companyNameListOne.indexOf(entity.name);
                            entity.entityType = this.companyTypeOne[i];
                            entity.thirdParty = newThirdParty;
                            var mainName = this.optionsUserArray.find((obj: any) => {
                                return obj.value == entity.fullName;
                            });
                            if (mainName == undefined) {
                                // console.log("lkiujhgfjhyg",entity)
                                this.tableRow.push({ _id: entity._id, entityId: entityId, Name: entity.fullName, 'Job Title': entity.role, type: entity.type, Email: entity.emailAddress, Phone: entity.phoneNumber,Vendor :entity.thirdParty !=  undefined ? entity.thirdParty.label : 'N/A' });
                            } else {
                                this.tableRow.push({ _id: entity._id, entityId: entityId, Name: mainName.label, 'Job Title': entity.role, type: entity.type, Email: entity.emailAddress, Phone: entity.phoneNumber, Vendor : entity.thirdParty !=  undefined ? entity.thirdParty.label : 'N/A'});
                            }
                            if (entity.type == 1) {
                                this.thirdPartyPrimaryCondition = true;
                            }
                        });
                        
                        break;
                }
            });
            this.dataLoading = false;
        },
        async addOrUpdate(obj: any, entityId: number) {
            let name = this.carouselHead.filter((x: any) => x.id == entityId);
            name = name[0].name;
            this.v$[name].$touch();
            let comingObjectId = obj._id;

            if (!this.v$[name].$invalid) {
                if (comingObjectId) {
                    delete obj._id;
                    obj.updatedAt = new Date();
                    await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/update/${comingObjectId}/${entityId}`, obj).then((result: any) => {
                        toast.info('updated', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    });
                } else {
                    obj.createdAt = new Date();
                    if (Object.keys(obj).includes('_id')) {
                        delete obj._id;
                    }
                    if (Object.keys(obj).includes('updatedAt')) {
                        delete obj.updatedAt;
                    }
                    await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/create/${entityId}`, obj).then((result: any) => {
                        toast.info('created', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    });
                }
                this.popUp = false;
                this.tableRow = [];
                this.getAllData(entityId);
            } else {
                toast.error(`cannot save`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async getAllUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
            }
            let activeUsers = this.users.filter((user: any) => {
                return user.isActive == true;
            });
            console.log('users', activeUsers);
            let userRoles = activeUsers.map((item: any) => {
                return item.roles;
            });
            for (let i = 0; i < userRoles.length; i++) {
                if (userRoles[i].length == userRoles.length - 1) userRoles[i].push({ appId: 10003, roleId: 10207 });
            }
            var roles = [];
            roles = userRoles.map((items: any) =>
                items.filter((item: any) => {
                    return item.appId == 10003;
                })
            );
            roles = roles.flat(1);

            roles = roles.map((item: any) => {
                if (item.appId == 10003) {
                    return { role: 'Client Admin' };
                }
            });

            for (let i = 0; i < userRoles.length; i++) {
                let Name = activeUsers[i].name;
                let email = activeUsers[i].email;
                let id = activeUsers[i]._id;
                this.nameList.push({ name: Name, emailAddress: email, nameId: id });
            }
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.systems = result.data;
                this.dummySystems = result.data;
                this.systems = this.systems.map((sys: any) => {
                    return { value: sys._id, label: sys.systemName };
                });
            });
        },
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id: org._id, name: org.organizationName };
                    this.tenants.push(obj);
                    const object = { value: org._id, label: org.organizationName };
                    this.tenantsSelect.push(object);
                });
            });
        },
        async getInformationSets() {
            this.savedInformationSets = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall`).then((result: any) => {
                this.savedInformationSets = result.data.map((info: any) => {
                    return { value: info._id, label: info.information };
                });
            });
        },
        async getAllTeams() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                for (var i = 0; i < res.data.length; i++) {
                    this.departmentList.push(res.data[i].teamName);
                }
            });
            console.log('this.departmentList', this.departmentList);
        },
        // getPermisssions() {
        //     this.permissions = this.userInfo.permissions[0].resources.filter((userPer: any) => {
        //         return userPer.id == this.selectedIndex;
        //     });
        //     this.permissions = this.permissions[0].permissions;
        //     console.log('kjeshdekjdssfe', this.permissions);
        // },
        updateButtonStates() {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    this.canScrollLeft = tabContainer.scrollLeft > 0;
                    this.canScrollRight = tabContainer.scrollLeft + tabContainer.clientWidth < tabContainer.scrollWidth;
                } else {
                    this.canScrollLeft = this.currentGroupIndex > 0;
                    this.canScrollRight = this.currentGroupIndex < this.totalGroups - 1;
                }
            }
        },
        handleResize() {
            this.isSmallScreen = window.innerWidth <= 768;
            if (this.isSmallScreen) {
                this.calculateTabsPerGroup();
                if (this.currentGroupIndex > this.totalGroups - 1) {
                    this.scrollTabs('left');
                }
            } else {
                this.tabsPerGroup = this.calculateTabsForLargeScreen();
                this.updateVisibleTabs();
            }
            this.updateButtonStates();
        },
        calculateTabsPerGroup() {
            const tabContainerWidth = this.$refs.tabContainer.clientWidth;

            let sumWidth = 0;
            let count = 0;
            const tabs = this.carouselHead;

            for (let i = 0; i < tabs.length; i++) {
                const tabWidth = this.getTabWidth(tabs[i]);

                if (sumWidth + tabWidth > tabContainerWidth) {
                    break;
                }

                sumWidth += tabWidth;
                count++;
            }
            this.tabsPerGroup = count;
            this.updateVisibleTabs();
        },
        calculateTabsForLargeScreen() {
            const tabContainerWidth = this.$refs.tabContainer.clientWidth;

            const tabs = this.carouselHead;
            let sumWidth = 0;
            let count = 0;

            for (let i = 0; i < tabs.length; i++) {
                const tabWidth = this.getTabWidth(tabs[i]);

                if (sumWidth + tabWidth > tabContainerWidth) {
                    break;
                }

                sumWidth += tabWidth;
                count++;
            }
            return count;
        },
        getTabWidth(tab: any) {
            // Simulate tab width calculation; replace with your actual logic
            const dummyDiv = document.createElement('div');
            dummyDiv.innerHTML = tab.displayName;
            dummyDiv.style.display = 'inline-block';
            dummyDiv.style.visibility = 'hidden';
            document.body.appendChild(dummyDiv);
            const width = dummyDiv.clientWidth;
            document.body.removeChild(dummyDiv);
            return width + 24; // Adjust padding or margin as needed
        },
        async updateVisibleTabs() {
            if (!this.isSmallScreen) {
                this.visibleTabs = [...this.carouselHead];
            } else {
                const startIdx = this.currentGroupIndex * this.tabsPerGroup;
                const endIdx = startIdx + this.tabsPerGroup;
                this.visibleTabs = this.carouselHead.slice(startIdx, endIdx);
                if (this.visibleTabs) {
                    let savedTabKey: any = sessionStorage.getItem('selectedTab');
                    
                    if (savedTabKey) {
                        savedTabKey = parseInt(savedTabKey)
                        const savedTab = this.visibleTabs.find((tab: any) => tab.id == savedTabKey);
                        console.log("savedTab", savedTab)
                        if (savedTab) {
                            await this.toggleTabs(savedTab);
                        }else{
                            this.selectedTabObj = { displayName: 'Third-party', name: 'thirdParty', id: 20003 },
                            this.selectedIndex = 20003;
                        }
                    }
                    else{
                        this.selectedTabObj = { displayName: 'Third-party', name: 'thirdParty', id: 20003 },
                        this.selectedIndex = 20003;
                        await this.toggleTabs(this.selectedTabObj);
                    }
                }
                this.totalGroups = Math.ceil(this.carouselHead.length / this.tabsPerGroup);
            }
            this.updateButtonStates();
        },
        scrollTabs(direction: any) {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    const scrollAmount = this.calculateScrollAmount(direction);
                    if (direction === 'left') {
                        tabContainer.scrollLeft -= scrollAmount;
                    } else if (direction === 'right') {
                        tabContainer.scrollLeft += scrollAmount;
                    }
                    this.updateButtonStates();
                } else {
                    if (direction === 'left' && this.currentGroupIndex > 0) {
                        this.currentGroupIndex--;
                    } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                        this.currentGroupIndex++;
                    }
                    this.updateVisibleTabs();
                    this.updateButtonStates(); // Update button states after changing currentGroupIndex
                }
            }
        },
        calculateScrollAmount(direction: any) {
            const tabContainer = this.$refs.tabContainer;
            const visibleTabs = this.visibleTabs;
            let sumWidth = 0;
            let scrollAmount = 0;

            if (direction === 'left') {
                for (let i = visibleTabs.length - 1; i >= 0; i--) {
                    const tabWidth = this.getTabWidth(visibleTabs[i]);
                    if (sumWidth + tabWidth > tabContainer.clientWidth) {
                        break;
                    }
                    sumWidth += tabWidth;
                    scrollAmount += tabWidth;
                }
            } else if (direction === 'right') {
                for (let i = 0; i < visibleTabs.length; i++) {
                    const tabWidth = this.getTabWidth(visibleTabs[i]);
                    if (sumWidth + tabWidth > tabContainer.clientWidth) {
                        break;
                    }
                    sumWidth += tabWidth;
                    scrollAmount += tabWidth;
                }
            }
            return scrollAmount;
        },
        scrollGroups(direction: any) {
            if (direction === 'left' && this.currentGroupIndex > 0) {
                this.currentGroupIndex--;
            } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                this.currentGroupIndex++;
            }
            this.updateVisibleTabs();
            this.updateButtonStates(); // Update button states after changing currentGroupIndex
        },
        handleScroll() {
            this.updateButtonStates();
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        sessionStorage.removeItem('selectedTab')
    }
});
</script>
