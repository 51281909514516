
<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">Risks</h1>
            <div class="flex items-center">
                <button class="btnprimary ml-2 flex items-center" @click="showPopupByAdd()">Add</button>
                <button class="ml-2 flex" @click="filterOpen" :class="riskList.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <div class="mt-3 text-xs">
            <div>
            Risk management is the process of identifying, assessing and controlling financial, legal, strategic and security risks to an organization’s capital and earnings. These threats, or risks, could stem from a wide variety of sources, including financial uncertainty, legal liabilities,
            strategic management errors, accidents and natural disasters.To reduce risk, an organization needs to apply resources to minimize, monitor and control the impact of negative events while maximizing positive events. A consistent, systemic and integrated approach to risk management can
            help determine how best to identify, manage and mitigate significant risks.
            </div>
        </div>

        <div>
            <newTable :tableRowData="riskList" :headersData="riskHeaders" :openPopup="false" :navigate="true" :loadData="dataLoading"></newTable>
            <div v-if="toolTipActive">
                <tool-tip :tipText="toolTipText"></tool-tip>
            </div>
        </div>


        <popup v-if="showRisksPopup" :popUp="showRisksPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Risk Master</h1>

                    <button class="float-right text-3xl" @click="onCrossbtn()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>

            <div class="popup-business">
                <div>
                    <div>
                        <div>
                            <div class="statuscontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label class="controllabel">Tier</label>

                                            <select
                                                @change="tierChange(riskThreatData.tier)"
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.tier"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.tier.$error,'inputboxstylingForSelectAtPopup': riskThreatData.tier === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.tier !== ''
                                                }"
                                            >
                                                <option value="" selected disabled hidden>Please select one</option>

                                                <option class="optionColors" v-for="option in tierMapping" :key="option.id" :value="option.id">
                                                    {{ option.value }}
                                                </option>
                                            </select>

                                            <div v-if="v$.riskThreatData.tier.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.tier.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <h1 class="ml-2" v-if="riskThreatData.tier && riskThreatData.tier == 1">Organization</h1>
                                    <h1 class="ml-2" v-else-if="riskThreatData.tier && riskThreatData.tier == 2">Process</h1>
                                    <h1 class="ml-2" v-else-if="riskThreatData.tier && riskThreatData.tier == 3">System</h1>
                                    <div class="border-b mt-3 mb-2 border-solid border-lightgrey"></div> -->

                                    <div class="row flex flex-row w-full upperspacing" v-if="orgTier">
                                        <div class="w-full">
                                            <label class="controllabel">Organization</label>

                                            <multiselect
                                                v-model="riskThreatData.org"
                                                :options="tenantsSelect"
                                                open-direction="bottom"
                                                :multiple="true"
                                                :close-on-select="false"
                                                :hide-selected="true"
                                                :clear-on-select="true"
                                                label="label"
                                                track-by="value"
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="Organisation"
                                                class="custom-multiselect"
                                                :class="{
                                                    'is-invalid red-border': v$.riskThreatData.org.$error,
                                                    'has-options': tenantsSelect && tenantsSelect.length > 0,
                                                    'no-options': !tenantsSelect.length &&  searchValue !== '',
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No Organisations Found</span>
                                                </template>
                                            </multiselect>
                                            <div v-if="v$.riskThreatData.org.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.org.required.$invalid">Required</div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing" v-else-if="processTier">
                                        <div class="w-full">
                                            <label class="controllabel">Process</label>
                                         
                                            <multiselect
                                                v-model="riskThreatData.process"
                                                :options="businessProcessList"
                                                open-direction="bottom"
                                                :multiple="true"
                                                :close-on-select="false"
                                                :hide-selected="true"
                                                :clear-on-select="true"
                                                label="label"
                                                track-by="value"
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="Process"
                                                class="custom-multiselect"
                                                :class="{
                                                    'is-invalid red-border': v$.riskThreatData.process.$error,
                                                    'has-options': businessProcessList && businessProcessList.length > 0,
                                                    'no-options': !businessProcessList.length &&  searchValue !== '',
                                                }"
                                            >
                                            <template v-slot:noResult>
                                                    <span class="text-xs">No Process Found</span>
                                                </template>
                                            </multiselect>
                                            <div v-if="v$.riskThreatData.process.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.process.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing" v-else-if="systemTier">
                                        <div class="w-full">
                                            <label class="controllabel">Systems</label>
                                         
                                            <multiselect
                                                v-model="riskThreatData.systems"
                                                :options="totalSystems"
                                                open-direction="bottom"
                                                :multiple="true"
                                                :close-on-select="false"
                                                :hide-selected="true"
                                                :clear-on-select="true"
                                                label="label"
                                                track-by="value"
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="Systems"
                                                class="custom-multiselect"
                                                :class="{
                                                    'is-invalid red-border': v$.riskThreatData.systems.$error,
                                                    'has-options': totalSystems && totalSystems.length > 0,
                                                    'no-options': !totalSystems.length &&  searchValue !== '',
                                                }"
                                            >
                                            <template v-slot:noResult>
                                                    <span class="text-xs">No Systems Found</span>
                                                </template>
                                            </multiselect>
                                            <div v-if="v$.riskThreatData.systems.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.systems.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Risk</label>

                                            <textarea
                                                class="inputboxstyling"
                                                placeholder="Risk"
                                                @input ="restrictCharacters('Risk')"
                                                v-model.trim="riskThreatData.risk"
                                                :class="{
                                                    'is-invalid': (v$.riskThreatData.risk.$error||displayErrorMessageOfRisk)
                                                }"
                                            />

                                            <div v-if="v$.riskThreatData.risk.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.risk.required.$invalid">Required</div>
                                            </div>
                                            <div v-else-if="displayErrorMessageOfRisk == true">
                                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Risk category , Risk owner div opened -->
                                    <div class="formcontainer flex flex-col upperspacing">
                                        <div class="row flex flex-row w-full">
                                            <div class="w-6/12">
                                                <label class="controllabel">Risk Category</label>
                                                
                                                <multiselect 
                                                    v-model="riskThreatData.riskCategory"
                                                    :options="riskCategoryList" 
                                                    :searchable="true" 
                                                    :close-on-select="true" 
                                                    :show-labels="false"
                                                    :hide-selected="true"
                                                    placeholder="Risk Category"
                                                    open-direction="bottom"
                                                    class="custom-multiselect"
                                                    :class="{
                                                        'is-invalid red-border': v$.riskThreatData.riskCategory.$error,
                                                        'has-options': riskCategoryList && riskCategoryList.length > 0,
                                                        'no-options':  !riskCategoryList.length,
                                                    }"
                                                >
                                                <template v-slot:noResult>
                                                        <span class="text-xs">No Categories Found</span>
                                                    </template>
                                                </multiselect>
                                                <div v-if="v$.riskThreatData.riskCategory.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.riskCategory.required.$invalid">Required</div>
                                                </div>
                                            </div>

                                            <div class="w-3/6 leftspacing">
                                                <label class="controllabel">Risk Owner</label>
                                                <multiselect
                                                    v-model="riskThreatData.riskOwner"
                                                    :options="userOptions"
                                                    :showNoOptions="false"
                                                    @search-change="asyncFind"
                                                    :limit-text="limitText"
                                                    :multiple="false"
                                                    :hide-selected="true"
                                                    :close-on-select="true"
                                                    :clear-on-select="true"
                                                    open-direction="bottom"
                                                    label="label"
                                                    track-by="value"    
                                                    :searchable="true"
                                                    :hideArrow="false"
                                                    placeholder="Risk Owner"
                                                    class="custom-multiselect userSelect"
                                                    :class="{
                                                        'is-invalid redBorder': v$.riskThreatData.riskOwner.$error,
                                                        'has-options': userOptions && userOptions.length > 0,
                                                        'no-options': !userOptions.length &&  searchValue !== '',
                                                    }"
                                                >
                                                    <template v-slot:noResult>
                                                        <span class="text-xs">No Members Found</span>
                                                    </template>
                                                </multiselect>
                                                <div v-if="v$.riskThreatData.riskOwner.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.riskOwner.required.$invalid">Required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row flex mb-3 flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Threat</label>

                                            <textarea
                                                class="inputboxstyling"
                                                @input ="restrictCharacters('Threat')"
                                                placeholder="Threat"
                                                v-model.trim="riskThreatData.threatEvent"
                                                :class="{
                                                    'is-invalid': (v$.riskThreatData.threatEvent.$error||displayErrorMessageOfThreat)
                                                }"
                                            />

                                            <div v-if="v$.riskThreatData.threatEvent.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.threatEvent.required.$invalid">Required</div>
                                            </div>
                                            <div v-else-if="displayErrorMessageOfThreat == true">
                                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Threat source , Threat Category opened -->
                                    <div class="formcontainer flex flex-col upperspacing">
                                        <div class="row flex flex-row w-full">
                                            <div class="w-6/12">
                                                <label class="controllabel">Threat Source</label>
                                                
                                                <multiselect 
                                                    v-model="riskThreatData.threatSource"
                                                    :options="threatSourceList" 
                                                    :searchable="true" 
                                                    :close-on-select="true" 
                                                    :show-labels="false"
                                                    :hide-selected="true"
                                                    placeholder="Threat Source"
                                                    open-direction="bottom"
                                                    class="custom-multiselect"
                                                    :class="{
                                                        'is-invalid red-border': v$.riskThreatData.threatSource.$error,
                                                        'has-options': threatSourceList && threatSourceList.length > 0,
                                                        'no-options':  !threatSourceList.length,
                                                    }"
                                                >
                                                <template v-slot:noResult>
                                                        <span class="text-xs">No Source Found</span>
                                                    </template>
                                                </multiselect>
                                                <div v-if="v$.riskThreatData.threatSource.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.threatSource.required.$invalid">Required</div>
                                                </div>
                                            </div>

                                            <div class="w-6/12 leftspacing">
                                                <label class="controllabel">Threat Category</label>
                                               
                                                <multiselect 
                                                    v-model="riskThreatData.threatCategory"
                                                    :options="threatCategoryList" 
                                                    :searchable="true" 
                                                    :close-on-select="true" 
                                                    :show-labels="false"
                                                    :hide-selected="true"
                                                    placeholder="Threat Category"
                                                    open-direction="bottom"
                                                    class="custom-multiselect"
                                                    :class="{
                                                        'is-invalid red-border': v$.riskThreatData.threatCategory.$error,
                                                        'has-options': threatCategoryList && threatCategoryList.length > 0,
                                                        'no-options':  !threatCategoryList.length,
                                                    }"
                                                >
                                                    <template v-slot:noResult>
                                                        <span class="text-xs">No Categories Found</span>
                                                    </template>
                                                </multiselect>
                                                <div v-if="v$.riskThreatData.threatCategory.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.threatCategory.required.$invalid">Required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Vulnerability</label>

                                            <textarea
                                                class="inputboxstyling"
                                                placeholder="Vulnerability"
                                                v-model.trim="riskThreatData.vulnerability"
                                                @input ="restrictCharacters('Vulnerability')"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.vulnerability.$error||displayErrorMessageOfVulnerability
                                                }"
                                            />

                                            <div v-if="v$.riskThreatData.vulnerability.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.vulnerability.required.$invalid">Required</div>
                                            </div>
                                            <div v-else-if="displayErrorMessageOfVulnerability == true">
                                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                            </div>
                                        </div>
                                    </div>

                                    <h1 class="upperspacing">Impact Analysis</h1>
                                    <div class="border-b pt-1 border-solid border-lightgrey"></div>

                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="col-md-6 w-6/12">
                                            <label class="controllabel">Strategic</label>

                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.business"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.business.$error,'inputboxstylingForSelectAtPopup': riskThreatData.business === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.business !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected disabled hidden>Please select one</option>

                                                <option class="optionColors" v-for="option in dbBussiness" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                            </select>

                                            <div v-if="v$.riskThreatData.business.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.business.required.$invalid">Required</div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 w-6/12 leftspacing">
                                            <label class="controllabel">Financial</label>

                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.financial"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.financial.$error,'inputboxstylingForSelectAtPopup': riskThreatData.financial === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.financial !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected disabled hidden>Please select one</option>

                                                <option class="optionColors" v-for="option in dbFinancial" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                            </select>

                                            <div v-if="v$.riskThreatData.financial.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.financial.required.$invalid">Required</div>
                                            </div>
                                        </div>

                                        
                                    </div>

                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="col-md-6 w-6/12">
                                            <label class="controllabel">Operations</label>

                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.operations"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.operations.$error,'inputboxstylingForSelectAtPopup': riskThreatData.operations === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.operations !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected disabled hidden>Please select one</option>

                                                <option class="optionColors" v-for="option in dbOperations" :key="option" :value="option.value">
                                                    {{ option.key }}
                                                </option>
                                            </select>

                                            <div v-if="v$.riskThreatData.operations.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.operations.required.$invalid">Required</div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 w-6/12 leftspacing">
                                            <label class="controllabel">Compliance</label>

                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.legal"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.legal.$error,'inputboxstylingForSelectAtPopup': riskThreatData.legal === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.legal !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected disabled hidden>Please select one</option>

                                                <option class="optionColors" v-for="option in dbLegal" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                            </select>

                                            <div v-if="v$.riskThreatData.legal.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.legal.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>

                                    

                                    <h1 class="upperspacing">Risk Analysis</h1>
                                    <div class="border-b pt-1 border-solid border-lightgrey"></div>

                                    <div class="row flex flex-row w-full upperspacing">
                                        
                                            <div class="w-6/12">
                                            <label class="controllabel">Likelihood</label>

                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.likelihood"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.likelihood.$error,'inputboxstylingForSelectAtPopup': riskThreatData.likelihood === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.likelihood !== ''
                                                }"
                                            >
                                                <option value="" selected disabled hidden>Please select one</option>

                                                <option class="optionColors" v-for="option in dbLikelihood" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                            </select>

                                            <div v-if="v$.riskThreatData.likelihood.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.likelihood.required.$invalid">Required</div>
                                            </div>
                                            </div>
                                            <div class="w-6/12 leftspacing">
                                                <label class="controllabel">Overall Impact</label>
                                                <select class="inputboxstyling" disabled v-model="riskThreatData.overallImpact">
                                                    <option value="" selected hidden>Please select one</option>
                                                    <option v-for="i in dbOverAllImpact" :value="i.value" :key="i">
                                                        {{ i.key }}
                                                    </option>
                                                </select>
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttonposition flex justify-center items-center mb-4 mx-0">
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn()">Cancel</button>
                        <button :disabled="clickOnButton" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdatethreat(riskThreatData)">Save</button>
                    </div>
                </div>
            </div>
        </popup>
      
    </div>
</template>
<style scoped>
.boredrRed{
    border: 1px solid red !important;
}
.smallHead {
    width: 180px !important;
}
.red-border {
    border: 1px solid red !important;
}
.positiontooltip {
    font-size: 12px;
}
.tip {
    display: inline;
}
.dropdown {
    position: absolute;
    background-color: white !important;
    color: black;
}
.accept {
    width: 100px;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { toast } from '@/main';
import popup from '@/components/popup.vue';
import store from '@/store';
import { emitter } from '@/main';
import moment from 'moment';
import { omit } from 'lodash';

// import dropdown from '@/components/dropdown.vue';
import { mapGetters } from 'vuex';
import newTable from '@/components/newTable.vue';
import Multiselect from 'vue-multiselect';

import toolTip from '@/components/toolTip.vue';

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            dummydata: [],
            filterDummy: {
                severity: '',
                statusCode: ''
            },
            selectedSystemsForReset: [],
            selected: '',
            selectedRowIndex: -1,
            showBlock: false,
            scalesInfo:[],

            showRisksPopup: false,
            systemTier: false,
            processTier: false,
            orgTier: false,
            showDropdown: false,
            searchValue: '',
            selectedCategory: [],
            riskList: [],
            systemAssesmentList: [],
            showFilters: false,
            mainRisk: {},
            riskHeaders: ['severity', 'risk', 'riskCategory', 'tier','priority', 'systemCount', 'actionButton',],
            riskThreatData: {
                risk: '',
                threatCategory: '',
                threatSource: '',
                threatEvent: '',
                vulnerability: '',
                tier: '',
                business: '',
                operations: '',
                legal: '',
                financial: '',
                likelihood: '',
                riskCategory: '',
                riskOwner: '',
                overallImpact:''
            },

            riskCategoryList: [],
            threatSourceList: [],
            threatCategoryList: [],

            optionsList: [],
            allRisksArray: [],
            likelihoodMapping: [],
            tierMapping: [
                {
                    id: 1,
                    value: 'Organization'
                },
                {
                    id: 2,
                    value: 'Process'
                },
                {
                    id: 3,
                    value: 'System'
                }
            ],

            risksValue: [
                {
                    id: 10401,
                    value: 'Open'
                },
                {
                    id: 10402,
                    value: 'In Progress'
                },
                {
                    id: 10403,
                    value: 'Accepted'
                },
                {
                    id: 10405,
                    value: 'Mitigated'
                },
                {
                    id: 10406,
                    value: 'Partial'
                }
            ],

            optionsUserArray: [],
            orgUsersList: [],
            permissions: [],
            totalSystems: [],
            businessProcessList: [],
            tenantsSelect: [],
            systems: [],
            riskAcceptanceObj: [],
            mappedSystems: [],
            mappedSystemsOptions: [],
            presentMappedRiskId: '',
            comments: '',
            showPriorityPopUp:false,
            riskPriorityShow:{},
            priorityDummy : {},
            userOptions:[],

            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,
			displayErrorMessageOfRisk : false,
			displayErrorMessageOfThreat : false,
			displayErrorMessageOfVulnerability : false,
            clickOnButton: false,
        };
    },
    components: {
        popup,
        toolTip,
        Multiselect,
        newTable
    },

    computed: {
        ...mapGetters({userInfo:'userInfo',dbSeverity:'dbSeverity',dbLikelihood:'dbLikelihood',dbBussiness:'dbBussiness',
            dbOrganization:'dbOrganization',dbOperations:'dbOperations',dbLegal:'dbLegal',
            dbFinancial:'dbFinancial',dbTechnical:'dbTechnical',dbPriority: 'dbPriority',dbOverAllImpact: 'dbOverAllImpact'
        }),
        disableStatus: function (): any {
            let result;
            if (this.showRisksPopup) {
                for (const [key, value] of Object.entries(this.riskThreatData)) {
                    if (value != '') {
                        result = true;
                    }
                }
            }
            return result;
        }
    },
    validations() {
        return {
            riskThreatData: {
                risk: { required },
                riskCategory: { required },
                threatSource: { required },
                threatCategory: { required },
                threatEvent: { required },
                vulnerability: { required },
                tier: { required },
                business: { required },
                operations: { required },
                legal: { required },
                financial: { required },
                likelihood: { required },
                riskOwner: { required },
                systems: {
                    required: requiredIf(() => {
                        return this.riskThreatData.tier == 3;
                    })
                },
                org: {
                    required: requiredIf(() => {
                        return this.riskThreatData.tier == 1;
                    })
                },
                process: {
                    required: requiredIf(() => {
                        return this.riskThreatData.tier == 2;
                    })
                }
            },
            riskPriorityShow:{
                risk:{required},
                priority: { required:requiredIf(()=>{
                    return this.priorityDummy.priority===this.riskPriorityShow.priority;
                })},
                justification:{required}
            }
        };
    },
    methods: {
         async calculateOverallImpact(){
            let a1 = this.riskThreatData.business == '' ? 0 : this.riskThreatData.business;
            let a2 = this.riskThreatData.operations == '' ? 0 : this.riskThreatData.operations;
            let a3 = this.riskThreatData.legal == '' ? 0 : this.riskThreatData.legal;
            let a4 = this.riskThreatData.financial == '' ? 0 : this.riskThreatData.financial;

            let arr = [a1, a2, a3, a4]

            let avg =  arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
            this.riskThreatData.overallImpact = Math.max(Math.round(avg), 1);
        },
        limitText (count:any) {
			// return `and ${count} other countries`
			console.log('countcount',count)
		},
		asyncFind(query:any){
            this.searchValue = query
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        filteredCategories(filterColumn: any, status: any) {
            this.selectedCategory = [
                ...new Set(
                    Object.values(this.optionsList).map((x: any) => {
                        x[status];

                        return x[status];
                    })
                )
            ].filter((category: any) => {
                return category.toLowerCase().includes(filterColumn.toLowerCase());
            });
        },
        tierChange(tier: any) {
            if (tier == 1) {
                this.orgTier = true;
                this.processTier = false;
                this.systemTier = false;
            } else if (tier == 2) {
                this.processTier = true;
                this.systemTier = false;
                this.orgTier = false;
            } else if (tier == 3) {
                this.systemTier = true;
                this.processTier = false;
                this.orgTier = false;
            }
            this.riskThreatData.org = []
            this.riskThreatData.process = []
            this.riskThreatData.systems = []
        },
        getName(options: any, status: any) {
            let OptionWords = status.charAt(0).toLowerCase() + status.replace(/\s+/g, '').substring(1);
            this.filteredCategories(options, OptionWords);
            this.setState(options, OptionWords);
        },
        setState(options: any, status: any) {
            this.riskThreatData[status] = options;
            this.autoSuggestion = false;
        },
        cancelInReset() {
            this.comments = '';
            if (this.selectedSystemsForReset.length != this.mappedSystemsOptions.length) {
                this.selectedSystemsForReset = [];
                this.mappedSystemsOptions.map((obj: any) => this.selectedSystemsForReset.push(obj.value));
            }
        },
        onCancelbtn() {
            for (const key in this.riskThreatData) {
                if (key == 'systems') {
                    this.riskThreatData[key] = [];
                } else {
                    this.riskThreatData[key] = '';
                }
            }
            this.displayErrorMessageOfRisk = false
            this.displayErrorMessageOfThreat = false
            this.displayErrorMessageOfVulnerability = false
            this.orgTier = false;
            this.processTier = false;
            this.systemTier = false;
            this.v$.$reset();
        },

        restrictCharacters(code: any){
            if(code === 'Threat'){
                if(this.riskThreatData.threatEvent.length > 300){
                this.displayErrorMessageOfThreat = true;
                }else if(this.riskThreatData.threatEvent.length < 300 || this.riskThreatData.threatEvent.length == 300){
                    this.displayErrorMessageOfThreat = false;
                }
            } else if(code === 'Risk'){
                if(this.riskThreatData.risk.length > 300){
                    this.displayErrorMessageOfRisk = true;
                }else if(this.riskThreatData.risk.length < 300 || this.riskThreatData.risk.length == 300){
                    this.displayErrorMessageOfRisk = false;
                }
            } else if(code === 'Vulnerability'){
                if(this.riskThreatData.vulnerability.length > 300){
                    this.displayErrorMessageOfVulnerability = true;
                }else if(this.riskThreatData.vulnerability.length < 300 || this.riskThreatData.vulnerability.length == 300){
                    this.displayErrorMessageOfVulnerability = false;
                }
            }
            
        },
        async addOrUpdatethreat(riskThreatdata: any) {
            this.v$.riskThreatData.$touch();
            if (!this.v$.riskThreatData.$invalid && this.displayErrorMessageOfRisk == false && this.displayErrorMessageOfThreat == false && this.displayErrorMessageOfVulnerability == false) {
                this.clickOnButton = true;
                console.log("validation done but not saving still");
                await this.addRiskList(riskThreatdata);
                this.v$.$reset();
            } else {
                console.log("error");
                // toast.error(`error`, {
                //     timeout: 1000,
                //     closeOnClick: true,
                //     closeButton: 'button',
                //     icon: true
                // });
            }
        },
        async getTotalRisksList() {
            this.optionsList = [];
            this.riskCategoryList = [];
            this.threatCategoryList = [];
            this.threatSourceList = [];
            const result = await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/risks/getall`);
            result.data.forEach((risk: any) => {
                risk.entityId = 20008;
                !this.riskCategoryList.includes(risk.riskCategory) ? this.riskCategoryList.push(risk.riskCategory) : '';

                !this.threatCategoryList.includes(risk.threatCategory) ? this.threatCategoryList.push(risk.threatCategory) : '';

                !this.threatSourceList.includes(risk.threatSource) ? this.threatSourceList.push(risk.threatSource) : '';
                if (risk.organization) {
                    let averageImpact = Math.round(this.average([risk.business, risk.operations, risk.legal, risk.financial]));
                    risk.overallImpact = averageImpact;
                    risk.severity = this.riskSeverity(risk.overallImpact * risk.likelihood);
                    this.optionsList.push(risk);
                }
            });
        },
        async getBusinessProcess() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                result.data.map((entity: any) => {
                    this.businessProcessList.push({ value: entity._id, label: entity.businessProcessName });
                });
            });
        },
        async getBusinessEntities(){
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                result.data.map((org: any) => {
                    this.tenantsSelect.push({ value: org._id, label: org.entityName });
                });
            })
        },
        async addRiskList(riskThreatdata: any) {
            console.log('After finalArray1')
            let copyOfriskThreatData:any = {...riskThreatdata}
            if(copyOfriskThreatData.tier == 1){
                delete copyOfriskThreatData.process;
                delete copyOfriskThreatData.systems
                copyOfriskThreatData.org.map((object:any,index:any)=>{
                    copyOfriskThreatData.org[index] = object.value
                })
            } else if(copyOfriskThreatData.tier == 2){
                console.log('After finalArray1')
                delete copyOfriskThreatData.org;
                delete copyOfriskThreatData.systems
                copyOfriskThreatData.process.map((object:any,index:any)=>{
                    copyOfriskThreatData.process[index] = object.value
                })
            } else if(copyOfriskThreatData.tier == 3){
                delete copyOfriskThreatData.process;
                delete copyOfriskThreatData.org
                copyOfriskThreatData.systems.map((object:any,index:any)=>{
                    copyOfriskThreatData.systems[index] = object.value
                })
            }

            copyOfriskThreatData.riskOwner = copyOfriskThreatData.riskOwner.value

            let finalArray: Record<string, any> = [];
            finalArray.push(copyOfriskThreatData);
            finalArray.map((obj: any) => {
                obj.statusCode = 10401;
                let now = moment(obj.createdAt);
                obj.createdBy = this.userInfo._id;
                obj.createdAt = now.format('DD-MM-YYYY, HH:mm:ss');
            });

            console.log('After finalArray',finalArray)

            // let saveObj = this.optionsList.find(
            //     (eachObj: any) => (eachObj.tier && eachObj.tier == riskThreatdata.tier && riskThreatdata.risk.toLowerCase() === eachObj.risk.toLowerCase()) || (riskThreatdata.riskCategory.toLowerCase() === eachObj.riskCategory.toLowerCase() && eachObj.tier && eachObj.tier == riskThreatdata.tier)
            // );
            // if (saveObj) {
            //     toast.error('Risk and Risk Category should be same with table values', {
            //         timeout: 5000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // } else {

            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/create?org==true`, finalArray).then(async (result: any) => {
                    // toast.info(`Uploaded succesfully`, {
                    //     closeOnClick: true,
                    //     closeButton: 'button'
                    // });

                    let riskId = result.data.insertedIds[0];
                    let payload: any = [];

                    if (copyOfriskThreatData.org != undefined) {
                        copyOfriskThreatData.org.map((orgId: any) => {
                            let newObj = { orgId: '', riskId: [`${riskId}`], statusCode: 10401 };
                            newObj.orgId = orgId;
                            payload.push(newObj);
                            payload[0].tier = 1;
                        });
                    } else if (copyOfriskThreatData.process != undefined) {
                        copyOfriskThreatData.process.map((processId: any) => {
                            let newObj = { processId: '', riskId: [`${riskId}`], statusCode: 10401 };
                            newObj.processId = processId;
                            payload.push(newObj);
                            payload[0].tier = 2;
                        });
                    } else if (copyOfriskThreatData.systems != undefined) {
                        copyOfriskThreatData.systems.map((systemId: any) => {
                            let newObj = { systemId: '', riskId: [`${riskId}`], statusCode: 10401 };
                            newObj.systemId = systemId;
                            payload.push(newObj);
                            payload[0].tier = 3;
                        });
                    }

                    // console.log("payload", payload)
                    await this.createRiskForOrg(payload);
                });
                this.showRisksPopup = false;
                await this.getRisksList();
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }finally{
                this.clickOnButton = false
            }
            this.showRisksPopup = false;
            await this.getRisksList();

            // }
        },
        async createRiskForOrg(payload: any) {
            console.log("payload", payload)
            payload.forEach((eachObj:any)=>{
                eachObj.tier = payload[0].tier;
                eachObj.createdAt = new Date();
                eachObj.createdBy = this.userInfo._id;
            })
            
            try {
                payload.createdAt = moment().format('DD-MM-YYYY, h:mm:ss a');
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/create/risks`, payload).then((result: any) => {
                    console.log("payloadInThePostMethod",payload)
                    console.log("result",result)
                    this.systemAssesmentList = result.data;
                    console.log("this.systemAssesmentList",this.systemAssesmentList);
                    console.log("result.data",result.data);
                    toast.info('created', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.systems = [...result.data];
                this.totalSystems = result.data.map((sys: any) => {
                    return { value: sys._id, label: sys.systemName };
                });
            });
        },

        async getMappedSystemsForRisk(mappedRisk: any) {
            this.presentMappedRiskId = mappedRisk;
            this.mappedSystemsOptions = [];
            this.riskAcceptanceObj = [];
            this.mappedSystems = [];
            this.selectedSystemsForReset = [];

            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risk/${mappedRisk}/systems`).then((result: any) => {
                this.riskAcceptanceObj = result.data;
                this.riskAcceptanceObj.filter((risk: any) => {
                    return risk.riskId == mappedRisk;
                });
                for (let i = 0; i < this.riskAcceptanceObj.length; i++) {
                    this.riskAcceptanceObj[i].systemName = this.systems.find((obj: any) => this.riskAcceptanceObj[i].systemId == obj._id).systemName;

                    this.systems.map((obj: any) => {
                        if (this.riskAcceptanceObj[i].systemId == obj._id) {
                            this.mappedSystems.push(obj);
                        }
                    });
                }

                this.mappedSystems.forEach((item: any) => {
                    let a = { value: item._id, label: item.systemName };
                    this.mappedSystemsOptions.push(a);
                    this.selectedSystemsForReset.push(a.value);
                });
            });
        },
        showPopupByAdd() {
            this.showRisksPopup = true;
            for (const key in this.riskThreatData) {
                if (key == 'systems') {
                    this.riskThreatData[key] = [];
                } else {
                    this.riskThreatData[key] = '';
                }
            }
        },
        changeRoute(risk: any, route: any) {
            this.mainRisk = risk;
            console.log('sandeiudn',risk)

            if (route == '/riskAcceptance') {
                let filteredRisksForMappedRisk = this.allRisksArray.filter((obj: any) => {
                    return obj.mappedRisk == this.mainRisk.mappedRisk;
                });

                let conditionForAccept = filteredRisksForMappedRisk.some((object: any) => {
                    return object.statusCode == 10401;
                });
                if (conditionForAccept) {
                    this.$router.push({ name: 'riskAcceptance', params: { riskId: risk.mappedRisk }, query:{Risks:true, tier:risk.tier} });
                } else {
                    toast.error(`Cannot Accept`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
            } else if (route == '/mitigateRisk') {
                let filteredRisksForMappedRisk = this.allRisksArray.filter((obj: any) => {
                    return obj.mappedRisk == this.mainRisk.mappedRisk;
                });

                let conditionForMitigate = filteredRisksForMappedRisk.some((object: any) => {
                    return object.statusCode == 10401;
                });
                if (conditionForMitigate) {
                    this.$router.push({ name: 'riskMitigate', params: { riskId: risk.mappedRisk }, query:{Risks:true, tier:risk.tier} });
                } else {
                    toast.error(`Cannot Mitigate`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
            } else if (route == '/avoid') {
                let filteredRisksForMappedRisk = this.allRisksArray.filter((obj: any) => {
                    return obj.mappedRisk == this.mainRisk.mappedRisk;
                });

                let conditionForAvoid = filteredRisksForMappedRisk.some((object: any) => {
                    return object.statusCode == 10401;
                });
                if (conditionForAvoid) {
                    this.$router.push({ name: 'riskAvoid', params: { riskId: risk.mappedRisk }, query:{Risks:true, tier:risk.tier} });
                } else {
                    toast.error(`Cannot Avoid`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
            } else if (route == '/transfer') {
                let filteredRisksForMappedRisk = this.allRisksArray.filter((obj: any) => {
                    return obj.mappedRisk == this.mainRisk.mappedRisk;
                });

                let conditionForTransfer = filteredRisksForMappedRisk.some((object: any) => {
                    return object.statusCode == 10401;
                });
                if (conditionForTransfer) {
                    this.$router.push({ name: 'riskTransfer', params: { riskId: risk.mappedRisk }, query:{Risks:true, tier:risk.tier} });
                } else {
                    toast.error(`Cannot Avoid`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
            } else if (route == '/reset') {
                let filteredRisksForMappedRisk = this.allRisksArray.filter((obj: any) => {
                    return obj.mappedRisk == this.mainRisk.mappedRisk;
                });

                let conditionForReset = filteredRisksForMappedRisk.some((object: any) => {
                    return object.statusCode != 10401;
                });
                if (conditionForReset) {
                    this.$router.push({ name: 'riskReset', params: { riskId: risk.mappedRisk }, query:{Risks:true, tier:risk.tier} });
                } else {
                    toast.error(`Cannot Reset`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
            } else if (route == '/deactive') {
                let filteredRisksForMappedRisk = this.allRisksArray.filter((obj: any) => {
                    return obj.mappedRisk == this.mainRisk.mappedRisk;
                });
                this.$router.push({ name: 'riskDelete', params: { riskId: risk.mappedRisk }, query:{Risks:true, tier:risk.tier}  });
            }else if(route == '/clone'){
                let tierType:any
                if(risk.tier == 1){
                    tierType = 'organization'
                }else if(risk.tier == 2){
                    tierType = 'process'
                }else if(risk.tier == 3){
                    tierType = 'systems'
                }

                this.$router.push({ name:'cloner',params:{ mappedRisk: risk.mappedRisk, riskId:risk._id, type: tierType },query:{ Risks:true, tier:risk.tier } })
            } 
            else if(route=='/priority'){
                console.log("allrisksarray",this.allRisksArray);
                
                  let filteredRisksForMappedRisk = this.allRisksArray.filter((obj: any) => {
                    return obj.mappedRisk == this.mainRisk.mappedRisk;
                }); 
                console.log("priority risk:",filteredRisksForMappedRisk);
                 let conditionForPriority = filteredRisksForMappedRisk.some((object: any) => {
                    return object.statusCode == 10401;
                });
                // if (conditionForPriority) {
                    this.$router.push({ name: 'riskPriority', params: { riskId: risk.mappedRisk }, query:{Risks:true, tier:risk.tier} });
                // } else {
                //     toast.error(`Cannot Priortize`, {
                //         timeout: 1000,
                //         closeOnClick: true,
                //         closeButton: 'button',
                //         icon: true
                //     });
                // }
                

            }
            
            
            else {
                toast.error(`No Result`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        onCrossbtn() {
            this.showRisksPopup = false;
            Object.keys(this.riskThreatData).forEach((prop) => {
                Array.isArray(this.riskThreatData[prop]) ? (this.riskThreatData[prop] = []) : (this.riskThreatData[prop] = '');
            });
            this.displayErrorMessageOfRisk = false
            this.displayErrorMessageOfThreat = false
            this.displayErrorMessageOfVulnerability = false
            this.orgTier = false;
            this.processTier = false;
            this.systemTier = false;
            this.selectedSystemsForReset = [];
            this.v$.$reset();
        },
        async getRisksList() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risks/getall?active=true`).then((result: any) => {
                const statusCodes: any = {
                    10401: 'Not started / Open',
                    10402: 'InProgress/waitforapparoval',
                    10403: 'Completed/closed/Accepted',
                    10404: 'Overdue/inactive',
                    10405: 'Mitigated',
                    10406: 'Partial'
                };

                const uniqueMappedRisks: any = {};
                this.allRisksArray = [];

                // const uniqueGroupBy: {
                //     [key: string]: Array<{
                //         statusCode: number;
                //         mappedRisk: string;
                //     }>;
                // } = {};

                // result.data.forEach((item: any) => {
                //     const { mappedRisk, ...rest } = item;
                //     if (!uniqueGroupBy[mappedRisk]) {
                //         uniqueGroupBy[mappedRisk] = [{ mappedRisk, ...rest }];
                //     } else {
                //         uniqueGroupBy[mappedRisk].push({ mappedRisk, ...rest });
                //     }
                // });
                const uniqueGroupBy: {
                    [key: string]: Array<{
                        statusCode: number;
                        mappedRisk: string;
                        tier: number;
                    }>;
                } = {};

                result.data.forEach((item: any) => {
                    const { mappedRisk, tier, ...rest } = item;
                    // Create a combined key using mappedRisk and tier
                    const key = `${mappedRisk}_${tier}`;
                    
                    if (!uniqueGroupBy[key]) {
                        uniqueGroupBy[key] = [{ mappedRisk, tier, ...rest }];
                    } else {
                        uniqueGroupBy[key].push({ mappedRisk, tier, ...rest });
                    }
                });


                let finalArray: any = [];
                console.log('uniqueGroupByuniqueGroupBy',uniqueGroupBy)
                
                for (let item of Object.values(uniqueGroupBy)) {
                    const statusCodes = item.map((obj: any) => obj.statusCode);
                    const areAllEqual = statusCodes.every((code, index, arr) => code === arr[0] && code != 10402);
                    const inProgress = statusCodes.some((code: any) => code == 10402);
                    item.map((obj: any) => this.allRisksArray.push({ ...obj }));
                    
                    let HighSevObject:any
                    if(item.length == 1){
                        HighSevObject = item[0]
                    }else{
                        let sevValues:any = []
                        item.map((obj:any)=>{
                            this.openList = obj;
                            let averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                            let impact = Math.round(averageImpact);
                            let severity = this.riskSeverity(impact * this.openList['likelihood']);
                            sevValues.push(severity)
                        })

                        let maxSeverity = Math.max(...sevValues);
                        let maxIndex = sevValues.indexOf(maxSeverity);
                        HighSevObject = item[maxIndex]
                    }

                    if (areAllEqual) {
                        finalArray.push(HighSevObject);
                    } else {
                        HighSevObject.statusCode = 10401;
                        finalArray.push(HighSevObject);
                    }
                }

                this.riskList = [...finalArray];

                for (let i = 0; i < this.riskList.length; i++) {
                    this.openList = this.riskList[i];
                    let averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                    let impact = Math.round(averageImpact);
                    let severity = this.riskSeverity(impact * this.openList['likelihood']);
                    this.riskList[i].impact = impact;
                    this.riskList[i].severity = severity;
                    this.riskList[i].entityId = 20008;
                    this.optionsList.push(this.riskList[i]);

                    let filterSystems = this.allRisksArray.filter((obj: any) => {
                        return this.riskList[i].mappedRisk == obj.mappedRisk && this.riskList[i].tier == obj.tier;
                    });
                    this.riskList[i].systemCount = filterSystems.length;
                }
                // this.$store.dispatch('updateDotsLoading', false);
            });
        },
          riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        async getallTeamMembers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.orgUsersList = res.data;
                this.orgUsersList = this.orgUsersList.filter((user: any) => {
                    return user.isActive == true;
                });
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                }
            });
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.riskList;
            } else {
                this.riskList = this.dummyList;
                for (const key in this.filterDummy) {
                    this.filterDummy[key] = '';
                }
            }
            emitter.emit('visible', this.showFilters);
        },
        average(arr: any[]) {
            return arr.reduce((p, c) => p + c, 0) / arr.length;
        },
        showPriorityPopUpAction(data: any) {
            this.mainRisk = {...data};

            this.showPriorityPopUp = true;

            this.riskPriorityShow.risk=data.risk;
            this.riskPriorityShow.priority=data.priority;
            this.priorityDummy.risk=data.risk;
            this.priorityDummy.priority=data.priority;
            this.riskPriorityShow.justification='';
            this.priorityDummy.justification='';
        },

        closePriorityPopup() {
            this.resetRiskPriority();
            this.showPriorityPopUp = false;
        },

        cancelPriorityBtn() {
            // this.riskPriorityShow._id=this.priorityDummy;
            this.riskPriorityShow.risk=this.priorityDummy.risk;
            this.riskPriorityShow.priority=this.priorityDummy.priority;
            this.riskPriorityShow.justification='';
            this.priorityDummy.justification='';
            this.v$.$reset();
            console.log("cancel clicked");
        },

        async addOrUpdatePriority() {
            this.v$.$touch();
            if (!this.v$.riskPriorityShow.$invalid) {
                let payload:any = {};

                let filterTierRisks = this.allRisksArray.filter((obj: any) => {
                    return obj.mappedRisk == this.mainRisk.mappedRisk;
                });

                let filterIds:any = [];
                if(this.mainRisk.tier == 1){
                    filterTierRisks.map((obj:any)=>filterIds.push(obj.orgId))
                    payload.org = filterIds
                }else if(this.mainRisk.tier == 2){
                    filterTierRisks.map((obj:any)=>filterIds.push(obj.processId))
                    payload.process = filterIds
                }else if(this.mainRisk.tier == 3){
                    filterTierRisks.map((obj:any)=>filterIds.push(obj.systemId))
                    payload.systems = filterIds
                }

                payload.priority = this.riskPriorityShow.priority;
                payload.priorityComments = this.riskPriorityShow.comments

                await this.updateRiskPriority(payload,this.mainRisk.mappedRisk)

                this.v$.$reset();
                this.showPriorityPopUp = false;
            }else {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }

        },
        async updateRiskPriority(payload:any,mappedRiskId:any){
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/priority/${mappedRiskId}/modify`, payload).then((result: any) => {
                    toast.info(`Updated succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
            await this.getRisksList();
        },

        resetRiskPriority() {
            this.riskPriorityShow.risk = '';
            this.riskPriorityShow.priority = null;
            this.riskPriorityShow.justification='';
            // this.riskPriorityShow._id=null;
            this.priorityDummy.risk = '';
            this.priorityDummy.priority = null;
            this.priorityDummy.justification='';
            // this.priorityDummy._id=null;
            this.v$.$reset();
        },
    },
    async mounted() {
        this.dataLoading = true;
        
        await this.getSystems();
        await this.getBusinessProcess();
        await this.getBusinessEntities();
        await this.getallTeamMembers();

        await this.getTotalRisksList();
        await this.getRisksList();

        this.dataLoading = false;

        this.scalesInfo = store.getters.scalesInfo;
        if (this.scalesInfo.length == 0) {
            await store.dispatch('getScalesInfo').then(async (res: any) => {
                this.scalesInfo = store.getters.scalesInfo;
                this.likelihoodMapping = this.scalesInfo.filter((scale: any) => {
                    return scale.entity == 'Likelihood';
                });
            });
        } else {
            this.scalesInfo = store.getters.scalesInfo;
            this.likelihoodMapping = this.scalesInfo.filter((scale: any) => {
                return scale.entity == 'Likelihood';
            });
        }

        emitter.on('*', (type, booleanValue) => {
            if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
                this.showBlock == true ? (this.showBlock = false) : '';
            } else {
                if (this.showDropdown === true) {
                    this.showBlock == true ? (this.showBlock = booleanValue) : '';
                } else if (this.showDropdown === false) {
                    this.showBlock == true ? '' : (this.showBlock = booleanValue);
                    this.showDropdown = true;
                }
            }
        });

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });

        emitter.off('showPriorityPopup');
        emitter.on('showPriorityPopup', (isData: any) => {
            this.showPriorityPopUpAction(isData);
        } );

        emitter.off('changeRouteInRisks');
        emitter.on('changeRouteInRisks', async (selectedActionObj: any) => {
            this.mainRisk = selectedActionObj.data;
            await this.changeRoute(selectedActionObj.data, selectedActionObj.route);
        });
        
    },
});
</script>

