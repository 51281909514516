<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="row w-full mb-3 justify-between flex" style="align-items: center">
            <h1 class="title">Organizations</h1>
            <div class="cardbody">
                <div class="flex flex-row-reverse items-center">
                    <div class="flex flex-row-reverse items-center">
                        <button class="flex" @click="filterOpen" :class="tenantsSelect.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                    <!-- <div class="text-center">
                        <button class="btnprimary mr-2" @click="showPopUp()">Add</button>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="mt-3 text-xs">
            Risk management is the process of identifying, assessing and controlling financial, legal, strategic and security risks to an organization’s capital and earnings. These threats, or risks, could stem from a wide variety of sources, including financial uncertainty, legal liabilities,
            strategic management errors, accidents and natural disasters.To reduce risk, an organization needs to apply resources to minimize, monitor and control the impact of negative events while maximizing positive events. A consistent, systemic and integrated approach to risk management can
            help determine how best to identify, manage and mitigate significant risks.
        </div>

        
        <div>
            <newTable :tableRowData="tenantsSelect" :headersData="headers" :openPopup="false" :navigate="true" :loadData="dataLoading"></newTable>
            <div v-if="toolTipActive">
                <tool-tip :tipText="toolTipText"></tool-tip>
            </div>
        </div>
        
    </div>
</template>
<style scoped>
.ellipsis-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { required,helpers,requiredIf, email } from '@vuelidate/validators';
const numeric = helpers.regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
import popup from '@/components/popup.vue';
import useVuelidate from '@vuelidate/core';
import dropdown from '@/components/dropdown.vue';
// import { toast } from '../../main';
const alphabet = helpers.regex(/^[a-zA-Z]+$/);
import newTable from '@/components/newTable.vue';
import { zip } from 'lodash';
import { toast, emitter } from '@/main';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            showFilters: false,
            popUp: false,

            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,

            tenantsSelect: [],
            objToBeModified: {},
            selectedCategory: [],
            headers: ['entityName', 'entityDisplayName', 'industrySectorName'],

            businesss: {
                companyName: '',
                companyDisplayName: '',
                industrySectorName: '',
                emailAddress: '',
                phoneNumber: '',
                addressLineOne: '',
                addressLineTwo: '',
                entityType: '',
                city: '',
                state: '',
                zipCode: '',
                country: ''
            },
            countriesStatesCities: [
                {
                    CountryName: 'India',
                    States: [
                        {
                            StateName: 'Maharashtra',
                            Cities: ['Pune', 'Nagpur', 'Mumbai']
                        },
                        {
                            StateName: 'Kerala',
                            Cities: ['Kochi', 'Munnar']
                        },
                        {
                            StateName: 'Telangana',
                            Cities: ['Hyderabad', 'Nizamabad','Warangal']
                        },
                        {
                            StateName: 'Andhra Pradesh',
                            Cities: ['Vijayawada', 'Vizag','Rajamundry']
                        }
                    ]
                },
                {
                    CountryName: 'Australia',
                    States: [
                        {
                            StateName: 'Aukland',
                            Cities: ['GlenField', 'Henderson', 'MilFord']
                        },
                        {
                            StateName: 'Melbourne',
                            Cities: ['Melbourne', 'South Oakleigh']
                        }
                    ]
                }
            ],

            orgType:[
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Subsidiary'
                },
                {
                    id: 3,
                    value: 'Branch'
                },
            ]
        };
    },
    components: {
        newTable,
        popup,
        dropdown,
        toolTip
    },
    validations() {
        return {
            businesss: {
                companyName: { required },
                companyDisplayName: { required },
                industrySectorName: { required },
                emailAddress: { required, email },
                phoneNumber: { required, numeric },
                addressLineOne: { required },
                entityType: { required },
                city: { required,alphabet },
                state: { required, alphabet },
                country: { required, alphabet },
                zipCode: { required, zip }
            },
        }
    },
    async mounted() {
        this.dataLoading = true;
        await this.getAllBusinessEntities();
        this.dataLoading = false;

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    },
    methods: {
        filterOpen() {
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
        },
        async getAllBusinessEntities() {
            // emitter.emit('visible', false);
            // this.showFilters = false;
            let entityId = 20001;
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                result.data.map((object: any)=>{
                    object.entityId = 20001
                    let industrySectorName:any = (object.industrySectorName==''||object.industrySectorName==undefined)?'N/A':object.industrySectorName;
                    object.industrySectorName = industrySectorName
                    this.tenantsSelect.push(object)
                })
                // this.$store.dispatch('updateDotsLoading', false);
                console.log('this.tenantsSelect', this.tenantsSelect);
            });
        }
    }
});
</script>



